import React, { CSSProperties } from "react";
import styles from "./Indicator.module.scss";
import PMCard from "../PMCard";

interface IndicatorProps {
  icon?: React.ReactNode;
  /* the label of the component */
  label: string;
  /* the number of the component */
  value: string | number;
  style?: CSSProperties;
}

function Indicator(props: IndicatorProps) {
  const { icon, label, value, style } = props;

  return (
    <PMCard className={styles.container} style={style}>
      <div className={styles.icon}>{icon}</div>
      <div>
        <div className={styles.label}>{label}</div>
        <div className={styles.value}>{value}</div>
      </div>
    </PMCard>
  );
}

export default Indicator;

import { BreadcrumbsRoute } from "use-react-router-breadcrumbs";

const routes: BreadcrumbsRoute[] = [
  {
    path: "/:customerGroupId/assignment",
    breadcrumb: "Assignment",
  },
  {
    path: "/:customerGroupId/assignment/:voucherGroupId",
    breadcrumb: "View details",
  },
];

export default routes;

import moment from "moment";
import instance from "../utils/instance";
import {
  ActiveUsersStatisticsResponse,
  LessonProgressStatisticsResponse,
  ReportingStatisticsResponse,
  ReportingSummaryResponse,
} from "../types/dto/response/reporting";
import Page from "../types/page";
import {
  LessonProgressDetailModel,
  LessonProgressStatisticsModel,
  LessonProgressSummaryModel,
  UserListFormModel,
} from "../types/model/reporting";
import {
  CustomerLearningTimeStatisticsParams,
  DownloadReportsRequest,
  DownloadReportsResponse,
  LessonProgressesParams,
  LessonProgressStatisticListParams,
  ScheduleExportInfo,
  ScheduleExportParams,
  ScheduleExportType,
} from "../types/dto/request/reporting";

export async function fetchLessonProgressStatistics(
  params: LessonProgressStatisticListParams
) {
  const url = "reporting/lesson-progress-statistics";
  return instance
    .get<Page<LessonProgressStatisticsResponse>>(url, { params })
    .then((data) => {
      data.content.forEach((record) => {
        // a,b,c,d => ['a','b','c','d']
        // @ts-ignore
        record.subUserNames = record.subUserNames.split(",").sort((a, b) => {
          if (a === record.rootUserName) return -1;
          if (b === record.rootUserName) return 1;
          return a.localeCompare(b);
        });
      });
      return (data as unknown) as Page<LessonProgressStatisticsModel>;
    });
}

export async function fetchLessonProgressSummary(
  customerGroupId: number,
  customerId: number,
  courseName: string
) {
  const url = "reporting/lesson-progress-summary";
  return instance.get<LessonProgressSummaryModel[]>(url, {
    params: { customerGroupId, customerId, courseName },
  });
}

export async function fetchReportingSummary({
  customerGroupId,
  voucherType,
}: {
  customerGroupId: number;
  voucherType: string;
}) {
  const url = `reporting/voucher-statistics`;
  return instance.get<ReportingSummaryResponse>(url, {
    params: { customerGroupId, voucherType },
  });
}

export async function fetchCourseRedemptionStatistics(customerGroupId: number) {
  const url = `reporting/course-redemption-statistics`;
  return instance.get<ReportingStatisticsResponse[]>(url, {
    params: { customerGroupId },
  });
}

export async function fetchCourseCompletionStatistics(customerGroupId: number) {
  const url = `reporting/course-completion-statistics`;
  return instance.get<ReportingStatisticsResponse[]>(url, {
    params: { customerGroupId },
  });
}

export async function fetchActiveUsersStatistics({
  customerGroupId,
  onlyRootUser,
  startDate,
  endDate,
}: {
  customerGroupId: number;
  onlyRootUser: boolean;
  startDate: number;
  endDate: number;
}) {
  const url = `reporting/active-users-statistics`;
  return instance.get<ActiveUsersStatisticsResponse[]>(url, {
    params: { customerGroupId, onlyRootUser, startDate, endDate },
  });
}

export async function fetchLessonProgresses(params: LessonProgressesParams) {
  const url = `reporting/lesson-progresses`;
  return instance.get<Page<LessonProgressDetailModel>>(url, { params });
}

export async function exportLessonProgressStatistics({
  customerGroupId,
  includeFields,
  excelType,
}: {
  customerGroupId: number;
  includeFields?: string[];
  excelType: string;
}) {
  const url = "reporting/lesson-progress-statistics/export";
  return instance.get(url, {
    params: { customerGroupId, includeFields, excelType },
    responseType: "arraybuffer",
  });
}

export async function scheduleExportLessonProgress(
  params: ScheduleExportParams
) {
  const timeZone = moment().format("Z");
  const updatedParams = {
    ...params,
    timeZone: timeZone,
  };
  const url = "scheduled-export";
  return instance.post(url, updatedParams);
}

export async function fetchScheduleExportLessonProgress({
  customerGroupId,
  scheduleExportType,
}: {
  customerGroupId: number;
  scheduleExportType: ScheduleExportType;
}) {
  const url = "scheduled-export";
  return instance.get<ScheduleExportInfo>(url, {
    params: { customerGroupId, scheduleExportType },
  });
}

export function removeScheduleExportLessonProgress({
  customerGroupId,
  scheduleExportType,
}: {
  customerGroupId: number;
  scheduleExportType: ScheduleExportType;
}) {
  const url = "scheduled-export";
  return instance.delete<void>(url, {
    params: { customerGroupId, scheduleExportType },
  });
}

export async function fetchHistoricalReportList(
  params: DownloadReportsRequest
) {
  const url = "scheduled-export/reports";
  return instance.get<DownloadReportsResponse>(url, { params });
}

export async function fetchCustomerLearningTimeStatistics(
  params: CustomerLearningTimeStatisticsParams
) {
  const url = "/reporting/customer-learning-time-statistics";
  return instance
    .get<Page<LessonProgressStatisticsResponse>>(url, { params })
    .then((data) => {
      return (data as unknown) as Page<UserListFormModel>;
    });
}

export async function exportUserListStatistics({
  customerGroupId,
  includeFields,
  excelType,
}: {
  customerGroupId: number;
  includeFields?: string[];
  excelType: string;
}) {
  const url = "reporting/customer-learning-time-statistics/export";
  return instance.get(url, {
    params: { customerGroupId, includeFields, excelType },
    responseType: "arraybuffer",
  });
}

export async function fetchTotalActiveUsers({
  customerGroupId,
  onlyRootUser,
  startDate,
  endDate,
}: {
  customerGroupId: number;
  onlyRootUser: boolean;
  startDate: number;
  endDate: number;
}) {
  const url = "/reporting/total-active-users";
  return instance.get(url, {
    params: { customerGroupId, onlyRootUser, startDate, endDate },
  });
}

export async function fetchTotalLearningTime({
  customerGroupId,
  onlyRootUser,
  startDate,
  endDate,
}: {
  customerGroupId: number;
  onlyRootUser: boolean;
  startDate: number;
  endDate: number;
}) {
  const url = "/reporting/total-learning-time";
  return instance.get(url, {
    params: { customerGroupId, onlyRootUser, startDate, endDate },
  });
}

export async function fetchDailyLearningTime({
  customerGroupId,
  onlyRootUser,
  startDate,
  endDate,
}: {
  customerGroupId: number;
  onlyRootUser: boolean;
  startDate: number;
  endDate: number;
}) {
  const url = "/reporting/daily-learning-time-statistics";
  return instance.get(url, {
    params: { customerGroupId, onlyRootUser, startDate, endDate },
  });
}

export async function fetchCategorizedLearningTime({
  customerGroupId,
  onlyRootUser,
  startDate,
  endDate,
}: {
  customerGroupId: number;
  onlyRootUser: boolean;
  startDate: number;
  endDate: number;
}) {
  const url = "/reporting/categorized-learning-time-statistics";
  return instance.get(url, {
    params: { customerGroupId, onlyRootUser, startDate, endDate },
  });
}

export async function getVoucherAssignmentsByCustomerId({
  customerId,
  adminGroupId,
  page,
  size,
  sort,
}: {
  customerId: number;
  adminGroupId: number;
  page?: number;
  size?: number;
  sort?: string;
}) {
  const url = "/vouchers/assignments/" + customerId;
  return instance.get(url, {
    params: { adminGroupId, page, size, sort },
  });
}

import { bold } from "utils/highlightText";

export const XLSX_TEMPLATE = {
  header: ["Email", "Name"],
  data: [],
  filename: "user_email_list_template.xlsx",
  sheetName: "Sheet1",
};

export const DOUBLE_CONFIRM_MSG = bold`Please be aware that we will create accounts and course assigment for the uploaded user(s). Once this process starts, it can't be undone. If sending email is checked, we will send email to users.\nAre you sure you want to continue?`;

import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Dropdown, Form, Menu } from "antd";
import { VoucherGroupModel } from "types/model/assignment";
import { RootState, store, useAppDispatch } from "app/store";
import { message, SwitchGroupForm, Modal } from "components";
import { getAvailableAmount } from "services/assignment";
import { More } from "assets/icons";
import DistributionForm from "../DistributionForm";
import { bulkSwitchGroup, distribute } from "../voucherGroupsSlice";
import {
  customerGroupsSelectors,
  selectCurrentGroup,
} from "../../customerGroup/customerGroupsSlice";

function Actions({ record }: { record: VoucherGroupModel }) {
  const { currentGroupId } = useSelector(
    (rootState: RootState) => rootState.customerGroups
  );
  const currentGroup = useSelector(selectCurrentGroup);
  const customerGroups = customerGroupsSelectors.selectAll(store.getState());
  const treeData = customerGroups
    .filter(
      (customerGroup) =>
        customerGroup.organizationId === currentGroup?.organizationId
    )
    .map((customerGroup) => {
      return customerGroup.id === currentGroup.id
        ? { ...customerGroup, disabled: true }
        : customerGroup;
    });

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const showDistributionModal = () => {
    if (record.adminGroupId != null) {
      getAvailableAmount({
        adminGroupId: record.adminGroupId,
        voucherGroupId: record.voucherGroupId,
      })
        .then((response) => response.availableAmount)
        .catch((error) => {
          message.error(new Error(error));
          return undefined;
        })
        .then((maxAmount) => {
          Modal.confirm({
            title: "Distribution",
            content: (
              <DistributionForm
                maxAmount={maxAmount}
                treeData={treeData}
                form={form}
              />
            ),
            onOk: () =>
              form.validateFields().then(() => {
                const {
                  toGroupId,
                  requestedVoucherAmount,
                } = form.getFieldsValue();
                dispatch(
                  distribute({
                    toGroupId,
                    requestedVoucherAmount,
                    voucherGroupId: record.voucherGroupId,
                    fromGroupId: currentGroupId,
                  })
                )
                  .then(unwrapResult)
                  .catch(message.error);
              }),
            afterClose: form.resetFields,
          });
        });
    }
  };

  const showBulkSwitchGroupModal = () => {
    Modal.confirm({
      title: "Switch group",
      content: <SwitchGroupForm form={form} treeData={treeData} />,
      onOk: () =>
        form.validateFields().then(() => {
          const { targetGroupId } = form.getFieldsValue();
          dispatch(
            bulkSwitchGroup({
              fromGroupId: currentGroupId,
              toGroupId: targetGroupId,
              voucherGroupId: record.voucherGroupId,
            })
          )
            .then(unwrapResult)
            .then(() => history.push(`/${targetGroupId}/assignment`))
            .catch(message.error);
        }),
      afterClose: form.resetFields,
    });
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown
        overlay={
          <Menu>
            {record.voucherType !== "MASTER" && (
              <Menu.Item onClick={showDistributionModal}>Distribute</Menu.Item>
            )}
            <Menu.Item onClick={showBulkSwitchGroupModal}>
              Switch group
            </Menu.Item>
          </Menu>
        }
      >
        <More />
      </Dropdown>
    </div>
  );
}

export default Actions;

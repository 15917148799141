import { Button, Table } from "antd";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { VoucherType } from "../../../../../types/model/assignment";
import { EMPTY, VOUCHER_TYPE_MAP } from "../../../../../constants/common";

export interface IChooseAssignmentTable {
  courseName: string;
  voucherType: VoucherType;
  expirationDate: number;
  organizationName: string;
  groupName: string;
  voucherGroupId: string;
  adminGroupId: number;
}

export function ChooseAssignmentTable({
  tableData,
  redeemerEmail,
  handleOpenModal,
}: {
  tableData: IChooseAssignmentTable[];
  redeemerEmail: string;
  handleOpenModal: (v: boolean) => void;
}) {
  const history = useHistory();

  const columns = [
    {
      title: "Course",
      dataIndex: "courseName",
      render: (value: string) => value || EMPTY,
    },
    {
      title: "Voucher type",
      dataIndex: "voucherType",
      render: (value: string) => VOUCHER_TYPE_MAP.get(value) || EMPTY,
    },
    {
      title: "Expiration date",
      dataIndex: "expirationDate",
      render: (value: number) =>
        (_.isNull(value) ? EMPTY : moment(value).format("YYYY-MM-DD")) || EMPTY,
    },
    {
      title: "Organization",
      dataIndex: "organizationName",
      render: (value: string) => value || EMPTY,
    },
    {
      title: "Group",
      dataIndex: "groupName",
      render: (value: string) => value || EMPTY,
    },
    {
      title: "Action",
      render: (value: IChooseAssignmentTable) => (
        <Button
          type="link"
          style={{ paddingLeft: 0 }}
          onClick={() => {
            handleOpenModal(false);
            history.push(
              `/${value.adminGroupId}/assignment/${value.voucherGroupId}?redeemerEmail=${redeemerEmail}`
            );
          }}
        >
          Manage
        </Button>
      ),
    },
  ];

  return <Table columns={columns} dataSource={tableData} />;
}

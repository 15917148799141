import { Rule } from "antd/es/form";
import { VoucherAssignmentRequest } from "types/dto/request/assignment";

export const RULES: Record<keyof VoucherAssignmentRequest, Rule[]> = {
  adminGroupId: [],
  autoSendEmail: [],
  voucherGroupId: [],
  applyEasyPassword: [],
  productId: [
    {
      required: true,
      type: "number",
    },
  ],
  assigneeList: [
    {
      required: true,
      message: "'Upload' file is required",
    },
  ],
};

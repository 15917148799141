import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { Card, Spin, Tabs, Typography } from "antd";
import GroupSummary from "../GroupSummary";
import UserReportPage from "../UserReportPage";
import { useLoadingStore } from "../../../hooks/useLoadingStore";
import TabLearningProgress from "./components/TabLearningProgress";
import TabUserList from "./components/TabUserList";
import LicenseUsage from "../UserReportPage/LicenseUsage";

const { Title } = Typography;

function GroupReportPage() {
  const isLoading = useLoadingStore((state) => state.getIsLoading());
  const loadingMap = useLoadingStore((state) => state.map);
  const [licenseUsageLoading, setLicenseUsageLoading] = useState(true);

  const [query, setQuery] = useQueryParams({
    tab: withDefault(StringParam, "dashboard"),
    email: withDefault(StringParam, ""),
  });
  const { tab } = query;

  const groupSummarySpinning = loadingMap.hasOwnProperty(
    "reporting/voucher-statistics"
  );

  const userReportSpinning = [
    "/reporting/total-active-users",
    "/reporting/daily-learning-time-statistics",
    "/reporting/total-learning-time",
    "reporting/active-users-statistics",
    "/reporting/categorized-learning-time-statistics",
  ].some((key) => loadingMap.hasOwnProperty(key));

  const history = useHistory();

  useEffect(() => {
    setLicenseUsageLoading(true);
    setTimeout(() => {
      setLicenseUsageLoading(false);
    }, 3000);
  }, [history.location.pathname]);

  return (
    <Card className="page-container">
      <Title style={{ fontSize: "26px" }}>Report overview</Title>
      <Tabs
        activeKey={tab}
        onChange={(key) => setQuery({ tab: key }, "push")}
        destroyInactiveTabPane
      >
        <Tabs.TabPane tab="Dashboard" key="dashboard">
          <Spin spinning={groupSummarySpinning} size={"large"}>
            <GroupSummary />
          </Spin>
          <Spin spinning={licenseUsageLoading} size={"large"}>
            <LicenseUsage />
          </Spin>
          <Spin spinning={userReportSpinning} size={"large"}>
            <UserReportPage />
          </Spin>
        </Tabs.TabPane>
        <Tabs.TabPane tab="User list" key="user_list">
          <Spin spinning={isLoading} size={"large"}>
            <TabUserList />
          </Spin>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Learning progress" key="learning_progress">
          <Spin spinning={isLoading} size={"large"}>
            <TabLearningProgress />
          </Spin>
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
}

export default GroupReportPage;

import React from "react";
import { useSelector } from "react-redux";
import { Col, Row, Typography } from "antd";
import DoughnutChart from "../DoughnutChart";
import TopFiveBarChart from "../TopFiveBarChat";
import { Indicator } from "../../../components";
import { RegisteredUser, SharedAccounts } from "../../../assets/icons";
import { EMPTY } from "../../../constants/common";
import { RootState } from "../../../app/store";

const { Title } = Typography;
function LicenseUsage() {
  const { reportingSummary } = useSelector(
    (state: RootState) => state.reporting
  );
  const { registeredUser, sharedAccountAmount } = reportingSummary;

  return (
    <>
      <Title style={{ fontSize: "20px" }}>License Usage</Title>
      <Row gutter={24}>
        <Col span={9}>
          <DoughnutChart />
        </Col>
        <Col span={10}>
          <TopFiveBarChart />
        </Col>
        <Col span={5}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              height: "100%",
            }}
          >
            <Indicator
              style={{ flex: "1 1 50%" }}
              icon={<RegisteredUser />}
              label="Registered user(s)"
              value={registeredUser ?? EMPTY}
            />
            <Indicator
              style={{ flex: "1 1 50%" }}
              icon={<SharedAccounts />}
              label="Shared account(s)"
              value={sharedAccountAmount ?? EMPTY}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
export default LicenseUsage;

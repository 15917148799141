import React from "react";
import { Provider } from "react-redux";
import { render } from "react-dom";
import { join } from "lodash";
import "antd/dist/antd.less";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "app/App";
import { store } from "./app/store";
import "./styles/index.scss";

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE,
} = process.env;

const scopes = [
  "read:organizations",
  "create:organization",
  "create:voucher_group",
  "distribute:voucher",
  "read:products",
  "read:generic_products",
  "read:all_voucher_groups",
  "read:voucher_groups",
  "update:voucher_group",
  "delete:voucher_group",
  "assign:voucher",
  "switch:course",
];

render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={REACT_APP_AUTH0_DOMAIN}
        clientId={REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}
        audience={REACT_APP_AUTH0_AUDIENCE}
        scope={join(scopes, " ")}
        cacheLocation="localstorage"
      >
        <App />
      </Auth0Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

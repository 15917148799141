import React from "react";
import { useSelector } from "react-redux";
import { Card, Typography } from "antd";
import { Unauthorized } from "assets/icons";
import GroupReportPage from "./GroupReportPage";
import { selectCurrentGroup } from "../customerGroup/customerGroupsSlice";
import styles from "./Reporting.module.scss";

const { Text } = Typography;

function Reporting() {
  const { isAnalyticsReportingEnabled } = useSelector(selectCurrentGroup);

  return isAnalyticsReportingEnabled ? (
    <GroupReportPage />
  ) : (
    <Card
      className="page-container"
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        rowGap: "8px",
        position: "absolute",
        width: "100%",
        height: "100%",
      }}
    >
      <div className={styles.iconWrapper}>
        <Unauthorized />
      </div>
      <Text style={{ fontSize: "20px", color: "#1c2154" }}>
        Your reporting setting is off.
      </Text>
      <Text style={{ fontSize: "20px", color: "#1c2154" }}>
        Please contact administrator to enable it.
      </Text>
    </Card>
  );
}

export default Reporting;

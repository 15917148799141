import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { TreeSelect } from "antd";
import { split } from "lodash";
import Icon from "@ant-design/icons";
import { RootState, store, useAppDispatch } from "app/store";
import { DropDown } from "assets/icons";
import {
  customerGroupsSelectors,
  setCurrentGroupId,
} from "../customerGroupsSlice";
import styles from "./CustomerGroupDropdown.module.scss";

function CustomerGroupDropdown() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { customerGroupId } = useParams<{ customerGroupId: string }>();
  const { currentGroupId } = useSelector(
    (state: RootState) => state.customerGroups
  );
  const customerGroups = customerGroupsSelectors.selectAll(store.getState());

  if (
    customerGroups.find(
      (customerGroup) => customerGroup.id.toString() === customerGroupId
    )
  ) {
    dispatch(setCurrentGroupId(parseInt(customerGroupId)));
  } else {
    history.push(`/${currentGroupId}/assignment`);
  }

  const data = customerGroups.map((customerGroup) => ({
    ...customerGroup,
    value: customerGroup.id,
    title: customerGroup.name,
  }));

  const handleOnSelect = (value: number) => {
    const paths = split(pathname, "/");
    history.push(`/${value}/${paths[2]}`);
  };

  return (
    <TreeSelect
      treeDataSimpleMode
      treeDefaultExpandAll
      showSearch={true}
      treeNodeFilterProp={"title"}
      treeData={data}
      value={currentGroupId}
      onSelect={(value) => handleOnSelect(value)}
      bordered={false}
      suffixIcon={<Icon component={DropDown} style={{ color: "white" }} />}
      dropdownMatchSelectWidth={false}
      dropdownClassName={styles.dropdown}
      style={{
        color: "#ffffff",
        fontSize: "16px",
        fontWeight: 500,
        minWidth: 80,
      }}
    />
  );
}

export default CustomerGroupDropdown;

import React from "react";
import { Menu } from "antd";
import { useHistory } from "react-router-dom";
import { MenuItemProps } from "rc-menu/lib/MenuItem";
import { useParams } from "react-router";

interface Props extends MenuItemProps {
  email: string;
}

function ViewProgress({ email, ...props }: Props) {
  const { customerGroupId } = useParams<{
    customerGroupId: string;
  }>();
  const history = useHistory();

  const handleViewProgress = () => {
    history.push({
      pathname: `/${customerGroupId}/reporting`,
      search: `email=${email}&tab=learning_progress`,
    });
  };

  return (
    <Menu.Item {...props} onClick={handleViewProgress}>
      View progress
    </Menu.Item>
  );
}

export default ViewProgress;

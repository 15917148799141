import React, { useEffect, useState } from "react";
import { Form, FormInstance, FormProps, Input, Select } from "antd";
import { groupBy, isEmpty } from "lodash";
import { DropDown } from "assets/icons";
import { RedeemableProductModel } from "types/model/assignment";
import { validateMessages } from "constants/common";
import { LoadingOutlined } from "@ant-design/icons";

interface Props extends Pick<FormProps, "onFinish"> {
  redeemableProducts: RedeemableProductModel[];
  redeemedCourse?: string;
  form: FormInstance;
}

function CourseSelectorForm({
  redeemableProducts,
  form,
  redeemedCourse,
}: Props) {
  const [productOptions, setProductOptions] = useState<
    RedeemableProductModel[]
  >([]);

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);

  const languageOptions = Object.keys(
    groupBy(redeemableProducts, "languageName")
  );

  const handleLanguageChange = (value: string) => {
    const options = redeemableProducts.filter(
      (product) => product.languageName === value
    );
    setProductOptions(options);
    form.resetFields(["productId"]);
  };
  return (
    <Form
      form={form}
      className="vertical-layout-form"
      validateMessages={validateMessages}
    >
      <Form.Item label="Original course" hidden={redeemedCourse == null}>
        <Input disabled value={redeemedCourse} />
      </Form.Item>

      <Form.Item label="Language" name="language" rules={[{ required: true }]}>
        <Select
          allowClear
          showSearch
          listHeight={160}
          optionFilterProp={"label"}
          getPopupContainer={(trigger) => trigger.parentNode}
          suffixIcon={
            isEmpty(languageOptions) ? <LoadingOutlined /> : <DropDown />
          }
          notFoundContent="Loading..."
          placeholder="Please select the language"
          onChange={handleLanguageChange}
          options={languageOptions.map((language) => ({
            label: language,
            value: language,
          }))}
        />
      </Form.Item>
      <Form.Item
        label="Course"
        name="productId"
        rules={[
          { required: true },
          {
            validator: (_, value) => {
              const selectedProduct = redeemableProducts.find(
                (product) => product.productId === value
              );
              if (
                selectedProduct != null &&
                selectedProduct.productName === redeemedCourse
              ) {
                return Promise.reject("Please choose a different course");
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Select
          allowClear
          showSearch
          listHeight={160}
          optionFilterProp={"label"}
          getPopupContainer={(trigger) => trigger.parentNode}
          suffixIcon={<DropDown />}
          placeholder={"Please select the course"}
          options={productOptions.map((product) => ({
            label: product.productName,
            value: product.productId,
          }))}
        />
      </Form.Item>
    </Form>
  );
}

export default CourseSelectorForm;

import React from "react";
import { useSelector } from "react-redux";
import { Table, TableProps, Tabs } from "antd";
import {
  ExpandedRowRender,
  RenderExpandIconProps,
} from "rc-table/lib/interface";
import { RootState } from "app/store";
import { LessonProgressStatisticsModel } from "types/model/reporting";
import { DropDown, RightArrow } from "assets/icons";
import { SorterResult } from "antd/es/table/interface";
import columnConfig from "./columnConfig";

import { reportingSelectors } from "../reportingSlice";
import styles from "./LessonProgressStatisticList.module.scss";
import tabStyles from "./tab.module.scss";
import Summary from "./Summary";
import Details from "./Details";
import { LessonProgressStatisticListParams } from "../../../types/dto/request/reporting";

interface Props {
  customerGroupId: number;
  expandedKeys: string[];
  setExpandedKeys: (val: string[]) => void;
  setQuery: (
    filter: (prevState: LessonProgressStatisticListParams) => any
  ) => void;
}

function LessonProgressStatisticList({
  customerGroupId,
  expandedKeys,
  setExpandedKeys,
  setQuery,
}: Props) {
  const { currentPage, currentSize, totalElements } = useSelector(
    (state: RootState) => state.reporting
  );
  const lessonProgressStatistics = useSelector(reportingSelectors.selectAll);

  const handleTableChange: TableProps<
    LessonProgressStatisticsModel
  >["onChange"] = (pagination, filters, sorter, extra) => {
    const sortColumn = sorter as SorterResult<LessonProgressStatisticsModel>;
    if ("sort" === extra.action) {
      setQuery((prevState) => ({
        ...prevState,
        page: undefined,
        sort: `${sortColumn.field},${
          sortColumn.order === "ascend" ? "ASC" : "DESC"
        }`,
      }));
      setExpandedKeys([]);
    }
  };

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setExpandedKeys([]);
    setQuery((prevState) => ({
      ...prevState,
      page: page - 1,
      size: pageSize,
    }));
  };

  const expandedRowRender: ExpandedRowRender<LessonProgressStatisticsModel> = (
    record,
    index,
    indent,
    expanded
  ) => {
    if (!expanded) {
      return null;
    }
    return (
      <Tabs
        type="card"
        destroyInactiveTabPane
        centered
        className={tabStyles.tab}
      >
        <Tabs.TabPane tab="Summary" key="Summary">
          <Summary
            customerGroupId={customerGroupId}
            customerId={record.customerId}
            courseName={record.courseName}
            subUserNames={record.subUserNames}
            rootUserName={record.rootUserName}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Details" key="Details">
          <Details
            customerGroupId={customerGroupId}
            customerId={record.customerId}
            courseName={record.courseName}
            subUserNames={record.subUserNames}
            rootUserName={record.rootUserName}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  };

  const expandedIconRender = ({
    expanded,
    onExpand,
    record,
  }: RenderExpandIconProps<LessonProgressStatisticsModel>) =>
    expanded ? (
      <DropDown
        onClick={(e: any) => {
          setExpandedKeys(
            expandedKeys.filter(
              (key) => key !== `${record.customerId}${record.courseName}`
            )
          );
          onExpand(record, e);
        }}
      />
    ) : (
      <RightArrow
        onClick={(e: any) => {
          setExpandedKeys([
            ...expandedKeys,
            `${record.customerId}${record.courseName}`,
          ]);
          return onExpand(record, e);
        }}
      />
    );

  return (
    <Table
      className={styles.table}
      columns={columnConfig}
      dataSource={lessonProgressStatistics}
      onChange={handleTableChange}
      rowKey={(record) => `${record.customerId}${record.courseName}`}
      expandedRowKeys={expandedKeys}
      expandable={{
        expandedRowRender,
        expandIcon: expandedIconRender,
      }}
      pagination={{
        showTotal: (total, range) =>
          `${range[0]}~${range[1]} of ${total} items`,
        total: totalElements,
        showSizeChanger: true,
        current: currentPage,
        pageSize: currentSize,
        onChange: handlePaginationChange,
      }}
    />
  );
}

export default LessonProgressStatisticList;

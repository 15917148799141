import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Popover,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import { InfoCircleOutlined } from "@ant-design/icons";
import { unwrapResult } from "@reduxjs/toolkit";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import Recurrence from "./Recurrence";
import {
  ScheduleExportInfo,
  ScheduleExportParams,
  ScheduleExportType,
} from "../../../../types/dto/request/reporting";
import { scheduleExportLessonProgress } from "../../reportingSlice";
import { Loading, message } from "../../../../components";
import styles from "./ScheduledReports.module.scss";
import { RootState } from "../../../../app/store";
import SendEmailList from "./SendEmailList";
import { updateIncludeFields } from "../../../../utils/parseXLSX";

interface Props {
  customerGroupId: number;
  handleCloseModal: () => void;
  dispatch: any;
  scheduleExportInfo: ScheduleExportInfo;
  updateScheduleExportInfo: (isDisplayToast: boolean) => void;
  handleDeleteScheduledExport: () => void;
  isDisplayDelete: boolean;
  scheduleExportType: ScheduleExportType;
  options: string[];
}

interface FormErrors {
  customizeRecurrence?: string;
  fieldList?: string;
  reminderEmails?: string;
  fileNamePrefix?: string;
}

function ScheduledReports({
  customerGroupId,
  dispatch,
  handleCloseModal,
  scheduleExportInfo,
  updateScheduleExportInfo,
  handleDeleteScheduledExport,
  isDisplayDelete,
  scheduleExportType,
  options,
}: Props) {
  const initScheduleExport: ScheduleExportParams = {
    customerGroupId: customerGroupId,
    cronExpression: "",
    excelTypeEnum: "CSV",
    scheduleExportType: scheduleExportType,
    beginDate: null,
    endDate: null,
    includeFields: [],
    reminderEmails: [],
    fileNamePrefix: "pimsleur",
  };
  const [form] = Form.useForm();
  const [scheduleExport, setScheduleExport] = useState<ScheduleExportParams>(
    initScheduleExport
  );
  const [formErrors, setFormErrors] = useState<FormErrors>();
  const loading = useSelector((state: RootState) => state.loading);
  const isUserList = scheduleExportType === "USER_LIST";
  const [popoverVisible, setPopoverVisible] = useState(false);

  useEffect(() => {
    setScheduleExport((prevState) => ({
      ...prevState,
      cronExpression: scheduleExportInfo.cronExpression ?? "",
      beginDate: scheduleExportInfo.beginDate ?? null,
      endDate: scheduleExportInfo.endDate ?? null,
      excelTypeEnum: scheduleExportInfo.excelTypeEnum ?? "CSV",
      includeFields: _.isEmpty(scheduleExportInfo.includeFields)
        ? options
        : scheduleExportInfo.includeFields,
      reminderEmails: _.isEmpty(scheduleExportInfo.reminderEmails)
        ? []
        : scheduleExportInfo.reminderEmails,
      fileNamePrefix: _.isEmpty(scheduleExportInfo.fileNamePrefix)
        ? "pimsleur"
        : scheduleExportInfo.fileNamePrefix,
    }));
  }, [scheduleExportInfo]);

  const onChangeFileType = (e: RadioChangeEvent) => {
    setScheduleExport((prevState) => ({
      ...prevState,
      excelTypeEnum: e.target.value,
    }));
  };

  const onChangeFieldOptions = (value: CheckboxValueType[]) => {
    setScheduleExport((prevState) => ({
      ...prevState,
      includeFields: value as string[],
    }));
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setScheduleExport((prevState) => ({
      ...prevState,
      includeFields: e.target.checked ? options : [],
    }));
  };

  const checkError = () => {
    const setError = (fieldName: string, message: string) => {
      setFormErrors((prevState) => ({ ...prevState, [fieldName]: message }));
    };

    setFormErrors({});
    if (_.isEmpty(scheduleExport.cronExpression)) {
      setError(
        "customizeRecurrence",
        '"Customize the recurrence" is required.'
      );
      return false;
    }
    if (_.isEmpty(scheduleExport.includeFields)) {
      setError("fieldList", '"Exported data" fields are required.');
      return false;
    }

    form.validateFields().then();
    let isEmpty = false;
    for (let i = 0; i < scheduleExport.reminderEmails.length; i++) {
      if (_.isEmpty(scheduleExport.reminderEmails[i])) isEmpty = true;
    }
    if (isEmpty) {
      setError("reminderEmails", '"Admin email" is required.');
      return false;
    }

    if (_.isEmpty(scheduleExport.fileNamePrefix)) {
      setError("fileNamePrefix", '"Report name" is required.');
      return false;
    }

    if (scheduleExport.fileNamePrefix.length > 30) {
      setError("fileNamePrefix", "Invalid report name.");
      setPopoverVisible(true);
      return false;
    }
    const validCharactersRegex = /^[a-zA-Z0-9 _\-()]*$/;
    if (!validCharactersRegex.test(scheduleExport.fileNamePrefix)) {
      setError("fileNamePrefix", "Invalid report name.");
      setPopoverVisible(true);
      return false;
    }

    return true;
  };

  const handleConfirm = () => {
    dispatch(
      scheduleExportLessonProgress({
        ...scheduleExport,
        includeFields:
          scheduleExport.includeFields &&
          scheduleExport.includeFields.length === options.length
            ? undefined
            : updateIncludeFields(scheduleExport.includeFields),
        fileNamePrefix: scheduleExport.fileNamePrefix,
      })
    )
      .then(unwrapResult)
      .then(() => {
        updateScheduleExportInfo(true);
        handleCloseModal();
      })
      .catch((error: Error) => {
        if (error.message.includes("parameterError")) {
          setFormErrors((prevState) => ({
            ...prevState,
            customizeRecurrence:
              "Unable to schedule the first report before the end date, please check the recurrence settings.",
          }));
        } else {
          message.error(error).then(handleCloseModal);
        }
      });
  };

  return (
    <div className={styles.content}>
      {loading && (
        <>
          <div className={styles.loadingOverlay} />
          <Loading />
        </>
      )}
      <Form form={form}>
        <Row style={{ marginBottom: "10px" }}>
          <span>
            Schedule a report to run automatically at the time you specify.
          </span>
        </Row>
        <div className={styles.rowContainer}>
          <span className={styles.required}>Customize the recurrence</span>
          {formErrors && (
            <div className={styles.errorContainer}>
              <span className={styles.error}>
                {formErrors?.customizeRecurrence}
              </span>
            </div>
          )}
          <Recurrence
            onConfirm={(
              cron?: string,
              beginDate?: number,
              endDate?: number
            ) => {
              setScheduleExport((prev) => ({
                ...prev,
                cronExpression: cron ?? "",
                beginDate: beginDate ?? null,
                endDate: endDate ?? null,
              }));
              setFormErrors({});
            }}
            scheduleExport={scheduleExport}
            currentBeginDate={scheduleExportInfo.beginDate}
            currentEndDate={scheduleExportInfo.endDate}
            errorDisplay={!_.isEmpty(formErrors?.customizeRecurrence)}
          />
        </div>
        <Divider />

        <div className={styles.colContainer}>
          {formErrors && (
            <div className={styles.errorContainer}>
              <span className={styles.error}>{formErrors?.fieldList}</span>
            </div>
          )}
          <Row justify="space-between" align="middle">
            <span className={styles.required}>
              Select the fields you want to include in the export
            </span>
            <Checkbox
              onChange={onCheckAllChange}
              checked={
                scheduleExport.includeFields &&
                scheduleExport.includeFields.length === options.length
              }
            >
              Select All
            </Checkbox>
          </Row>
          <Checkbox.Group
            style={{ width: "100%" }}
            value={scheduleExport.includeFields}
            onChange={onChangeFieldOptions}
          >
            <Row>
              {options.map((option, index) => (
                <Col span={isUserList ? 12 : 8} key={index}>
                  <Checkbox value={option} key={option}>
                    {option}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </div>
        <Divider />

        <Row align="middle">
          <span className={styles.required}>Export a report as a</span>
          <Radio.Group
            onChange={onChangeFileType}
            value={scheduleExport.excelTypeEnum}
          >
            <Radio value={"CSV"}>CSV file</Radio>
            <Radio value={"XLSX"}>XLSX file</Radio>
          </Radio.Group>
        </Row>

        <Row align="middle" style={{ marginTop: "14px" }}>
          <Col span={6}>
            <span className={styles.required}>Report name</span>
          </Col>
          <Col span={18}>
            {formErrors && (
              <div className={styles.errorContainer}>
                <span className={styles.error}>
                  {formErrors?.fileNamePrefix}
                </span>
              </div>
            )}
            <Input
              style={{ width: "370px" }}
              value={scheduleExport.fileNamePrefix}
              onChange={(e) =>
                setScheduleExport((prevState) => ({
                  ...prevState,
                  fileNamePrefix: e.target.value,
                }))
              }
              onBlur={() =>
                setScheduleExport((prevState) => ({
                  ...prevState,
                  fileNamePrefix: prevState.fileNamePrefix.trim(),
                }))
              }
              suffix={
                <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                  _{scheduleExportType.toLowerCase()}_timestamp.
                  {scheduleExport.excelTypeEnum === "CSV" ? "csv" : "xlsx"}
                </span>
              }
              className={formErrors?.fileNamePrefix && styles.errorInput}
            />
            <Popover
              overlayClassName={styles.popover}
              content={
                <div>
                  &bull; Please limit the character length below 30.
                  <br />
                  &bull; Report names can contain only letters, numbers, spaces,
                  brackets, dashes, and underscore characters.
                </div>
              }
              placement="topRight"
              visible={popoverVisible}
              onVisibleChange={setPopoverVisible}
              trigger={["click"]}
              getPopupContainer={(trigger) =>
                trigger.parentElement as HTMLElement
              }
            >
              <InfoCircleOutlined className={styles.toast} />
            </Popover>
          </Col>
        </Row>
        <Divider />

        <SendEmailList
          form={form}
          reminderEmails={scheduleExport.reminderEmails}
          handleChangeReminderEmails={(reminderEmails: string[]) =>
            setScheduleExport((prevState) => ({
              ...prevState,
              reminderEmails: reminderEmails,
            }))
          }
        />
        <Divider />

        <span>Note: Admin can only set one scheduled report at one time.</span>

        <Space className={styles.buttonGroup}>
          {isDisplayDelete && (
            <Button
              type="default"
              shape="round"
              onClick={handleDeleteScheduledExport}
            >
              Delete
            </Button>
          )}
          <Button type="default" shape="round" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            type="primary"
            shape="round"
            onClick={() => checkError() && handleConfirm()}
          >
            Confirm
          </Button>
        </Space>
      </Form>
    </div>
  );
}
export default ScheduledReports;

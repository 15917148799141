import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Checkbox, Form, FormInstance, Input, Space } from "antd";
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import styles from "./SendEmailList.module.scss";

interface Props {
  form: FormInstance;
  reminderEmails: string[];
  handleChangeReminderEmails: (value: string[]) => void;
}

function SendEmailList({
  form,
  reminderEmails,
  handleChangeReminderEmails,
}: Props) {
  const [isChecked, setIsChecked] = useState(reminderEmails.length > 0);
  const { user } = useAuth0();
  useEffect(() => {
    setIsChecked(reminderEmails.length > 0);
  }, [reminderEmails.length]);

  return (
    <>
      <Checkbox
        checked={isChecked}
        onChange={() => {
          isChecked
            ? handleChangeReminderEmails([])
            : handleChangeReminderEmails([user.email]);
          setIsChecked((prevState) => !prevState);
        }}
      >
        Send a reminder email when the report is ready for download
      </Checkbox>
      {isChecked && (
        <Form
          className={styles.formContainer}
          initialValues={{
            adminEmails: reminderEmails,
          }}
          onValuesChange={(changedValues) => {
            if (changedValues.adminEmails) {
              handleChangeReminderEmails(changedValues.adminEmails);
            }
          }}
          form={form}
        >
          <Form.List name="adminEmails">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} align="start">
                    <Form.Item
                      {...restField}
                      label="Email"
                      name={[name]}
                      rules={[
                        {
                          required: true,
                          message: '"Email" is required.',
                        },
                      ]}
                    >
                      <Input
                        className={styles.input}
                        placeholder="Please input email"
                        onChange={(e) => {
                          let newFormValues = [...reminderEmails];
                          newFormValues[key] = e.target.value;
                          handleChangeReminderEmails(newFormValues);
                        }}
                      />
                    </Form.Item>
                    {key !== 0 && (
                      <MinusCircleFilled
                        onClick={() => remove(name)}
                        className={styles.deleteBtn}
                      />
                    )}
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    className={styles.addBtn}
                    onClick={() => add()}
                    type="link"
                    icon={<PlusCircleFilled />}
                  >
                    Add email
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      )}
    </>
  );
}
export default SendEmailList;

import React, { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { Button, Checkbox, Form, FormProps, Modal, Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload/interface";
import { ModalProps } from "antd/lib/modal";
import Icon, { CloudUploadOutlined } from "@ant-design/icons";
import { useResetFormOnCloseModal } from "hooks";
import { Template } from "assets/icons";
import { assignVouchers } from "features/assignment/voucherGroupsSlice";
import { RootState, useAppDispatch } from "app/store";
import parseXLSX from "utils/parseXLSX";
import { VoucherAssignmentRequest } from "types/dto/request/assignment";
import { message } from "components";
import CourseSelectorForm from "components/CourseSelectorForm";
import { VoucherType } from "types/model/assignment";
import { validateCsv } from "./validateHelper";
import { RULES } from "./formConfig";
import styles from "./AssignmentModal.module.scss";
import { DOUBLE_CONFIRM_MSG, XLSX_TEMPLATE } from "../configs";
import { selectCurrentGroup } from "../../customerGroup/customerGroupsSlice";

type Values = Omit<VoucherAssignmentRequest, "adminGroupId" | "voucherGroupId">;

interface AssignmentModalProps extends Omit<ModalProps, "onOk"> {
  onFinish?: () => void;
  adminGroupId: number;
  // TODO: refactor voucherGroupId, voucherType -> voucherGroupEntity
  voucherGroupId: string;
  voucherType: VoucherType;
}

function AssignInBulkModal({
  visible = false,
  adminGroupId,
  voucherGroupId,
  voucherType,
  onFinish,
  ...otherProps
}: AssignmentModalProps) {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<any>();
  const dispatch = useAppDispatch();
  const { redeemableProducts } = useSelector(
    (state: RootState) => state.voucherGroups
  );
  const currentGroup = useSelector(selectCurrentGroup);

  useResetFormOnCloseModal({ form, visible });

  useEffect(() => setFileList([]), [visible]);

  const handleSubmit: FormProps<Values>["onFinish"] = (values) => {
    return dispatch(assignVouchers({ ...values, adminGroupId, voucherGroupId }))
      .then(unwrapResult)
      .then(onFinish)
      .catch(message.error);
  };

  // historical reason. Backend team does not have time to deal with xlsx file.
  // Thus, the parsing task is handled by FE.
  const handleFileUpload = async (info: UploadChangeParam) => {
    if (info.file.status === "removed") {
      setFileList([]);
      return form.resetFields(["assigneeList"]);
    }
    try {
      const file = info.fileList[0].originFileObj;
      if (file!.size > 1024 * 1024) {
        return message.error("The file exceeds the max file size 1M!");
      }
      const rows = await parseXLSX(file!);
      await validateCsv(rows);
      if (voucherType === "SEAT") {
        const duplicates = rows
          .map((row) => row.email)
          .filter((val, i, emails) => emails.includes(val, i + 1));
        if (duplicates.length >= 1) {
          return message.error(
            `The file contains duplicate email ${duplicates[0]}`
          );
        }
      }
      setFileList(info.fileList);
      form.setFieldsValue({ assigneeList: rows });
    } catch (err) {
      setFileList(fileList.length > 0 ? fileList : []);
      return message.error(err);
    }
  };

  const handleConfirmButtonClick = () => {
    if (voucherType !== "SEAT") {
      return form.submit();
    } else if (
      voucherType === "SEAT" &&
      !currentGroup?.isCreatingAuth0AccountForSeatUserEnabled
    ) {
      return form.validateFields().then(handleSubmit);
    }

    const showDoubleConfirm = (values: Values) =>
      Modal.confirm({
        title: "Confirm",
        content: DOUBLE_CONFIRM_MSG,
        okText: "Continue",
        onOk: () => handleSubmit(values),
        centered: true,
        okButtonProps: { shape: "round" },
        cancelButtonProps: { shape: "round" },
      });
    return form.validateFields().then(showDoubleConfirm);
  };

  const onDownloadTemplate = () => {
    /* Reference: https://github.com/SheetJS/sheetjs/issues/817 */
    /* this line is only needed if you are not adding a script tag reference */
    // if(typeof XLSX == 'undefined') XLSX = require('xlsx');
    const { header, data, filename, sheetName } = XLSX_TEMPLATE;
    /* make the worksheet */
    const worksheet = XLSX.utils.json_to_sheet(data, {
      header,
    });

    /* add to workbook */
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    /* generate an XLSX file */
    XLSX.writeFile(workbook, filename);
  };

  return (
    <Modal
      title="Assign in bulk"
      {...otherProps}
      confirmLoading={
        voucherType === "SEAT" &&
        currentGroup.isCreatingAuth0AccountForSeatUserEnabled
          ? undefined
          : otherProps.confirmLoading
      }
      visible={visible}
      okText={"Confirm"}
      onOk={handleConfirmButtonClick}
      okButtonProps={{ shape: "round", style: { minWidth: "92px" } }}
      cancelButtonProps={{ shape: "round", style: { minWidth: "92px" } }}
    >
      <Form<Values> layout="vertical" form={form} onFinish={handleSubmit}>
        {voucherType === "SEAT" && (
          <CourseSelectorForm
            form={form}
            redeemableProducts={redeemableProducts}
          />
        )}

        <Form.Item
          label="Upload file:"
          name={"assigneeList"}
          rules={RULES.assigneeList}
        >
          <Upload.Dragger
            accept=".xlsx"
            beforeUpload={() => false}
            fileList={fileList}
            onChange={handleFileUpload}
            maxCount={1}
            multiple={false}
          >
            <div className={styles.uploadDragIcon}>
              <CloudUploadOutlined style={{ fontSize: "32px" }} />
            </div>
            <p className={styles.uploadText}>
              <strong>Drag and drop your files here</strong>
            </p>
            <div className={styles.uploadLink}>
              <u>or browse files</u>
            </div>
            <p className={styles.uploadHint}>Files supported : .xlsx</p>
            <p className={styles.uploadHint}>Maximum size: 1MB</p>
          </Upload.Dragger>
        </Form.Item>

        <Form.Item
          name={"autoSendEmail"}
          initialValue={true}
          valuePropName="checked"
          style={{ marginBottom: "0px" }}
        >
          <Checkbox>
            Automatically send on-boarding email(s) after assigning user(s)
          </Checkbox>
        </Form.Item>

        {voucherType === "SEAT" &&
          currentGroup?.isCreatingAuth0AccountForSeatUserEnabled && (
            <Form.Item
              name="applyEasyPassword"
              initialValue={false}
              valuePropName="checked"
            >
              <Checkbox>Apply easy password</Checkbox>
            </Form.Item>
          )}

        <Button
          type="link"
          onClick={onDownloadTemplate}
          className={styles.templateLink}
        >
          <Icon component={Template} />
          Download template
        </Button>
      </Form>
    </Modal>
  );
}

export default AssignInBulkModal;

import create from "zustand";
import { devtools } from "zustand/middleware";

export interface ILoadingState {
  // status:
  map: Record<string, boolean>;

  // methods:
  add: (url?: string) => void;
  remove: (url?: string) => void;
  getIsLoading: () => boolean;
}

function defaultState() {
  return {
    map: {},
  };
}

export const useLoadingStore = create<ILoadingState>()(
  devtools(
    (set, get) => ({
      ...defaultState(),
      add: (url) => {
        if (!url) {
          return;
        }
        set({
          map: {
            ...get().map,
            [url]: true,
          },
        });
      },
      remove: (url) => {
        if (!url) {
          return;
        }
        const map = { ...get().map };
        delete map[url];
        set({ map });
      },
      getIsLoading: () => {
        const mapValues = Object.values(get().map);
        return mapValues.length > 0 && mapValues.every((val) => val);
      },
    }),
    { name: "useLoadingStore" }
  )
);

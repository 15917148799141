import { Form, Modal } from "antd";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { DOUBLE_CONFIRM_MSG } from "../../configs";
import { VoucherType } from "../../../../types/model/assignment";
import { selectCurrentGroup } from "../../../customerGroup/customerGroupsSlice";
import {
  assignVouchers,
  fetchRedeemableProducts,
} from "../../voucherGroupsSlice";
import { message } from "../../../../components";
import { RootState, useAppDispatch } from "../../../../app/store";
import AssignUserForm from "../../../../components/AssignUserForm";

interface Props {
  voucherType: VoucherType;
  adminGroupId: number;
  voucherGroupId: string;
  resetFilter: () => void;
  visible: boolean;
  close: () => void;
  voucherCode: string;
}

export default function AssignUserModal({
  voucherType,
  adminGroupId,
  voucherGroupId,
  resetFilter,
  visible,
  close,
  voucherCode,
}: Props) {
  const [form] = Form.useForm();
  const currentGroup = useSelector(selectCurrentGroup);
  const dispatch = useAppDispatch();
  const redeemableProducts = useSelector(
    (state: RootState) => state.voucherGroups.redeemableProducts
  );

  useEffect(() => {
    if (voucherType !== "SEAT" || voucherCode == null) return;

    dispatch(fetchRedeemableProducts(voucherCode))
      .then(unwrapResult)
      .catch(message.error);
  }, [dispatch, voucherCode, voucherType]);

  const handleSubmit = (values: any) => {
    return dispatch(assignVouchers({ ...values, adminGroupId, voucherGroupId }))
      .then(unwrapResult)
      .then(resetFilter)
      .catch(message.error);
  };

  return (
    <Modal
      visible={visible}
      title="Assign user"
      okText="Confirm"
      onOk={() =>
        form.validateFields().then((values) => {
          return voucherType === "SINGLE" ||
            !currentGroup?.isCreatingAuth0AccountForSeatUserEnabled
            ? handleSubmit(values).then(close)
            : Promise.reject(
                Modal.confirm({
                  title: "Confirm",
                  content: DOUBLE_CONFIRM_MSG,
                  okText: "Continue",
                  onOk: () =>
                    handleSubmit(values).then(() => {
                      Modal.destroyAll();
                      close();
                    }),
                  centered: true,
                  okButtonProps: { shape: "round" },
                  cancelButtonProps: { shape: "round" },
                })
              );
        })
      }
      afterClose={() => form.resetFields()}
      onCancel={close}
    >
      <AssignUserForm
        form={form}
        redeemableProducts={redeemableProducts}
        voucherType={voucherType}
        isCreatingAuth0AccountForSeatUserEnabled={
          currentGroup?.isCreatingAuth0AccountForSeatUserEnabled
        }
      />
    </Modal>
  );
}

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { exportUserListStatistics } from "features/reporting/userListSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment/moment";
import { saveFileWithoutSuffix } from "utils/saveFile";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Popover,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
} from "antd";
import styles from "./ExportNow.module.scss";
import { ScheduleExportType } from "../../../../types/dto/request/reporting";
import { exportLessonProgressStatistics } from "../../reportingSlice";
import { Loading } from "../../../../components";
import { RootState } from "../../../../app/store";
import { updateIncludeFields } from "../../../../utils/parseXLSX";

interface Props {
  dispatch: any;
  customerGroupId: number;
  handleCloseModal: () => void;
  scheduleExportType: ScheduleExportType;
  options: string[];
}

interface FormErrors {
  fieldList?: string;
  fileName?: string;
}

function ExportNow({
  dispatch,
  customerGroupId,
  handleCloseModal,
  scheduleExportType,
  options,
}: Props) {
  const [form] = Form.useForm();
  const [fileTypeValue, setFileTypeValue] = useState("CSV");
  const [includeFields, setIncludeFields] = useState<string[]>(options);
  const [formErrors, setFormErrors] = useState<FormErrors>();
  const isUserList = scheduleExportType === "USER_LIST";
  const initFileName = `${
    isUserList ? "user_list_" : "learning_progress_"
  }${moment().format("YYYY-MM-DDTHH.mm.ss")}.csv`;
  const [fileName, setFileName] = useState(initFileName);
  const loading = useSelector((state: RootState) => state.loading);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleConfirm = () => {
    dispatch(
      isUserList
        ? exportUserListStatistics({
            customerGroupId,
            includeFields:
              includeFields.length === options.length
                ? undefined
                : updateIncludeFields(includeFields),
            excelType: fileTypeValue,
          })
        : exportLessonProgressStatistics({
            customerGroupId,
            includeFields:
              includeFields.length === options.length
                ? undefined
                : updateIncludeFields(includeFields),
            excelType: fileTypeValue,
          })
    )
      .then(unwrapResult)
      .then((content: any) => saveFileWithoutSuffix(content, fileName))
      .then(() => handleCloseModal())
      .catch(message.error);
  };

  const onChangeFileType = (e: RadioChangeEvent) => {
    setFormErrors({});
    const selectedFileType = e.target.value;
    let newFileName = `${
      isUserList ? "user_list_" : "learning_progress_"
    }${moment().format("YYYY-MM-DDTHH.mm.ss")}${
      selectedFileType === "CSV" ? ".csv" : ".xlsx"
    }`;
    setFileName(newFileName);
    setFileTypeValue(selectedFileType);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setFormErrors({});
    const isChecked = e.target.checked;
    setIncludeFields(isChecked ? options : []);
  };

  const onChangeFieldOptions = (selectedFields: CheckboxValueType[]) => {
    setFormErrors({});
    setIncludeFields(selectedFields as string[]);
  };

  const checkError = () => {
    const setError = (fieldName: string, message: string) => {
      setFormErrors((prevState) => ({ ...prevState, [fieldName]: message }));
    };
    setFormErrors({});
    if (includeFields.length <= 0) {
      setError("fieldList", '"Exported data" fields are required.');
      return false;
    }

    if (fileName.length <= 0) {
      setError("fileName", '"Report name" is required.');
      return false;
    }
    const extensionRegex = /\.(csv|xlsx)$/;
    if (!extensionRegex.test(fileName)) {
      setError("fileName", "Invalid file name.");
      setPopoverVisible(true);
      return false;
    }
    if (fileName.length > 64) {
      setError("fileName", "Invalid file name.");
      setPopoverVisible(true);
      return false;
    }
    const validCharactersRegex = /^[a-zA-Z0-9 ._\-()]*$/;
    if (!validCharactersRegex.test(fileName.replace(extensionRegex, ""))) {
      setError("fileName", "Invalid file name.");
      setPopoverVisible(true);
      return false;
    }
    return true;
  };

  return (
    <Form form={form}>
      {loading && (
        <>
          <div className={styles.loadingOverlay} />
          <Loading />
        </>
      )}
      <span>Download to export this report now.</span>
      <Divider />

      <div className={styles.container}>
        {formErrors && (
          <div className={styles.errorContainer}>
            <span className={styles.error}>{formErrors?.fieldList}</span>
          </div>
        )}
        <Row justify="space-between" align="middle">
          <Form.Item
            label="Select the fields you want to include in the export"
            name="checkedList"
            rules={[{ required: true }]}
            className={styles.form}
          />
          <Checkbox
            onChange={onCheckAllChange}
            checked={includeFields.length === options.length}
          >
            Select All
          </Checkbox>
        </Row>
        <Checkbox.Group
          style={{ width: "100%" }}
          value={includeFields}
          onChange={onChangeFieldOptions}
        >
          <Row>
            {options.map((option, index) => (
              <Col span={isUserList ? 12 : 8} key={index}>
                <Checkbox value={option} key={option}>
                  {option}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </div>
      <Divider />

      <Row>
        <Col span={9}>
          <Form.Item
            label="Export a report as a"
            name="fileTypeValue"
            rules={[{ required: true }]}
            className={styles.radio}
          />
          <Radio.Group
            onChange={onChangeFileType}
            value={fileTypeValue}
            className={styles.radioGroup}
          >
            <Radio value={"CSV"}>CSV file</Radio>
            <Radio value={"XLSX"}>XLSX file</Radio>
          </Radio.Group>
        </Col>
        <Col span={15}>
          <Form.Item
            label="Report Name"
            name="reportName"
            rules={[{ required: true }]}
            className={styles.form}
          />
          <div className={styles.inputContainter}>
            {formErrors && (
              <div className={styles.errorContainer}>
                <span className={styles.error}>{formErrors?.fileName}</span>
              </div>
            )}
            <Input
              value={fileName}
              onChange={(e) => {
                setFormErrors({});
                setFileName(e.target.value);
              }}
              onFocus={(e) => {
                const index =
                  fileTypeValue === "CSV"
                    ? e.target.value.lastIndexOf(".csv")
                    : e.target.value.lastIndexOf(".xlsx");
                if (index !== -1) {
                  e.target.setSelectionRange(0, index);
                }
              }}
              onBlur={() => setFileName((prevState) => prevState.trim())}
              className={formErrors?.fileName && styles.errorInput}
            />
            <Popover
              overlayClassName={styles.popover}
              content={
                <div>
                  &bull; Please use the correct name extension.
                  <br />
                  &bull; Please limit the character length below 64.
                  <br />
                  &bull; File names can contain only letters, numbers, spaces,
                  brackets, dashes, and underscore characters.
                </div>
              }
              placement="topRight"
              visible={popoverVisible}
              onVisibleChange={setPopoverVisible}
              trigger={["click"]}
              getPopupContainer={(trigger) =>
                trigger.parentElement as HTMLElement
              }
            >
              <InfoCircleOutlined className={styles.toast} />
            </Popover>
          </div>
        </Col>
      </Row>

      <Space className={styles.buttonGroup}>
        <Button type="default" shape="round" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button
          type="primary"
          shape="round"
          onClick={() => checkError() && handleConfirm()}
        >
          Confirm
        </Button>
      </Space>
    </Form>
  );
}

export default ExportNow;

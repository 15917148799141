import Icon from "@ant-design/icons/lib/components/Icon";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  FormProps,
  Popover,
  Radio,
  Space,
  Typography,
} from "antd";
import { isEqual } from "lodash";
import { DeliveryStatus } from "types/model/global";
import { DropDown } from "assets/icons";
import { useState } from "react";
import { VoucherType } from "types/model/assignment";
import styles from "./PopOverFilter.module.scss";

const { Title, Text } = Typography;

type Values = {
  hideRedeemed?: boolean;
  hideAssigned?: boolean;
  inactiveDays?: number;
  entitlementRemainingDays?: number;
  emailStatus: EmailStatus;
};

type Filter = {
  hasRedeemed?: boolean;
  hasAssigned?: boolean;
  hasSentEmail?: boolean;
  deliveryStatuses?: DeliveryStatus[];
  inactiveDays?: number;
  entitlementRemainingDays?: number;
};

export enum EmailStatus {
  All,
  NotSent,
  Failed,
}

export interface PopOverFilterProps {
  voucherType: VoucherType;
  filter: Filter;
  onSubmit: (values: Values) => void;
  showInactiveDays?: boolean;
  showEntitlementRemainingDays?: boolean;
  showEmailStatus?: boolean;
}

function PopOverFilter({
  voucherType,
  filter,
  onSubmit,
  showInactiveDays,
  showEntitlementRemainingDays,
  showEmailStatus,
}: PopOverFilterProps) {
  const [form] = Form.useForm<Values>();
  const [open, setOpen] = useState(false);

  // map props to state
  let emailStatus = EmailStatus.All;
  if (filter.hasSentEmail === false) {
    emailStatus = EmailStatus.NotSent;
  } else if (
    isEqual(filter.deliveryStatuses, [
      DeliveryStatus.BOUNCE,
      DeliveryStatus.FAILED,
    ])
  ) {
    emailStatus = EmailStatus.Failed;
  }

  const handleSearch: FormProps<Values>["onFinish"] = (values) => {
    onSubmit(values);
    setOpen(false);
  };

  function handleOpenChange() {
    setOpen(!open);

    // reset to initial
    form.setFieldsValue({
      inactiveDays: filter.inactiveDays,
      //filterValues.hasAssigned is false or undefined, so as to hasRedeemed & hasSentEmail
      hideAssigned: filter.hasAssigned === false,
      hideRedeemed: filter.hasRedeemed === false,
      emailStatus,
    });
  }

  function clear() {
    form.setFieldsValue({
      hideAssigned: false,
      hideRedeemed: false,
      inactiveDays: undefined,
      emailStatus: EmailStatus.All,
    });
  }

  const content = (
    <Form<Values>
      form={form}
      layout="horizontal"
      className={styles.searchForm}
      onFinish={handleSearch}
    >
      <Title level={5}>Filter option</Title>

      {voucherType === "SINGLE" && (
        <Space direction="horizontal">
          <Text>Redemption status:</Text>
          <Form.Item name="hideAssigned" valuePropName="checked">
            <Checkbox>Hide Assigned</Checkbox>
          </Form.Item>
          <Form.Item name="hideRedeemed" valuePropName="checked">
            <Checkbox>Hide Redeemed</Checkbox>
          </Form.Item>
        </Space>
      )}

      {showInactiveDays && (
        <Form.Item name="inactiveDays" label="User activity status">
          <Radio.Group>
            <Radio value={undefined}>Show All Users</Radio>
            <Radio value={30}>Inactive Last 30 days</Radio>
            <Radio value={60}>Inactive Last 60 days</Radio>
            <Radio value={90}>Inactive Last 90 days</Radio>
          </Radio.Group>
        </Form.Item>
      )}

      {showEmailStatus && (
        <Form.Item name="emailStatus" label="Onboarding email status">
          <Radio.Group>
            <Radio value={EmailStatus.All}>Show All</Radio>
            <Radio value={EmailStatus.NotSent}>Email Not Sent</Radio>
            <Radio value={EmailStatus.Failed}>Email Failed to Send</Radio>
          </Radio.Group>
        </Form.Item>
      )}

      {showEntitlementRemainingDays && (
        <Form.Item
          name="entitlementRemainingDays"
          label="License expiration status"
        >
          <Radio.Group>
            <Radio value={undefined}>Show All</Radio>
            <Radio value={30}>Expire in 30 days</Radio>
            <Radio value={60}>Expire in 60 days</Radio>
            <Radio value={0}>Expired Already</Radio>
          </Radio.Group>
        </Form.Item>
      )}

      <Divider />

      <Space className={styles.buttonGroup}>
        <Button type="default" shape="round" onClick={handleOpenChange}>
          Cancel
        </Button>
        <Button type="default" shape="round" onClick={clear}>
          Reset
        </Button>
        <Button type="primary" shape="round" htmlType="submit">
          OK
        </Button>
      </Space>
    </Form>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      placement="bottom"
      visible={open}
      overlayClassName={styles.popover}
      onVisibleChange={handleOpenChange}
    >
      <Button type="default" shape="round">
        Filter
        <Icon component={DropDown} />
      </Button>
    </Popover>
  );
}

export default PopOverFilter;

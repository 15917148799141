import React, { useMemo } from "react";
import moment from "moment/moment";
import { capitalize } from "lodash";
import { ColumnsType, FilterValue } from "antd/es/table/interface";
import { DATE_FORMAT, EMPTY } from "../../../../constants/common";
import CourseCompleted from "../../../../components/CourseCompleted";
import { LessonProgressDetailModel } from "../../../../types/model/reporting";

export function useColumns(
  subUserNames: string[],
  rootUserName: string,
  filteredInfo: Record<string, FilterValue | null>
): ColumnsType<LessonProgressDetailModel> {
  return useMemo(
    () => [
      {
        title: "Subuser",
        dataIndex: "subUserName",
        key: "subUserName",
        filters: subUserNames.map((item) => {
          let text: string | JSX.Element = item;
          if (text === rootUserName) {
            text = (
              <>
                {text}
                <div
                  style={{
                    paddingLeft: "24px",
                    color: "#858585",
                    fontSize: "12px",
                  }}
                >
                  Account holder
                </div>
              </>
            );
          }
          return { text, value: item };
        }),
        filteredValue: filteredInfo.subUserName || null,
        onFilter: (value, record) =>
          record.subUserName.includes(value as string),
      },
      {
        title: "Lesson No.",
        dataIndex: "lessonNumber",
        key: "lessonNumber",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.lessonNumber - b.lessonNumber,
        sortDirections: ["descend", "ascend", "descend"],
      },
      {
        title: "First activity date",
        dataIndex: "firstActivityDate",
        key: "firstActivityDate",
        sorter: (a, b) => a.firstActivityDate - b.firstActivityDate,
        sortDirections: ["descend", "ascend", "descend"],
        render: (value) => (value ? moment(value).format(DATE_FORMAT) : EMPTY),
      },
      {
        title: "Last activity date",
        dataIndex: "lastActivityDate",
        key: "lastActivityDate",
        sorter: (a, b) => a.lastActivityDate - b.lastActivityDate,
        sortDirections: ["descend", "ascend", "descend"],
        render: (value) => (value ? moment(value).format(DATE_FORMAT) : EMPTY),
      },
      {
        title: "Completed",
        dataIndex: "isCompleted",
        key: "isCompleted",
        sorter: true,
        sortDirections: ["descend", "ascend", "descend"],
        render: (value) => <CourseCompleted completed={value} />,
      },
      {
        title: "Listen time(Mins)",
        dataIndex: "listenTimeMinutes",
        key: "listenTimeMinutes",
        sorter: (a, b) => a.listenTimeMinutes - b.listenTimeMinutes,
        sortDirections: ["descend", "ascend", "descend"],
        render: (value) => value ?? EMPTY,
      },
      {
        title: "Platform",
        dataIndex: "sourcePlatform",
        key: "sourcePlatform",
        render: (value) => capitalize(value ?? EMPTY),
      },
    ],
    [subUserNames.join(), rootUserName, filteredInfo]
  );
}

import React from "react";
import { Route, Switch } from "react-router-dom";
import { Footer, Breadcrumbs } from "components";
import Reporting from "features/reporting";
import Assignment from "features/assignment";
import CustomerGroup from "features/customerGroup";

import styles from "./Content.module.scss";

function Content() {
  return (
    <main className={styles.main}>
      <section>
        <Breadcrumbs />
      </section>
      <Switch>
        <Route path="/:customerGroupId/assignment">
          <Assignment />
        </Route>
        <Route path="/:customerGroupId/reporting">
          <Reporting />
        </Route>
        <Route path="/:customerGroupId/my-group">
          <CustomerGroup />
        </Route>
      </Switch>
      <Footer />
    </main>
  );
}

export default Content;

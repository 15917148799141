import React, { ComponentProps, useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { Prompt, useHistory } from "react-router";
import { Button, Form, Row, Space } from "antd";
import { RootState, useAppDispatch } from "app/store";
import { message, Modal } from "components";
import { LEAVE_CONFIRMATION } from "constants/common";
import {
  fetchCustomerGroup,
  selectCurrentGroup,
  updateCustomerGroup,
} from "../customerGroupsSlice";
import BasicInfoForm from "../BasicInfoForm";

function MyGroupDetailPage() {
  const dispatch = useAppDispatch();
  const [editing, setEditing] = useState(false);
  const history = useHistory();

  const { currentGroupId: adminGroupId } = useSelector(
    (state: RootState) => state.customerGroups
  );
  const data = useSelector(selectCurrentGroup);

  useEffect(() => {
    setEditing(false);
  }, [adminGroupId]);

  useEffect(() => {
    const unregister = history.listen((location) => {
      if (location.pathname === history.location.pathname) {
        setEditing(false);
      }
    });
    return unregister;
  }, []);

  useEffect(() => {
    dispatch(fetchCustomerGroup(adminGroupId))
      .then(unwrapResult)
      .catch(message.error);
  }, [dispatch, adminGroupId]);

  const [form] = Form.useForm();

  function showConfirmModal() {
    Modal.confirm({
      title: "Confirm",
      content: LEAVE_CONFIRMATION,
      onOk: () => {
        setEditing(false);
      },
    });
  }

  const buttonGroups = editing ? (
    <Space size={12}>
      <Button type="default" shape="round" onClick={showConfirmModal}>
        Cancel
      </Button>
      <Button type="primary" shape="round" onClick={form.submit}>
        Save
      </Button>
    </Space>
  ) : (
    data?.editable && (
      <Button
        type="default"
        shape="round"
        style={{ width: "92px" }}
        onClick={() => setEditing(true)}
      >
        Edit
      </Button>
    )
  );

  const handleSubmit: ComponentProps<typeof BasicInfoForm>["onFinish"] = (
    values
  ) =>
    dispatch(
      updateCustomerGroup({
        ...values,
        groupAdmins: values?.groupAdmins ?? [],
        isCreatingAuth0AccountForSeatUserEnabled:
          data.isCreatingAuth0AccountForSeatUserEnabled,
      })
    )
      .then(unwrapResult)
      .then(() => setEditing(false))
      .catch(message.error);

  return (
    <div>
      <Prompt when={editing} message={LEAVE_CONFIRMATION} />

      <Row justify={"space-between"}>
        <h3>Basic information</h3>
        {buttonGroups}
      </Row>

      <BasicInfoForm
        editing={editing}
        form={form}
        initialValues={data}
        onFinish={handleSubmit}
      />
    </div>
  );
}

export default MyGroupDetailPage;

import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import ReactECharts from "echarts-for-react";
import { graphic } from "echarts";
import { Col, Row } from "antd";
import NoData from "components/NoData";
import PMCard from "components/PMCard";
import calendar from "assets/icons/calendar.svg";
import learningTime from "assets/icons/icon-learning-time.svg";
import { RootState } from "app/store";

import styles from "./UserEngagementCard.module.scss";
import CategorizedLearningTimeBarChat from "../CategorizedLearningTimeBarChat";

interface Props {
  totalActiveUsers: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

function UserEngagementCard({
  totalActiveUsers,
  startDate,
  endDate,
}: Readonly<Props>) {
  const { activeUsersStatistics, dailyTotalLearningTime } = useSelector(
    (state: RootState) => state.reporting
  );
  const { totalLearningTime } = useSelector(
    (state: RootState) => state.reporting
  );
  let activeUsersToDisplay = [];
  let dailyLearningTimeToDisPlay = [];
  const daysDiff = endDate.diff(startDate, "days");
  for (let i = 0; i <= daysDiff; i++) {
    let date = startDate.clone().add(i, "days");
    let formattedDate = date.format("MMM D, YYYY");
    const activeUser = activeUsersStatistics.find(
      (user) => user.date === date.format("YYYY-MM-DD")
    );
    const dailyLearningTime = dailyTotalLearningTime.find(
      (item) => item.date === date.format("YYYY-MM-DD")
    );

    activeUsersToDisplay.push({
      date: formattedDate,
      activeUsers: activeUser ? activeUser.activeUsers : 0,
    });
    dailyLearningTimeToDisPlay.push({
      date: formattedDate,
      totalLearningTime: dailyLearningTime
        ? dailyLearningTime.learningTimeMinutes
        : 0,
    });
  }

  const activeUserOption = {
    title: {
      text: "Daily active users",
      left: "left",
      textStyle: {
        color: "#474545",
        fontSize: 16,
        fontFamily: "CeraPRO",
      },
    },
    tooltip: {
      trigger: "axis",
      position: function (pt: any) {
        return [pt[0] - 70, "10%"];
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: activeUsersToDisplay.map((userData) => userData.date),
      axisLabel: {
        color: "#8c8c8c",
        formatter(value: string) {
          const parts = value.split(", ");
          return parts[0];
        },
      },
    },
    yAxis: {
      minInterval: 1,
      type: "value",
      boundaryGap: [0, "5%"],
      axisLabel: {
        color: "#8c8c8c",
      },
    },
    series: [
      {
        name: "Active users",
        type: daysDiff < 1 ? "bar" : "line",
        barWidth: "40",
        symbol: "none",
        sampling: "lttb",
        itemStyle: {
          color: "#4267f9",
        },
        lineStyle: {
          width: 1,
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#4267f9",
            },
            {
              offset: 1,
              color: "rgba(66, 103, 249, 0.05)",
            },
          ]),
        },
        data: activeUsersToDisplay.map((userData) => userData.activeUsers),
      },
    ],
  };

  const dailyLearningTimeOption = {
    title: {
      text: "Daily total learning time spent in the app",
      left: "left",
      textStyle: {
        color: "#474545",
        fontSize: 16,
        fontFamily: "CeraPRO",
      },
    },
    tooltip: {
      trigger: "axis",
      show: true,
      extraCssText: "",
      formatter: function (params: any) {
        const value = params[0].value as number;
        const hours = Math.floor(value / 60);
        const minutes = value % 60;
        let formattedValue: string;
        if (hours > 0 && minutes > 0) {
          formattedValue = `${hours}h ${minutes}m`;
        } else if (hours > 0) {
          formattedValue = `${hours}h`;
        } else {
          formattedValue = `${minutes}m`;
        }
        return `${params[0].name}<br />${params[0].marker} ${params[0].seriesName}:  ${formattedValue}`;
      },
      position: function (pt: any) {
        return [pt[0] - 70, "10%"];
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: dailyLearningTimeToDisPlay.map((userData) => userData.date),
      axisLabel: {
        color: "#8c8c8c",
        formatter(value: string) {
          const parts = value.split(", ");
          return parts[0];
        },
      },
    },
    yAxis: {
      minInterval: 1,
      type: "value",
      boundaryGap: [0, "5%"],
      axisLabel: {
        color: "#8c8c8c",
        formatter: function (value: number) {
          const hours = Math.floor(value / 60);
          const minutes = value % 60;

          if (hours > 0) {
            return `${hours}h ${minutes}m`;
          }
          return `${minutes}m`;
        },
      },
    },
    series: [
      {
        name: "Total learning time",
        type: daysDiff < 1 ? "bar" : "line",
        barWidth: "40",
        symbol: "none",
        sampling: "lttb",
        itemStyle: {
          color: "#4267f9",
        },
        lineStyle: {
          width: 1,
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#4267f9",
            },
            {
              offset: 1,
              color: "rgba(66, 103, 249, 0.05)",
            },
          ]),
        },
        data: dailyLearningTimeToDisPlay.map(
          (userData) => userData.totalLearningTime
        ),
      },
    ],
  };

  return (
    <>
      <PMCard className={styles.chartContainer}>
        <Row gutter={24}>
          <Col span={7}>
            <h4 className={styles.title}>Total active users</h4>
            <div className={styles.iconContainer}>
              <img src={calendar} alt="Active users" className={styles.icon} />
              <span className={styles.activeNumber}>{totalActiveUsers}</span>
            </div>
            <span className={styles.text}>
              Active users that have learning progress during the selected time
              frame.
            </span>
          </Col>
          <Col span={17}>
            {activeUsersStatistics.length > 0 ? (
              <ReactECharts
                option={activeUserOption}
                notMerge={true}
                lazyUpdate={true}
              />
            ) : (
              <>
                <h4 className={styles.title}>Daily active users</h4>
                <Row>
                  <Col span={8} offset={9}>
                    <NoData />
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </PMCard>

      <Row gutter={24} style={{ marginTop: 20 }}>
        <Col span={17}>
          <PMCard className={styles.chartContainer}>
            <Row gutter={24}>
              <Col span={8}>
                <h4 className={styles.title}>Total learning time</h4>
                <div className={styles.iconContainer}>
                  <span className={styles.activeNumber}>
                    {Math.floor(totalLearningTime / 60)}
                    {"h "}
                    {totalLearningTime % 60}
                    {"m"}
                  </span>
                </div>
                <div className={styles.iconContainer}>
                  <img
                    src={learningTime}
                    alt="Total learning time"
                    className={styles.learningTimeIcon}
                  />
                  <span className={styles.text}>
                    Total learning time on audio and practice in the app.
                  </span>
                </div>
              </Col>
              <Col span={16}>
                {activeUsersStatistics.length > 0 ? (
                  <ReactECharts
                    option={dailyLearningTimeOption}
                    notMerge={true}
                    lazyUpdate={true}
                  />
                ) : (
                  <>
                    <h4 className={styles.title}>
                      Daily total learning time spent in the app
                    </h4>
                    <Row>
                      <Col span={8} offset={9}>
                        <NoData />
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </PMCard>
        </Col>

        <Col span={7}>
          <CategorizedLearningTimeBarChat />
        </Col>
      </Row>
    </>
  );
}

export default UserEngagementCard;

import React from "react";
import { Form, FormInstance, InputNumber, TreeSelect, Typography } from "antd";
import { FormProps } from "antd/es/form";
import { validateMessages } from "constants/common";
import { CustomerGroup } from "../../customerGroup/customerGroupsSlice";
import styles from "./DistributionForm.module.scss";

interface Values {
  requestedVoucherAmount: number;
  toGroupId: number;
}

interface Props extends FormProps<Values> {
  maxAmount?: number;
  treeData: Array<CustomerGroup>;
  form: FormInstance;
}

const { Text } = Typography;

function DistributionForm({ maxAmount, treeData, form }: Props) {
  return (
    <Form<Values>
      layout="horizontal"
      labelAlign="left"
      form={form}
      validateTrigger={["onSubmit"]}
      validateMessages={validateMessages}
      className={styles.form}
    >
      <Form.Item
        label={"Number of distribution"}
        name={"requestedVoucherAmount"}
        normalize={(value) => (value != null ? parseInt(value) : value)}
        rules={[{ required: true, type: "number", max: maxAmount, min: 1 }]}
        style={{ display: "inline-flex", width: "85%" }}
      >
        <InputNumber step={1} min={0} style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        label={" "}
        colon={false}
        style={{
          display: "inline-flex",
          marginLeft: "12px",
          width: "calc(15% - 12px)",
        }}
      >
        <Text strong>{" / " + maxAmount}</Text>
      </Form.Item>

      <Form.Item
        label={"Distribute to"}
        name={"toGroupId"}
        rules={[{ required: true }]}
      >
        <TreeSelect
          treeDataSimpleMode
          treeData={treeData}
          allowClear
          treeDefaultExpandAll
        />
      </Form.Item>
    </Form>
  );
}

export default DistributionForm;

import instance from "utils/instance";
import {
  CustomerGroupDetailResponse,
  CustomerGroupResponse,
  SubgroupListResponse,
} from "types/dto/response/customerGroup";
import Page from "../types/page";
import {
  CustomerGroupCreateAndUpdateRequest,
  SubgroupListParams,
} from "../types/dto/request/customerGroup";

export async function fetchAll() {
  const url = "/customer-groups/hierarchy";
  return instance.get<CustomerGroupResponse>(url);
}

export async function fetchCustomerGroup(id: number) {
  const url = `/customer-groups/${id}`;
  const data = await instance.get(url);
  return new CustomerGroupDetailResponse(data);
}

export function createCustomerGroup(data: CustomerGroupCreateAndUpdateRequest) {
  const url = "/customer-groups";
  return instance.post<void>(url, data);
}

export async function updateCustomerGroup(
  id: number,
  data: CustomerGroupCreateAndUpdateRequest
) {
  const url = `/customer-groups/${id}`;
  return instance.put<void>(url, data);
}

export async function sendEmail(id: number, email: string) {
  const url = `/customer-groups/${id}/send-email`;
  return instance.post<void>(url, { email: email });
}

export async function fetchSubgroup(params: SubgroupListParams) {
  const url = `/customer-groups/${params.customerGroupId}/sub-groups`;
  return instance.get<Page<SubgroupListResponse>>(url, { params });
}

export function removeCustomerGroup(customerGroupId: number) {
  const url = `/customer-groups/${customerGroupId}`;
  return instance.delete<void>(url);
}

import React from "react";
import { Switch, Route } from "react-router-dom";
import MyGroup from "./MyGroup";
import SubgroupCreationPage from "./SubgroupCreationPage";

function CustomerGroup() {
  return (
    <Switch>
      <Route exact path="/:customerGroupId/my-group">
        <MyGroup />
      </Route>
      <Route exact path="/:customerGroupId/my-group/add">
        <SubgroupCreationPage />
      </Route>
    </Switch>
  );
}

export default CustomerGroup;

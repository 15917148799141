import React from "react";
import { Dropdown, Menu, Space } from "antd";
import Icon from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import CustomerGroupDropdown from "features/customerGroup/CustomerGroupDropdown";
import logo from "assets/images/logo.png";
// import { Book } from "assets/icons";
import * as Icons from "assets/icons";
import styles from "./Header.module.scss";
import SearchOrganizationByVoucher from "../../features/assignment/SearchVoucherInformation";

function Header() {
  const { logout, user } = useAuth0();

  const handleLogout = () => {
    sessionStorage.clear();
    logout({ returnTo: window.location.origin });
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={handleLogout} className={styles.logout}>
        <Icon component={Icons.Exit} style={{ fontSize: "20px" }} />
        Log out
      </Menu.Item>
    </Menu>
  );
  return (
    <header className={styles.header}>
      <Space align={"center"}>
        <img className={styles.headerLogoImage} src={logo} alt="" />
        <CustomerGroupDropdown />
      </Space>

      <div>
        <Space size={32}>
          <SearchOrganizationByVoucher />
          {/*<a*/}
          {/*  href={`${process.env.PUBLIC_URL}/B2B_portal_manual.pdf`}*/}
          {/*  download="B2B portal manual.pdf"*/}
          {/*  style={{ fontSize: "16px", fontWeight: 500 }}*/}
          {/*  className={styles.docs}*/}
          {/*>*/}
          {/*  <Book />*/}
          {/*  Docs*/}
          {/*</a>*/}
          <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
            <div className={styles.profile}>
              <Icon component={Icons.User} style={{ fontSize: "20px" }} />
              <span style={{ fontSize: "16px", fontWeight: 500 }}>
                Hi, {user.nickname}
              </span>
            </div>
          </Dropdown>
        </Space>
      </div>
    </header>
  );
}

export default Header;

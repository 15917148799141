import { Rule } from "antd/es/form";

type RuleName =
  | "name"
  | "isCreatingSubgroupEnabled"
  | "isAnalyticsReportingEnabled"
  | "adminEmail"
  | "adminName"
  | "adminTelephone";

export const rules: Record<RuleName, Rule[]> = {
  name: [{ required: true, type: "string", max: 100 }],
  isCreatingSubgroupEnabled: [{ required: true }],
  isAnalyticsReportingEnabled: [{ required: true }],
  adminEmail: [
    {
      type: "email",
      message: "'Admin email' is not a valid email",
    },
    {
      required: true,
      message: "'Admin email' is required",
    },
    {
      type: "string",
      max: 100,
      message: "'Admin email' cannot be longer than 100 characters",
    },
  ],
  adminName: [
    {
      message: "Must be less than 200 characters",
      type: "string",
      max: 200,
    },
  ],
  adminTelephone: [
    {
      message: "Must be less than 32 characters",
      type: "string",
      max: 32,
    },
  ],
};

export const options = [
  { value: true, label: "Yes", style: { flexGrow: 1 } },
  { value: false, label: "No", style: { flexGrow: 1 } },
];

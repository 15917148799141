import { Button, Col, Dropdown, Menu, Popover, Row, Space } from "antd";
import React from "react";
import Icon from "@ant-design/icons/lib/components/Icon";
import moment from "moment";
import classNames from "classnames";
import { Alarm, DropDown } from "../../../../assets/icons";
import styles from "./TabUserList/UserList.module.scss";
import { formatScheduleExport } from "../../../../utils/recurrence";
import { ScheduleExportInfo } from "../../../../types/dto/request/reporting";
import { Modal as ModalComponents } from "../../../../components";

interface Props {
  scheduleExportInfo: ScheduleExportInfo;
  setScheduleAReportVisible: (v: boolean) => void;
  handleDeleteScheduledExport: () => void;
  setExportNowVisible: (v: boolean) => void;
  setDownloadReportsVisible: (v: boolean) => void;
  isShake: boolean;
}
function ScheduledExportButton({
  scheduleExportInfo,
  setScheduleAReportVisible,
  handleDeleteScheduledExport,
  setExportNowVisible,
  setDownloadReportsVisible,
  isShake,
}: Props) {
  const expiredError = () => {
    ModalComponents.error({
      title: "Expired",
      content: (
        <div>
          Unable to schedule a report due to no active licenses in the group
        </div>
      ),
    });
  };

  const formatOneTimeText = () => {
    const [, , , day, month, , year] = scheduleExportInfo.cronExpression.split(
      " "
    );
    const date = moment(
      `${year}-${month && month.padStart(2, "0")}-${
        day && day.padStart(2, "0")
      }`
    );
    return date.format("YYYY-MM-DD");
  };

  const toastTextOfScheduledExport = () => {
    const createdTime = `Created on ${moment(
      scheduleExportInfo.createdDate
    ).format("YYYY-MM-DD HH:mm:ss")}`;
    const startTime =
      scheduleExportInfo.cronExpression.split(" ").length === 7
        ? `Export on ${formatOneTimeText()}`
        : `Start on ${moment(scheduleExportInfo.beginDate)
            .startOf("day")
            .format("YYYY-MM-DD")}`;
    const endTime = scheduleExportInfo.endDate
      ? `End on ${moment(scheduleExportInfo.endDate)
          .endOf("day")
          .format("YYYY-MM-DD")}`
      : "";
    return createdTime + "\n" + startTime + "\n" + endTime;
  };

  return (
    <Space size={10}>
      {!scheduleExportInfo.expired && scheduleExportInfo.cronExpression && (
        <Row align="middle">
          <Alarm
            className={classNames(styles.alarm, {
              [styles.shake]: isShake,
            })}
            onClick={() =>
              scheduleExportInfo.expired
                ? expiredError()
                : setScheduleAReportVisible(true)
            }
          />
          <Col>
            <Popover
              overlayClassName={styles.scheduledPopover}
              content={
                <div style={{ whiteSpace: "pre-line" }}>
                  {toastTextOfScheduledExport()}
                </div>
              }
              destroyTooltipOnHide={true}
            >
              <div
                className={classNames(styles.scheduledExportText, {
                  [styles.redScheduledExportText]: isShake,
                })}
              >
                Scheduled export
              </div>
              <div
                className={classNames(styles.scheduledExportText, {
                  [styles.redScheduledExportText]: isShake,
                })}
              >
                {formatScheduleExport(scheduleExportInfo.cronExpression)}
              </div>
            </Popover>
          </Col>
          <Button
            type="text"
            className={styles.delete}
            onClick={handleDeleteScheduledExport}
          >
            Delete
          </Button>
        </Row>
      )}
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item onClick={() => setExportNowVisible(true)}>
              Export now
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                scheduleExportInfo.expired
                  ? expiredError()
                  : setScheduleAReportVisible(true)
              }
            >
              Schedule a report
            </Menu.Item>
            <Menu.Item onClick={() => setDownloadReportsVisible(true)}>
              Download scheduled reports
            </Menu.Item>
          </Menu>
        }
        trigger={["click"]}
        placement="bottomRight"
      >
        <Button type="default" shape="round">
          Export
          <Icon component={DropDown} />
        </Button>
      </Dropdown>
    </Space>
  );
}

export default ScheduledExportButton;

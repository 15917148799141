import React, { useState } from "react";
import { Button, Checkbox, Popover } from "antd";
import Icon from "@ant-design/icons";
import { CheckboxGroupProps } from "antd/es/checkbox";
import * as Icons from "assets/icons";
import styles from "./DropdownInput.module.scss";

interface DropdownInputProps {
  options: Option[];
  placeholder?: string;
  width?: number;
  value?: Option[];
  onChange?: (checkedValue: Option[]) => void;
}

export interface Option {
  title: string;
  value: number;
}

function DropdownInput({
  options,
  placeholder,
  width,
  value,
  onChange,
}: DropdownInputProps) {
  const [visible, setVisible] = useState(false);

  const handleCheckboxChange: CheckboxGroupProps["onChange"] = (
    checkedValue
  ) => {
    onChange &&
      onChange(options.filter((option) => checkedValue.includes(option.title)));
  };

  const checkbox = (
    <Checkbox.Group
      value={value?.map((group) => group.title)}
      options={options.map((group) => group.title)}
      onChange={handleCheckboxChange}
      style={width ? { width: `${width}px` } : { width: "auto" }}
      className={styles.dropdownCheckbox}
    />
  );

  const isDisplayPlaceholder =
    value === undefined ||
    value.length === 0 ||
    value.length === options.length;

  return (
    <Popover
      content={checkbox}
      overlayClassName={styles.overlay}
      placement="bottomLeft"
      trigger={["click"]}
      visible={visible}
      onVisibleChange={() => setVisible(!visible)}
    >
      <Button
        className={styles.dropdownInput}
        style={width ? { width: `${width}px` } : { width: "auto" }}
      >
        {isDisplayPlaceholder ? (
          <span className={styles.placeholder}>{placeholder}</span>
        ) : (
          value?.map((option) => option.title).join(", ")
        )}
        <Icon component={Icons.DropDown} className={styles.icon} />
      </Button>
    </Popover>
  );
}

export default DropdownInput;

import React, { useEffect, useState } from "react";
import { DatePicker, Divider, Menu, Radio, Row, Space, Typography } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { MenuInfo } from "rc-menu/lib/interface";
import { DropDown } from "assets/icons";
import calendar from "assets/icons/calendarCompleted.svg";
import { unwrapResult } from "@reduxjs/toolkit";
import UserEngagementCard from "../UserEngagementCard";
import { message } from "../../../components";
import { RootState, useAppDispatch } from "../../../app/store";
import styles from "./UserReportPage.module.scss";
import {
  fetchActiveUsersStatistics,
  fetchCategorizedLearningTime,
  fetchDailyLearningTime,
  fetchTotalActiveUsers,
  fetchTotalLearningTime,
} from "../reportingSlice";

const { Title } = Typography;
const { RangePicker } = DatePicker;

function UserReportPage() {
  const dispatch = useAppDispatch();
  const { currentGroupId } = useSelector(
    (rootState: RootState) => rootState.customerGroups
  );
  const [isOnlyRootUser, setIsOnlyRootUser] = useState(true);
  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().subtract(29, "days").startOf("day")
  );
  const [endDate, setEndDate] = useState<moment.Moment>(moment().endOf("day"));
  const [calendarState, setCalendarState] = useState("Last 30 days");
  const [totalActiveUsers, setTotalActiveUsers] = useState("0");
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [selectedCalendarKeys, setSelectedCalendarKeys] = useState(["30"]);
  const [isChangeDate, setIsChangeDate] = useState(false);

  useEffect(() => {
    getActiveUsers(startDate, endDate);
  }, [isOnlyRootUser, currentGroupId]);

  useEffect(() => {
    setStartDate(moment().subtract(29, "days").startOf("day"));
    setEndDate(moment().endOf("day"));
    setCalendarState("Last 30 days");
    setSelectedCalendarKeys(["30"]);
  }, [currentGroupId]);

  useEffect(() => {
    if (!calendarVisible && isChangeDate) {
      getActiveUsers(startDate, endDate);
    }
  }, [calendarVisible]);

  const handleCalendarMenuClick = (value: MenuInfo) => {
    setCalendarVisible(false);
    if (value.key) {
      const newEndDate = moment().endOf("day");
      let newStartDate;
      setEndDate(newEndDate);
      setSelectedCalendarKeys([value.key.toString()]);
      if (value.key === "all") {
        newStartDate = moment("2022-01-01").startOf("day");
        setStartDate(newStartDate);
        setCalendarState("All");
      } else {
        const days = value.key as number;
        newStartDate = moment()
          .subtract(days - 1, "days")
          .startOf("day");
        setStartDate(newStartDate);
        setCalendarState(`Last ${days.toString()} days`);
      }
      getActiveUsers(newStartDate, newEndDate);
    }
  };

  const getActiveUsers = (startDay: moment.Moment, endDay: moment.Moment) => {
    const request = {
      customerGroupId: currentGroupId,
      onlyRootUser: isOnlyRootUser,
      startDate: startDay?.valueOf(),
      endDate: endDay?.valueOf(),
    };
    dispatch(fetchTotalActiveUsers(request))
      .then(unwrapResult)
      .then((res) => setTotalActiveUsers(res))
      .catch(message.error);
    dispatch(fetchActiveUsersStatistics(request))
      .then(unwrapResult)
      .then(() => setIsChangeDate(false))
      .catch(message.error);
    dispatch(fetchTotalLearningTime(request))
      .then(unwrapResult)
      .catch(message.error);
    dispatch(fetchDailyLearningTime(request))
      .then(unwrapResult)
      .catch(message.error);
    dispatch(fetchCategorizedLearningTime(request))
      .then(unwrapResult)
      .catch(message.error);
  };

  return (
    <>
      <Divider style={{ marginBottom: "15px" }} />
      <Row justify={"space-between"}>
        <Space align="start">
          <Title style={{ fontSize: "20px", marginTop: "4px" }}>
            User Engagement
          </Title>
          <Space align="center">
            <span style={{ marginLeft: "14px", marginRight: "10px" }}>
              Show the learning process of
            </span>
            <Radio.Group
              options={[
                { label: "Primary users only", value: true },
                { label: "Primary and sub-users", value: false },
              ]}
              onChange={(evt) => setIsOnlyRootUser(evt.target.value)}
              value={isOnlyRootUser}
              defaultValue
              optionType="button"
              buttonStyle="solid"
              className={styles.radioGroup}
            />
          </Space>
        </Space>
        <Row>
          <div className={styles.datePickerContainer}>
            <Row align="middle">
              <img
                src={calendar}
                style={{ color: "#1C2154" }}
                alt="select range"
              />
              <RangePicker
                bordered={false}
                value={[startDate, endDate]}
                renderExtraFooter={() => (
                  <div className={styles.rangeMenu}>
                    <Menu
                      onClick={handleCalendarMenuClick}
                      selectedKeys={selectedCalendarKeys}
                    >
                      <Menu.Item key="7">Last 7 days</Menu.Item>
                      <Menu.Item key="30">Last 30 days</Menu.Item>
                      <Menu.Item key="60">Last 60 days</Menu.Item>
                      <Menu.Item key="90">Last 90 days</Menu.Item>
                      <Menu.Item key="all">All</Menu.Item>
                    </Menu>
                  </div>
                )}
                dropdownClassName={styles.rangePicker}
                disabledDate={(current) =>
                  (current && current > moment().endOf("day")) ||
                  current < moment("2022-01-01")
                }
                onCalendarChange={(dates) => {
                  if (dates && dates[0] && dates[1]) {
                    setStartDate(dates[0].startOf("day"));
                    setEndDate(dates[1].endOf("day"));
                    setCalendarState("Custom");
                    setSelectedCalendarKeys([]);
                    setIsChangeDate(true);
                  }
                }}
                suffixIcon={<DropDown className={styles.dropDownIcon} />}
                onOpenChange={(status) => setCalendarVisible(status)}
                format="MMM D, YYYY"
                separator={"-"}
                allowClear={false}
                open={calendarVisible}
                inputReadOnly
              />
            </Row>
            <span className={styles.calendarState}>{calendarState}</span>
          </div>
        </Row>
      </Row>
      <UserEngagementCard
        totalActiveUsers={totalActiveUsers}
        startDate={startDate}
        endDate={endDate}
      />
    </>
  );
}

export default UserReportPage;

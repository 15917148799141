import { saveAs } from "file-saver";
import moment from "moment";
import JSZip from "jszip";
import { checkIsIPad, checkIsIphone, checkIsSafari } from "./utils";

export interface DownloadFile {
  fileName: string;
  downloadLink: string;
}

export const saveFile = (content: Blob, fileName: string) =>
  saveAs(
    new Blob([content], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }),
    `${fileName} ${moment().format("YYYY-MM-DDTHH.mm.ss")}.xlsx`
  );

export const saveFileWithoutSuffix = (content: Blob, fileName: string) =>
  saveAs(
    new Blob([content], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }),
    fileName
  );

export const downloadFile = async (file: DownloadFile) => {
  const response = await fetch(file.downloadLink);
  const blob = await response.blob();
  saveFileWithoutSuffix(blob, file.fileName);
};

export const downloadFiles = async (
  files: DownloadFile[],
  fileName: string
) => {
  if (checkIsIPad() || checkIsIphone()) {
    const zip = new JSZip();
    for (const file of files) {
      const response = await fetch(file.downloadLink);
      const blob = await response.blob();
      zip.file(file.fileName, blob);
    }
    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, `${fileName}${moment().format("YYYY-MM-DD_HH.mm.ss")}.zip`);
  } else if (checkIsSafari()) {
    for (const file of files) {
      await downloadFile(file);
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
  } else {
    await Promise.all(files.map((file) => downloadFile(file)));
  }
};

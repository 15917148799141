import React from "react";
import { omit } from "lodash";
import { message as antMessage } from "antd";
import Icon from "@ant-design/icons";
import { Fail } from "../../assets/icons";

const message = omit(antMessage, "error") as typeof antMessage;
message.config({ top: 50 });

message.error = (error: Error | string) => {
  const content = typeof error === "string" ? error : error.message;
  return antMessage.error({
    content,
    icon: <Icon component={Fail} />,
  });
};

export default message;

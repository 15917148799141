import React, { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Spin } from "antd";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { WaterMark } from "@ant-design/pro-layout";
import { QueryParamProvider } from "use-query-params";
import { security } from "utils/security";
import { useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  BrowserRouterProps,
  Route,
} from "react-router-dom";
import { Redirect } from "react-router";
import { Header, Sider, Content, Modal } from "components";
import { RootState, useAppDispatch } from "./store";
import { fetchAll } from "../features/customerGroup/customerGroupsSlice";
import { UNAUTHORIZED_MSG } from "../constants/common";
import styles from "./App.module.scss";

const { REACT_APP_WATER_MARK } = process.env;

const showConfirm: BrowserRouterProps["getUserConfirmation"] = (
  message,
  callback
) => {
  Modal.confirm({
    title: "Confirm",
    content: message,
    onOk: () => {
      callback(true);
      Modal.destroyAll();
    },
  });
};

enum Statuses {
  AUTHENTICATED,
  AUTHORIZED,
  UNAUTHORIZED,
}

function AuthApp() {
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<Statuses>(Statuses.AUTHENTICATED);
  const { currentGroupId } = useSelector(
    (state: RootState) => state.customerGroups
  );
  const { logout } = useAuth0();

  useEffect(() => {
    if (status === Statuses.AUTHENTICATED) {
      dispatch(fetchAll())
        .then(unwrapResult)
        .then((response) => {
          if (response.adminGroups != null && response.adminGroups.length > 0) {
            return setStatus(Statuses.AUTHORIZED);
          }
          setStatus(Statuses.UNAUTHORIZED);
        })
        .catch(() => setStatus(Statuses.UNAUTHORIZED));
    }
  }, [dispatch]);

  switch (status) {
    case Statuses.AUTHENTICATED:
      return <Spin />;
    case Statuses.UNAUTHORIZED:
      Modal.destroyAll();
      Modal.error({
        title: UNAUTHORIZED_MSG.title,
        content: UNAUTHORIZED_MSG.content,
        onOk: () => {
          logout({ returnTo: window.location.origin });
        },
      });
      return <div />;
    case Statuses.AUTHORIZED:
      return (
        <WaterMark
          content={REACT_APP_WATER_MARK}
          gapX={300}
          gapY={300}
          zIndex={9999}
          fontColor="rgba(0, 0, 0, 0.1)"
        >
          <Router getUserConfirmation={showConfirm}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <Route exact path="/">
                <Redirect to={`/${currentGroupId}/assignment`} />
              </Route>
              <Route path="/:customerGroupId">
                <Header />
                <div className={styles.mainWrapper}>
                  <Sider />
                  <Content />
                </div>
              </Route>
            </QueryParamProvider>
          </Router>
        </WaterMark>
      );
  }
}

function App() {
  const { error, logout, getAccessTokenSilently } = useAuth0();
  security.setAccessTokenSilently(getAccessTokenSilently);

  if (error) {
    const { message } = error;
    const title = message === "Timeout" ? "Timeout" : UNAUTHORIZED_MSG.title;
    const content =
      message === "Timeout"
        ? "Session timeout, please login again"
        : UNAUTHORIZED_MSG.content;
    Modal.error({
      title: title,
      content: content,
      className: styles.modal,
      onOk: () => {
        Modal.destroyAll();
        logout({ returnTo: window.location.origin });
      },
    });
    return <div />;
  }

  const MyApp = withAuthenticationRequired(AuthApp);

  return <MyApp />;
}

export default App;

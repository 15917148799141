import React from "react";
import { Tooltip } from "antd";
import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { AssignmentListResponse } from "../../../types/dto/response/assignment";
import styles from "./EmailIndicator.module.scss";
import { DeliveryStatus } from "../../../types/model/global";

interface Props {
  deliveryStatus: AssignmentListResponse["deliveryStatus"];
  style?: React.CSSProperties;
}

export default function EmailIndicator({ deliveryStatus, style }: Props) {
  const isSending = deliveryStatus === DeliveryStatus.SENDING;
  const isFailed =
    deliveryStatus === DeliveryStatus.FAILED ||
    deliveryStatus === DeliveryStatus.BOUNCE;

  let title = "";
  if (isSending) {
    title = "Email is delivering, please wait.";
  } else if (isFailed) {
    title = "Email failed to send, please check the email and try again.";
  }

  return (
    <span
      className={classNames(
        styles.emailIndicator,
        isSending && styles.isSending,
        isFailed && styles.isFailed
      )}
      style={style}
    >
      <Tooltip placement="top" title={title}>
        {isSending && <UploadOutlined />}
        {isFailed && <ExclamationCircleOutlined />}
      </Tooltip>
    </span>
  );
}

import moment from "moment";

export function formatScheduleExport(cronExpression: string) {
  const parts = cronExpression.split(" ");
  const [, , , day, month, dayOfWeek, year] = parts;

  if (year !== undefined) {
    const date = moment(
      `${year}-${month && month.padStart(2, "0")}-${
        day && day.padStart(2, "0")
      }`
    );
    return `One-time export on ${date.format("YYYY-MM-DD")}`;
  }

  if (day === "*" && month === "*" && dayOfWeek === "?") {
    return `Daily export`;
  }

  if (/^\d+$/.test(dayOfWeek)) {
    const dayOfWeekName = moment()
      .day(Number(dayOfWeek) - 1 === 0 ? 6 : Number(dayOfWeek) - 1)
      .format("dddd");
    return `Weekly export on ${dayOfWeekName}`;
  }

  if (/^\d+$/.test(day)) {
    return `Monthly export on Day ${day}`;
  }

  if (day === "L") {
    return `Monthly export on the last day`;
  }

  return "Custom recurrence";
}

export const cronFormat = (
  recurrenceTypeValue: number,
  exportTime: moment.Moment,
  repeatWeek?: string,
  repeatMonth?: string,
  oneTimeExport?: moment.Moment | null | undefined
) => {
  const minute = exportTime.minute();
  const hour = exportTime.hour();
  if (recurrenceTypeValue === 1) {
    return `0 ${minute} ${hour} ${oneTimeExport?.format(
      "D"
    )} ${oneTimeExport?.format("M")} ? ${oneTimeExport?.year()}`;
  }
  if (recurrenceTypeValue === 2) {
    return `0 ${minute} ${hour} * * ?`;
  }
  if (recurrenceTypeValue === 3) {
    return `0 ${minute} ${hour} ? * ${repeatWeek}`;
  }
  if (recurrenceTypeValue === 4) {
    return `0 ${minute} ${hour} ${repeatMonth} * ?`;
  }
};

export const parseCronExpression = (
  cronExpression: string
): {
  recurrenceTypeValue: number;
  exportTime?: moment.Moment;
  repeatWeek?: string;
  repeatMonth?: string;
  oneTimeExport?: moment.Moment;
} => {
  const parts = cronExpression.split(" ");
  const [, minute, hour, day, month, week, year] = parts;
  const exportTime = moment(
    `2000-01-01 ${hour && hour.padStart(2, "0")}:${
      minute && minute.padStart(2, "0")
    }:00`
  );

  if (year !== undefined) {
    return {
      recurrenceTypeValue: 1,
      exportTime: exportTime,
      repeatWeek: "",
      repeatMonth: "",
      oneTimeExport: moment(
        `${year}-${month && month.padStart(2, "0")}-${
          day && day.padStart(2, "0")
        }`
      ),
    };
  }

  if (day === "*" && month === "*" && week === "?") {
    return {
      recurrenceTypeValue: 2,
      exportTime: exportTime,
      repeatWeek: "",
      repeatMonth: "",
    };
  }

  if (/^\d+$/.test(week)) {
    return {
      recurrenceTypeValue: 3,
      exportTime: exportTime,
      repeatMonth: "",
      repeatWeek: week,
    };
  }

  if (/^\d+$/.test(day) || day === "L") {
    return {
      recurrenceTypeValue: 4,
      exportTime: exportTime,
      repeatWeek: "",
      repeatMonth: day,
    };
  }

  return {
    recurrenceTypeValue: 1,
    exportTime: undefined,
    repeatWeek: "",
    repeatMonth: "",
  };
};

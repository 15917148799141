import React from "react";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { DATE_FORMAT, EMPTY } from "constants/common";
import { UserListFormModel } from "types/model/reporting";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import _ from "lodash";
import styles from "./UserList.module.scss";
import { More } from "../../../../../assets/icons";
import { RootState, useAppDispatch } from "../../../../../app/store";
import { formatTime } from "../../../../../utils/parseXLSX";
import { getVoucherAssignmentsByCustomerId } from "../../../userListSlice";
import { IChooseAssignmentTable } from "./ChooseAssignmentTable";

const ALL_TIME = "Learning time (All time)";
const ONLY_30_DAYS = "Learning time (Last 30 days)";

const useColumns = (
  isOnlyLast30Days: boolean,
  setOnlyLast30Days: (val: boolean) => void,
  setChooseAssignmentVisible: (val: boolean) => void,
  setAssignmentData: (val: IChooseAssignmentTable[]) => void,
  setRedeemerEmail: (val: string) => void
) => {
  const history = useHistory();
  const { currentGroupId: customerGroupId } = useSelector(
    (state: RootState) => state.customerGroups
  );
  const dispatch = useAppDispatch();

  const columnConfig: ColumnType<UserListFormModel>[] = [
    {
      title: "Customer email",
      dataIndex: "customerEmail",
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Customer name",
      dataIndex: "customerName",
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: (
        <Dropdown
          overlay={
            <Menu className={styles.menu}>
              <Menu.Item
                onClick={() => {
                  setOnlyLast30Days(false);
                }}
              >
                {ALL_TIME}
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  setOnlyLast30Days(true);
                }}
              >
                {ONLY_30_DAYS}
              </Menu.Item>
            </Menu>
          }
        >
          <div>{isOnlyLast30Days ? ONLY_30_DAYS : ALL_TIME} ▼</div>
        </Dropdown>
      ),
      dataIndex: isOnlyLast30Days
        ? "learningTimeLast30DaysSeconds"
        : "learningTimeSeconds",
      ellipsis: true,
      width: 234,
      render: (value) => (_.isNull(value) ? EMPTY : formatTime(value)) || EMPTY,
    },
    {
      title: "First activity date",
      dataIndex: "firstActivityDate",
      ellipsis: true,
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true,
      render: (value) => (value ? moment(value).format(DATE_FORMAT) : EMPTY),
    },
    {
      title: "Last activity date",
      dataIndex: "lastActivityDate",
      ellipsis: true,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true,
      render: (value) => (value ? moment(value).format(DATE_FORMAT) : EMPTY),
    },
    {
      title: "Current course",
      dataIndex: "courseName",
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Action",
      width: 150,
      render: (value) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() => {
                  history.push(
                    `/${customerGroupId}/reporting?tab=learning_progress&email=${value.customerEmail}`
                  );
                }}
              >
                View Progress
              </Menu.Item>
              <Menu.Item
                onClick={() =>
                  dispatch(
                    getVoucherAssignmentsByCustomerId({
                      customerId: value.customerId,
                      adminGroupId: customerGroupId,
                    })
                  ).then((data) => {
                    if (data.payload.contentSize > 1) {
                      setAssignmentData(data.payload.content);
                      setRedeemerEmail(value.customerEmail);
                      setChooseAssignmentVisible(true);
                    } else if (data.payload.contentSize === 1) {
                      history.push(
                        `/${customerGroupId}/assignment/${data.payload.content[0].voucherGroupId}?redeemerEmail=${value.customerEmail}`
                      );
                      setChooseAssignmentVisible(false);
                    } else {
                      console.log("Error: none content");
                      setChooseAssignmentVisible(false);
                    }
                  })
                }
              >
                Manage assignment
              </Menu.Item>
            </Menu>
          }
        >
          <More />
        </Dropdown>
      ),
    },
  ];
  return columnConfig;
};

export default useColumns;

import * as XLSX from "xlsx";
import moment from "moment";

type Row = Record<string, string>;

function trimVal(obj: Row) {
  return Object.entries(obj).reduce<Row>(
    (prev, [key, val]) => ({
      ...prev,
      [key.toLowerCase()]: val.toString().trim(),
    }),
    {}
  );
}

export default async function parseXLSX(file: File) {
  const data = await file.arrayBuffer();
  try {
    const workbook = XLSX.read(data, { type: "array" });
    const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
    const rows = XLSX.utils.sheet_to_json<Row>(firstWorksheet, {
      header: 0,
      defval: "",
    });
    return rows.map(trimVal);
  } catch (err) {
    throw new Error("The file may be damaged and cannot be opened.");
  }
}

export function camelCaseToUnderscore(str: string): string {
  const result = str.replace(/([a-z])([A-Z])/g, "$1_$2");
  return result.toLowerCase();
}

export function formatTime(seconds: number): string {
  const duration = moment.duration(seconds, "seconds");

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  } else {
    return `${minutes}m ${duration.seconds()}s`;
  }
}

export function updateIncludeFields(
  includeFields: string[] | undefined
): string[] | undefined {
  let newIncludeFields = includeFields;
  if (newIncludeFields?.includes("Learning time(All time)")) {
    if (!newIncludeFields?.includes("Learning time(All time/Seconds)")) {
      newIncludeFields = [
        ...(newIncludeFields || []),
        "Learning time(All time/Seconds)",
      ];
    }
  } else {
    newIncludeFields = newIncludeFields?.filter(
      (item) => item !== "Learning time(All time/Seconds)"
    );
  }

  if (newIncludeFields?.includes("Learning time(Last 30 days)")) {
    if (!newIncludeFields?.includes("Learning time(Last 30 days/Seconds)")) {
      newIncludeFields = [
        ...(newIncludeFields || []),
        "Learning time(Last 30 days/Seconds)",
      ];
    }
  } else {
    newIncludeFields = newIncludeFields?.filter(
      (item) => item !== "Learning time(Last 30 days/Seconds)"
    );
  }

  return newIncludeFields;
}

import React from "react";
import { useSelector } from "react-redux";
import { isNil } from "lodash";
import { DeliveryStatus } from "types/model/global";
import { unwrapResult } from "@reduxjs/toolkit";
import { Tooltip } from "antd";
import Icon from "@ant-design/icons";
import { GroupAdmin } from "types/model/customerGroup";
import { EMPTY } from "constants/common";
import { RootState, useAppDispatch } from "app/store";
import { message, Modal } from "components";
import { SendEmail } from "assets/icons";
import { fetchCustomerGroup, sendEmail } from "../customerGroupsSlice";

interface Props {
  value?: GroupAdmin;
}

function GroupAdminEmailView(props: Props) {
  const { value } = props;
  const dispatch = useAppDispatch();
  const { currentGroupId: adminGroupId } = useSelector(
    (state: RootState) => state.customerGroups
  );
  const hasSent =
    value?.hasSentEmail &&
    (isNil(value.deliveryStatus) ||
      value.deliveryStatus === DeliveryStatus.DELIVERY);
  const isSending =
    value?.hasSentEmail && value.deliveryStatus === DeliveryStatus.SENDING;
  const isFailed =
    value?.hasSentEmail &&
    (value.deliveryStatus === DeliveryStatus.BOUNCE ||
      value.deliveryStatus === DeliveryStatus.FAILED);

  const handleSendEmail = (email: string) => {
    Modal.confirm({
      title: "Send email",
      content: `Are you sure you want to send admin on-boarding email to ${email}?`,
      okText: "Send",
      onOk: () =>
        dispatch(sendEmail({ id: adminGroupId, email: email }))
          .then(unwrapResult)
          .then(() => {
            adminGroupId &&
              dispatch(fetchCustomerGroup(adminGroupId))
                .then(unwrapResult)
                .catch(message.error);
          })
          .catch(message.error),
    });
  };

  return (
    <>
      <span>{value?.email ?? EMPTY}</span>
      {value?.email && (
        <>
          <Tooltip placement="top" title="Send admin on-boarding email">
            <Icon
              component={SendEmail}
              style={{ marginLeft: "10px" }}
              onClick={() => handleSendEmail(value?.email)}
            />
          </Tooltip>
          {(hasSent || isSending || isFailed) && (
            <i
              style={{
                marginLeft: "10px",
                backgroundColor: isFailed ? "#fff0f0" : "#f3f4ff",
                padding: "8px",
                color: isFailed ? "#f00" : undefined,
              }}
            >
              {hasSent && "Email sent"}
              {isSending && "Email delivering"}
              {isFailed && "Email failed to send"}
            </i>
          )}
        </>
      )}
    </>
  );
}

export default GroupAdminEmailView;

import React from "react";
import { useSelector } from "react-redux";
import ReactECharts from "echarts-for-react";
import { RootState } from "app/store";
import NoData from "components/NoData";
import PMCard from "components/PMCard";
import styles from "./BarChart.module.scss";

function CategorizedLearningTimeBarChat() {
  const { categorizedLearningTime } = useSelector(
    (state: RootState) => state.reporting
  );

  let learningTimes = [
    { name: "Enrich practice", value: 0 },
    { name: "Enrich audio", value: 0 },
    { name: "Practice", value: 0 },
    { name: "Audio", value: 0 },
  ];
  learningTimes = learningTimes.map((itemA) => {
    const matchingItemB = categorizedLearningTime.find(
      (itemB) => itemB.learningType === itemA.name
    );
    if (matchingItemB) {
      return { name: itemA.name, value: matchingItemB.learningTimeMinutes };
    }

    return itemA;
  });

  const option = {
    title: {
      text: "Learning time by content type",
      left: 23,
      top: 20,
      textStyle: {
        color: "#474545",
        fontSize: 16,
        fontFamily: "CeraPRO",
      },
    },
    yAxis: {
      type: "category",
      axisTick: { show: false },
      axisLabel: {
        color: "#8c8c8c",
      },
      axisLine: {
        lineStyle: {
          color: "#8c8c8c",
        },
      },
      data: learningTimes.map((item) => item.name),
    },
    xAxis: {
      type: "value",
      axisLabel: {
        show: false,
      },
    },
    grid: {
      containLabel: true,
      left: 29,
      right: 88,
      top: 66,
      bottom: 20,
    },
    series: [
      {
        data: learningTimes.map((item) => item.value),
        type: "bar",
        label: {
          show: true,
          position: "right",
          formatter: function (params: any) {
            const value = params.value;
            const hours = Math.floor(value / 60);
            const minutes = value % 60;

            if (hours > 0 && minutes > 0) {
              return `${hours}h ${minutes}m`;
            } else if (hours > 0) {
              return `${hours}h`;
            } else {
              return `${minutes}m`;
            }
          },
          color: "#7f7f7f",
        },
        itemStyle: {
          color: "#b3c2fd",
        },
        emphasis: {
          itemStyle: {
            color: "#1644F9",
          },
        },
      },
    ],
  };
  return (
    <PMCard className={styles.chartContainer}>
      {categorizedLearningTime.length > 0 ? (
        <ReactECharts
          style={{ height: "100%" }}
          option={option}
          notMerge={true}
          lazyUpdate={true}
        />
      ) : (
        <>
          <h4>Learning time by content type</h4>
          <NoData />
        </>
      )}
    </PMCard>
  );
}

export default CategorizedLearningTimeBarChat;

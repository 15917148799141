import React, { useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import ReactECharts from "echarts-for-react";
import { message } from "components";
import { RootState, useAppDispatch } from "app/store";
import NoData from "components/NoData";
import PMCard from "components/PMCard";
import { fetchCourseRedemptionStatistics } from "../reportingSlice";

import styles from "./BarChart.module.scss";

function TopFiveBarChart() {
  const dispatch = useAppDispatch();
  const { currentGroupId: adminGroupId } = useSelector(
    (state: RootState) => state.customerGroups
  );
  const { courseRedemptionStatistics } = useSelector(
    (state: RootState) => state.reporting
  );
  let topFiveLanguages = Array(5 - courseRedemptionStatistics.length).fill({
    name: "",
    value: 0,
  });
  topFiveLanguages = topFiveLanguages
    .concat(courseRedemptionStatistics)
    .sort((a, b) => a.value - b.value);

  useEffect(() => {
    if (adminGroupId != null) {
      dispatch(fetchCourseRedemptionStatistics(adminGroupId))
        .then(unwrapResult)
        .catch(message.error);
    }
  }, [dispatch, adminGroupId]);

  const option = {
    title: {
      text: "Top 5 redeemed languages",
      left: 23,
      top: 20,
      textStyle: {
        color: "#474545",
        fontSize: 16,
        fontFamily: "CeraPRO",
      },
    },
    yAxis: {
      type: "category",
      axisTick: { show: false },
      axisLabel: {
        color: "#8c8c8c",
      },
      axisLine: {
        lineStyle: {
          color: "#8c8c8c",
        },
      },
      data: topFiveLanguages.map((language) => language.name),
    },
    xAxis: {
      type: "value",
      axisLabel: {
        show: false,
      },
    },
    grid: {
      containLabel: true,
      left: 29,
      right: 88,
      top: 66,
      bottom: 20,
    },
    series: [
      {
        data: topFiveLanguages.map((language) => language.value),
        type: "bar",
        label: {
          show: true,
          position: "right",
          formatter: "{c} time(s)",
          color: "#7f7f7f",
        },
        itemStyle: {
          color: "#b3c2fd",
        },
        emphasis: {
          itemStyle: {
            color: "#1644F9",
          },
        },
      },
    ],
  };
  return (
    <PMCard className={styles.chartContainer}>
      {courseRedemptionStatistics.length > 0 ? (
        <ReactECharts
          style={{ height: "100%" }}
          option={option}
          notMerge={true}
          lazyUpdate={true}
        />
      ) : (
        <>
          <h4>Top 5 redeemed languages</h4>
          <NoData />
        </>
      )}
    </PMCard>
  );
}

export default TopFiveBarChart;

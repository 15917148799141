import React, { CSSProperties } from "react";
import { Space, Typography } from "antd";
import { Unauthorized } from "assets/icons";

import styles from "./NoData.module.scss";

const { Text } = Typography;

function NoData({ style }: { style?: CSSProperties }) {
  return (
    <Space
      direction={"vertical"}
      align={"center"}
      style={{ marginTop: "30px", ...style }}
    >
      <div className={styles.iconWrapper}>
        <Unauthorized />
      </div>
      <Text strong style={{ fontSize: "16px", color: "#474545" }}>
        No data available
      </Text>
    </Space>
  );
}

export default NoData;

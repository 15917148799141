import { Button } from "antd";
import { ColumnType } from "antd/lib/table";
import { DATE_FORMAT, EMPTY } from "constants/common";
import moment from "moment";
import { SubgroupListResponse } from "types/dto/response/customerGroup";
import React from "react";

type Record = SubgroupListResponse;

export default function useColumns(
  onDelete: (record: Record) => void
): ColumnType<Record>[] {
  return [
    {
      title: "Group name",
      dataIndex: "name",
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Allowed to create subgroup",
      dataIndex: "isCreatingSubgroupEnabled",
      ellipsis: true,
      render: (value) => (value ? "Yes" : value === false ? "No" : EMPTY),
    },
    {
      title: "Progress reporting",
      dataIndex: "isAnalyticsReportingEnabled",
      ellipsis: true,
      render: (value) => (value ? "Yes" : value === false ? "No" : EMPTY),
    },
    {
      title: "Modified at",
      dataIndex: "lastModifiedDate",
      ellipsis: true,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true,
      render: (value) => (value ? moment(value).format(DATE_FORMAT) : EMPTY),
    },
    {
      title: "Actions",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => onDelete(record)}
          style={{ padding: 0, height: "auto" }}
        >
          Delete
        </Button>
      ),
    },
  ];
}

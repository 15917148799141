import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { fetchLessonProgresses } from "../../../../services/reporting";
import { LessonProgressDetailModel } from "../../../../types/model/reporting";
import { useColumns } from "./useColumns";

interface Props {
  customerGroupId: number;
  customerId: number;
  courseName: string;
  subUserNames: string[];
  rootUserName: string;
}

export default function Details({
  customerGroupId,
  customerId,
  courseName,
  subUserNames,
  rootUserName,
}: Props) {
  const [dataSource, setDataSource] = useState<LessonProgressDetailModel[]>([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalElements, setTotalElements] = useState(0);
  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});
  const [sortedInfo, setSortedInfo] = useState<
    SorterResult<LessonProgressDetailModel>
  >({});
  const selectedSubUserNames = filteredInfo.subUserName?.join(",");
  const sort = sortedInfo.field
    ? `${sortedInfo.field},${sortedInfo.order === "ascend" ? "ASC" : "DESC"}`
    : "lessonNumber,ASC";
  const columns = useColumns(subUserNames, rootUserName, filteredInfo);

  useEffect(() => {
    fetchLessonProgresses({
      customerGroupId,
      customerId,
      courseName,
      page,
      size,
      sort,
      subUserNames: selectedSubUserNames,
    }).then((data) => {
      setDataSource(data.content);
      setPage(data.page);
      setSize(data.size);
      setTotalElements(data.totalElements);
    });
  }, [
    customerGroupId,
    customerId,
    courseName,
    page,
    size,
    sort,
    selectedSubUserNames,
  ]);

  return (
    <Table
      rowKey="lessonProgressId"
      dataSource={dataSource}
      columns={columns}
      onChange={(pagination, filters, sorter, extra) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter as SorterResult<LessonProgressDetailModel>);
        if (extra.action !== "paginate") {
          setPage(0);
        }
      }}
      pagination={{
        showTotal: (total, range) =>
          `${range[0]}~${range[1]} of ${total} items`,
        total: totalElements,
        showSizeChanger: true,
        current: page + 1,
        pageSize: size,
        onChange: (_page, _pageSize) => {
          setPage(_page - 1);
          setSize(_pageSize ?? 10);
        },
      }}
    />
  );
}

import React from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Menu } from "antd";
import { MenuItemProps } from "rc-menu/lib/MenuItem";
import { useAppDispatch } from "app/store";
import {
  revokeVoucher,
  revokingRedeemedSingle,
} from "features/assignment/voucherGroupsSlice";
import { message, Modal } from "../index";

interface Props extends MenuItemProps {
  voucherId: number;
  resetFilter: () => void;
  hasRedeemed?: boolean;
}

function RevokeVoucher({
  voucherId,
  resetFilter,
  hasRedeemed,
  ...props
}: Props) {
  const dispatch = useAppDispatch();
  const handleRevokeVoucher = () => {
    Modal.confirm({
      title: "Revoke voucher",
      content: "Are you sure you want to revoke this voucher?",
      okText: "Confirm",
      onOk: () => {
        if (hasRedeemed) {
          dispatch(revokingRedeemedSingle(voucherId))
            .then(unwrapResult)
            .then(() => resetFilter())
            .catch(message.error);
        } else {
          dispatch(revokeVoucher({ voucherId }))
            .then(unwrapResult)
            .then(() => resetFilter())
            .catch(message.error);
        }
      },
    });
  };
  return (
    <Menu.Item {...props} onClick={handleRevokeVoucher}>
      Revoke voucher
    </Menu.Item>
  );
}

export default RevokeVoucher;

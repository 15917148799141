import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  FormProps,
  Popover,
  Row,
  Space,
  Table,
  TableProps,
} from "antd";
import Icon from "@ant-design/icons/lib/components/Icon";
import { ColumnType } from "antd/lib/table";
import { SorterResult } from "antd/es/table/interface";
import moment from "moment";
import {
  CustomerGroup,
  customerGroupsSelectors,
} from "features/customerGroup/customerGroupsSlice";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { DropDown } from "../../../../assets/icons";
import DateInput from "./DateInput";
import styles from "./DownloadReports.module.scss";
import { DATE_FORMAT, EMPTY } from "../../../../constants/common";
import {
  DownloadReportsRequest,
  DownloadReportsResponse,
  DownloadReportsStatisticsModel,
  ScheduleExportType,
} from "../../../../types/dto/request/reporting";
import { fetchHistoricalReportList } from "../../reportingSlice";
import { Loading, message } from "../../../../components";
import { RootState, store } from "../../../../app/store";
import { DownloadFile, downloadFiles } from "../../../../utils/saveFile";
import DropdownInput, { Option } from "../../../../components/DropdownInput";

interface Props {
  adminGroupId: number;
  dispatch: any;
  scheduleExportType: ScheduleExportType;
}

function DownloadReports({
  adminGroupId,
  dispatch,
  scheduleExportType,
}: Props) {
  const [form] = Form.useForm<DownloadReportsRequest>();
  const [openFilter, setOpenFilter] = useState(false);
  const initialValues: DownloadReportsRequest = {
    customerGroupId: adminGroupId,
    subgroupIds: [],
    startDate: undefined,
    endDate: undefined,
    page: 0,
    size: 10,
    sort: "createdDate,DESC",
    scheduleExportType: scheduleExportType,
  };
  const [query, setQuery] = useState<DownloadReportsRequest>(initialValues);
  const [downloadData, setDownloadData] = useState<DownloadReportsResponse>();
  const isLoading = useSelector((state: RootState) => state.loading);
  const [selectedFiles, setSelectedFiles] = useState<DownloadFile[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<Option[]>([]);
  const customerGroups = customerGroupsSelectors.selectAll(store.getState());
  function filterCustomerGroups(groups: CustomerGroup[]): CustomerGroup[] {
    const filteredGroups: CustomerGroup[] = [];
    function filterRecursive(id: number) {
      const currentGroup = groups.find((group) => group.id === id);
      if (currentGroup) {
        filteredGroups.push(currentGroup);
        groups
          .filter((group) => group.pId === id)
          .forEach((childGroup) => filterRecursive(childGroup.id));
      }
    }
    filterRecursive(adminGroupId);
    return filteredGroups;
  }

  const groups: Option[] = filterCustomerGroups(customerGroups).map(
    (group) => ({
      value: group.id,
      title: group.title,
    })
  );

  useEffect(() => {
    dispatch(fetchHistoricalReportList(query))
      .then(unwrapResult)
      .then((data: DownloadReportsResponse) => {
        setDownloadData(data);
      })
      .catch(message.error);
    setSelectedFiles([]);
  }, [query]);

  const handleReset = () => {
    form.setFieldsValue(initialValues);
    setSelectedGroups([]);
  };

  function toggleFilterButton() {
    setOpenFilter((prevState) => !prevState);
  }

  const handleSearch: FormProps["onFinish"] = (values) => {
    setQuery((prevState) => ({
      ...prevState,
      subgroupIds: selectedGroups.map((group) => group.value),
      startDate: values.startDate?.startOf("day").valueOf() ?? undefined,
      endDate: values.endDate?.endOf("day").valueOf() ?? undefined,
    }));
    setOpenFilter(false);
  };

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setQuery((prevState) => ({
      ...prevState,
      page: page - 1,
      size: pageSize,
    }));
  };

  const columnConfig: ColumnType<DownloadReportsStatisticsModel>[] = [
    {
      title: "Group",
      dataIndex: "groupName",
      ellipsis: true,
      render: (value) => value || EMPTY,
      width: 144,
    },
    {
      title: "File name",
      dataIndex: "fileName",
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Created at",
      dataIndex: "createdDate",
      ellipsis: true,
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend", "descend"],
      sorter: true,
      render: (value) => (value ? moment(value).format(DATE_FORMAT) : EMPTY),
      width: 180,
    },
  ];

  const handleTableChange: TableProps<
    DownloadReportsStatisticsModel
  >["onChange"] = (pagination, filters, sorter, extra) => {
    const sortColumn = sorter as SorterResult<DownloadReportsStatisticsModel>;
    if ("sort" === extra.action) {
      setQuery((prevState) => ({
        ...prevState,
        page: undefined,
        sort: `createdDate,${sortColumn.order === "ascend" ? "ASC" : "DESC"}`,
      }));
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedFiles.map((file) => file.downloadLink),
    onChange: (
      selectedRowKeys: React.Key[],
      selectedRows: DownloadReportsStatisticsModel[]
    ) => {
      setSelectedFiles(
        selectedRows.map((row) => ({
          fileName: row.fileName,
          downloadLink: row.downloadLink,
        }))
      );
    },
    columnTitle: (
      <Checkbox
        checked={
          selectedFiles.length === downloadData?.content.length &&
          selectedFiles.length > 0
        }
        onChange={(e) =>
          e.target.checked
            ? setSelectedFiles(
                downloadData
                  ? downloadData.content.map((file) => ({
                      fileName: file.fileName,
                      downloadLink: file.downloadLink,
                    }))
                  : []
              )
            : setSelectedFiles([])
        }
      />
    ),
  };

  const handleDownload = () => {
    downloadFiles(selectedFiles, scheduleExportType.toLowerCase() + "_").catch(
      message.error
    );
  };

  const filterLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const filterContent = (
    <Form
      {...filterLayout}
      form={form}
      initialValues={initialValues}
      onFinish={handleSearch}
    >
      <Form.Item label="Group" style={{ marginBottom: "10px" }}>
        <DropdownInput
          options={groups}
          placeholder="All groups"
          value={selectedGroups}
          onChange={(value) => setSelectedGroups(value)}
          width={314}
        />
      </Form.Item>
      <Form.Item label="Created at" style={{ marginBottom: "10px" }}>
        <Form.Item noStyle name="startDate">
          <DateInput
            placeholder="Start date"
            disabledDate={(date: moment.Moment) =>
              date.startOf("day") > moment().startOf("day") ||
              (form.getFieldValue("endDate") &&
                date > form.getFieldValue("endDate"))
            }
            className={styles.dataInput}
          />
        </Form.Item>
        <span className={styles.to}>to</span>
        <Form.Item noStyle name="endDate">
          <DateInput
            placeholder="End date"
            disabledDate={(date: moment.Moment) =>
              date.valueOf() > moment().valueOf() ||
              (form.getFieldValue("startDate") &&
                date < form.getFieldValue("startDate"))
            }
            className={styles.dataInput}
          />
        </Form.Item>
      </Form.Item>

      <Space className={styles.buttonGroup}>
        <Button type="default" shape="round" onClick={toggleFilterButton}>
          Cancel
        </Button>
        <Button type="default" shape="round" onClick={handleReset}>
          Reset
        </Button>
        <Button type="primary" shape="round" htmlType="submit">
          OK
        </Button>
      </Space>
    </Form>
  );
  return (
    <div className={styles.modalContainer}>
      {isLoading && (
        <>
          <div className={styles.loadingOverlay} />
          <Loading />
        </>
      )}
      <Row justify={"space-between"}>
        <Popover
          content={filterContent}
          trigger="click"
          placement="bottomLeft"
          getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
          visible={openFilter}
          onVisibleChange={toggleFilterButton}
          overlayClassName={styles.filter}
        >
          <Button type="default" shape="round">
            Filter
            <Icon component={DropDown} />
          </Button>
        </Popover>
        <Button
          type="primary"
          shape="round"
          onClick={handleDownload}
          disabled={selectedFiles.length < 1}
        >
          Download
        </Button>
      </Row>
      <Table
        rowKey={(record) => record.downloadLink}
        rowSelection={rowSelection}
        className={styles.table}
        columns={columnConfig}
        dataSource={downloadData?.content}
        onChange={handleTableChange}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}~${range[1]} of ${total} items`,
          total: downloadData?.totalElements,
          showSizeChanger: true,
          current: downloadData && downloadData?.page + 1,
          pageSize: downloadData?.size,
          onChange: handlePaginationChange,
        }}
      />
    </div>
  );
}

export default DownloadReports;

import React from "react";
import { Checkbox, Form, FormInstance, FormProps, Input } from "antd";
import { RedeemableProductModel, VoucherType } from "types/model/assignment";
import CourseSelectorForm from "../CourseSelectorForm";

interface Props extends Pick<FormProps, "onFinish"> {
  redeemableProducts?: RedeemableProductModel[];
  form: FormInstance;
  voucherType?: VoucherType;
  isCreatingAuth0AccountForSeatUserEnabled?: boolean;
}

function AssignUserForm({
  redeemableProducts,
  form,
  onFinish,
  voucherType,
  isCreatingAuth0AccountForSeatUserEnabled,
}: Props) {
  const handleBlur = () => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      assigneeList: form.getFieldsValue().assigneeList.map((item: any) => ({
        ...item,
        email: item.email && item.email.trim(),
        name: item.name && item.name.trim(),
      })),
    });
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      {voucherType === "SEAT" && redeemableProducts && (
        <CourseSelectorForm
          form={form}
          redeemableProducts={redeemableProducts}
        />
      )}
      <Form.List name="assigneeList" initialValue={[{}]}>
        {(fields) => (
          <div>
            {fields.map((field, index) => (
              <React.Fragment key={index}>
                <Form.Item
                  label="Email:"
                  name={[field.name, "email"]}
                  rules={[
                    {
                      type: "email",
                      message: "'Email' is not a valid email",
                    },
                    {
                      required: true,
                      message: "'Email' is required",
                    },
                    {
                      type: "string",
                      max: 100,
                      message: "'Email' cannot be longer than 100 characters",
                    },
                  ]}
                >
                  <Input onBlur={handleBlur} />
                </Form.Item>
                <Form.Item label="Name:" name={[field.name, "name"]}>
                  <Input onBlur={handleBlur} />
                </Form.Item>
              </React.Fragment>
            ))}
          </div>
        )}
      </Form.List>
      <Form.Item
        name="autoSendEmail"
        initialValue={true}
        valuePropName="checked"
        style={{ marginBottom: "0px" }}
      >
        <Checkbox>
          Automatically send on-boarding email(s) after assigning user(s)
        </Checkbox>
      </Form.Item>
      {voucherType === "SEAT" && isCreatingAuth0AccountForSeatUserEnabled && (
        <Form.Item
          name="applyEasyPassword"
          initialValue={false}
          valuePropName="checked"
        >
          <Checkbox>Apply easy password</Checkbox>
        </Form.Item>
      )}
    </Form>
  );
}

export default AssignUserForm;

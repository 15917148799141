import React, { useEffect, useState } from "react";
import { DropDown } from "assets/icons";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Popover,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  TimePicker,
} from "antd";
import Icon from "@ant-design/icons/lib/components/Icon";
import moment from "moment";
import styles from "./Recurrence.module.scss";
import { ScheduleExportParams } from "../../../../types/dto/request/reporting";
import {
  cronFormat,
  formatScheduleExport,
  parseCronExpression,
} from "../../../../utils/recurrence";

interface Props {
  onConfirm: (cron?: string, beginDate?: number, endDate?: number) => void;
  scheduleExport: ScheduleExportParams;
  currentBeginDate: number | null;
  currentEndDate: number | null;
  errorDisplay: boolean;
}

interface FormErrors {
  exportTime?: string;
  oneTimeExport?: string;
  earlierThanNow?: string;
  beginDate?: string;
  endDate?: string;
  repeatWeek?: string;
  repeatMonth?: string;
}
function Recurrence({
  onConfirm,
  scheduleExport,
  currentBeginDate,
  currentEndDate,
  errorDisplay,
}: Props) {
  const [form] = Form.useForm();
  const parsedCron = parseCronExpression(scheduleExport.cronExpression);
  const [visible, setVisible] = useState(false);
  const [recurrenceTypeValue, setRecurrenceTypeValue] = useState(1);
  const [exportTime, setExportTime] = useState<moment.Moment | null>();
  const [oneTimeExport, setOneTimeExport] = useState<moment.Moment | null>();
  const [beginDate, setBeginDate] = useState<moment.Moment | null>();
  const [isOpenEnded, setIsOpenEnded] = useState(true);
  const [endDate, setEndDate] = useState<moment.Moment | null>();
  const [repeatWeek, setRepeatWeek] = useState("");
  const [repeatMonth, setRepeatMonth] = useState("");
  const [formErrors, setFormErrors] = useState<FormErrors>();

  useEffect(() => {
    if (visible) {
      setRecurrenceTypeValue(parsedCron.recurrenceTypeValue);
      setExportTime(parsedCron.exportTime);
      setOneTimeExport(parsedCron.oneTimeExport);
      setRepeatWeek(parsedCron.repeatWeek ?? "");
      setRepeatMonth(parsedCron.repeatMonth ?? "");
      currentBeginDate && setBeginDate(moment(currentBeginDate));
      if (currentEndDate) {
        setIsOpenEnded(false);
        setEndDate(moment(currentEndDate));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const weeklyValue = [
    {
      value: "1",
      label: "Sunday",
    },
    {
      value: "2",
      label: "Monday",
    },
    {
      value: "3",
      label: "Tuesday",
    },
    {
      value: "4",
      label: "Wednesday",
    },
    {
      value: "5",
      label: "Thursday",
    },
    {
      value: "6",
      label: "Friday",
    },
    {
      value: "7",
      label: "Saturday",
    },
  ];

  const monthlyValue = [
    {
      value: "1",
      label: "Day 1",
    },
    {
      value: "15",
      label: "Day 15",
    },
    {
      value: "L",
      label: "The last day",
    },
  ];

  const setError = (fieldName: string, message: string) => {
    setFormErrors((prevState) => ({ ...prevState, [fieldName]: message }));
  };

  const checkError = () => {
    setFormErrors({});
    if (recurrenceTypeValue === 1) {
      if (!oneTimeExport) {
        setError("oneTimeExport", '"Customize a recurrence" is required.');
        return false;
      }
      const combinedExportTime = moment(
        `${oneTimeExport?.format("YYYY-MM-DD")}T${exportTime?.format(
          "HH:mm:ss"
        )}`
      );
      if (combinedExportTime.isBefore(moment())) {
        setError(
          "earlierThanNow",
          '"Schedule time" should not be earlier than now.'
        );
        return false;
      }
    }
    if (recurrenceTypeValue === 3) {
      if (!repeatWeek) {
        setError("repeatWeek", '"Customize a recurrence" is required.');
        return false;
      }
    }
    if (recurrenceTypeValue === 4) {
      if (!repeatMonth) {
        setError("repeatMonth", '"Customize a recurrence" is required.');
        return false;
      }
    }
    if (!exportTime) {
      setError("exportTime", '"Export time" is required.');
      return false;
    }
    if (recurrenceTypeValue !== 1) {
      if (!beginDate) {
        setError("beginDate", '"Start date" is required.');
        return false;
      }
      if (!endDate && !isOpenEnded) {
        setError("endDate", '"End date" is required.');
        return false;
      }
    }
    return true;
  };

  const confirmAndCloseModal = () => {
    setVisible(false);
    exportTime &&
      onConfirm(
        cronFormat(
          recurrenceTypeValue,
          exportTime,
          repeatWeek,
          repeatMonth,
          oneTimeExport
        ),
        beginDate?.startOf("day").valueOf(),
        isOpenEnded ? undefined : endDate?.endOf("day").valueOf()
      );
  };

  const content = (
    <Form form={form}>
      <div className={styles.content}>
        <Radio.Group
          onChange={(e: RadioChangeEvent) => {
            setFormErrors({});
            setRecurrenceTypeValue(e.target.value);
            if (e.target.value === 1) {
              setBeginDate(undefined);
              setIsOpenEnded(true);
              setEndDate(undefined);
            }
          }}
          value={recurrenceTypeValue}
          style={{ width: "100%" }}
        >
          <Space direction="vertical" style={{ width: "350px" }}>
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: "8px" }}
            >
              <Radio value={1}>One-time export</Radio>
              <div className={styles.container}>
                <span className={styles.text}>on</span>
                <DatePicker
                  defaultValue={parsedCron.oneTimeExport}
                  onChange={(val) => {
                    setFormErrors({});
                    setOneTimeExport(val);
                  }}
                  disabled={recurrenceTypeValue !== 1}
                  disabledDate={(current: moment.Moment) => {
                    return current && current < moment().startOf("day");
                  }}
                  className={
                    (formErrors?.oneTimeExport &&
                      formErrors?.oneTimeExport?.length > 0) ||
                    (formErrors?.earlierThanNow &&
                      formErrors?.earlierThanNow?.length > 0)
                      ? styles.errorPicker
                      : undefined
                  }
                />
                {formErrors && (
                  <div className={styles.errorContainerWithOn}>
                    <span className={styles.error}>
                      {formErrors?.oneTimeExport}
                      {formErrors?.earlierThanNow}
                    </span>
                  </div>
                )}
              </div>
            </Row>

            <Radio value={2} style={{ height: "32px" }}>
              Daily export
            </Radio>

            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: "8px" }}
            >
              <Radio value={3}>Weekly export</Radio>
              <div className={styles.container}>
                <span className={styles.text}>on</span>
                <Select
                  defaultValue={
                    parsedCron.repeatWeek && parsedCron.repeatWeek?.length > 0
                      ? parsedCron.repeatWeek
                      : undefined
                  }
                  placeholder="Select day"
                  disabled={recurrenceTypeValue !== 3}
                  style={{ width: 130 }}
                  onChange={(val: string) => {
                    setFormErrors({});
                    setRepeatWeek(val);
                  }}
                  options={weeklyValue}
                  className={
                    formErrors?.repeatWeek && formErrors?.repeatWeek?.length > 0
                      ? styles.errorSelect
                      : undefined
                  }
                />
                {formErrors && (
                  <div className={styles.errorContainerWithOn}>
                    <span className={styles.error}>
                      {formErrors?.repeatWeek}
                    </span>
                  </div>
                )}
              </div>
            </Row>

            <Row justify="space-between" align="middle">
              <Radio value={4}>Monthly export</Radio>
              <div className={styles.container}>
                <span className={styles.text}>on</span>
                <Select
                  defaultValue={
                    parsedCron.repeatMonth && parsedCron.repeatMonth?.length > 0
                      ? parsedCron.repeatMonth
                      : undefined
                  }
                  placeholder="Select day"
                  disabled={recurrenceTypeValue !== 4}
                  style={{ width: 130 }}
                  onChange={(value: string) => {
                    setFormErrors({});
                    setRepeatMonth(value);
                  }}
                  options={monthlyValue}
                  className={
                    formErrors?.repeatMonth &&
                    formErrors?.repeatMonth?.length > 0
                      ? styles.errorSelect
                      : undefined
                  }
                />
                {formErrors && (
                  <div className={styles.errorContainerWithOn}>
                    <span className={styles.error}>
                      {formErrors?.repeatMonth}
                    </span>
                  </div>
                )}
              </div>
            </Row>
          </Space>
          <Divider />

          <Row
            align="middle"
            justify="space-between"
            style={{ width: "350px" }}
          >
            <Form.Item
              label="Export report at"
              name="exportTime"
              rules={[{ required: true }]}
              className={styles.form}
            />
            <div className={styles.container}>
              <TimePicker
                onChange={(value) => {
                  setFormErrors({});
                  setExportTime(value);
                }}
                defaultValue={parsedCron.exportTime}
                format="HH:mm"
                defaultOpenValue={moment()}
                style={{ width: "130px" }}
                className={
                  (formErrors?.exportTime &&
                    formErrors?.exportTime?.length > 0) ||
                  (formErrors?.earlierThanNow &&
                    formErrors?.earlierThanNow?.length > 0)
                    ? styles.errorPicker
                    : undefined
                }
              />
              {formErrors && (
                <div className={styles.errorContainer}>
                  <span className={styles.error}>
                    {formErrors?.exportTime}
                    {formErrors?.earlierThanNow}
                  </span>
                </div>
              )}
            </div>
          </Row>
        </Radio.Group>

        {recurrenceTypeValue !== 1 && (
          <>
            <Divider />
            <Row align="middle" style={{ marginBottom: "18px" }}>
              <Form.Item
                label="Start date"
                name="beginDate"
                rules={[{ required: true }]}
                className={styles.form}
              />
              <div className={styles.container}>
                <DatePicker
                  defaultValue={
                    currentBeginDate ? moment(currentBeginDate) : undefined
                  }
                  onChange={(value) => {
                    setFormErrors({});
                    setBeginDate(value);
                  }}
                  disabledDate={(date: moment.Moment) =>
                    date < moment().startOf("day") ||
                    (endDate && endDate.startOf("day")
                      ? date > endDate.startOf("day")
                      : false)
                  }
                  className={
                    formErrors?.beginDate && formErrors?.beginDate?.length > 0
                      ? styles.errorPicker
                      : undefined
                  }
                />
                {formErrors && (
                  <div
                    className={styles.errorContainer}
                    style={{ lineHeight: 1 }}
                  >
                    <span className={styles.error}>
                      {formErrors?.beginDate}
                    </span>
                  </div>
                )}
              </div>
            </Row>

            <Row style={{ marginTop: "10px" }}>
              <Form.Item
                label="End date"
                name="endDate"
                rules={[{ required: true }]}
                className={styles.form}
              />
              <Radio.Group
                onChange={(e: RadioChangeEvent) => {
                  setFormErrors({});
                  setIsOpenEnded(e.target.value);
                }}
                value={isOpenEnded}
                style={{ marginTop: "6px" }}
              >
                <Space direction="vertical">
                  <Radio value={true}>Open ended</Radio>
                  <Space size={4}>
                    <Radio value={false}>End on</Radio>
                    <div className={styles.container}>
                      <DatePicker
                        defaultValue={
                          currentEndDate ? moment(currentEndDate) : undefined
                        }
                        onChange={(value) => {
                          setFormErrors({});
                          setEndDate(value);
                        }}
                        disabledDate={(date: moment.Moment) => {
                          if (date && beginDate)
                            return date < beginDate?.startOf("day");
                          else return false;
                        }}
                        disabled={isOpenEnded}
                        className={
                          formErrors?.endDate && formErrors?.endDate?.length > 0
                            ? styles.errorPicker
                            : undefined
                        }
                      />
                      {formErrors && (
                        <div className={styles.errorContainer}>
                          <span className={styles.error}>
                            {formErrors?.endDate}
                          </span>
                        </div>
                      )}
                    </div>
                  </Space>
                </Space>
              </Radio.Group>
            </Row>
          </>
        )}

        <Space className={styles.buttonGroup}>
          <Button
            type="default"
            shape="round"
            onClick={() => setVisible(false)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            shape="round"
            onClick={() => {
              checkError() && confirmAndCloseModal();
            }}
          >
            Confirm
          </Button>
        </Space>
      </div>
    </Form>
  );

  return (
    <Popover
      content={content}
      overlayClassName={styles.overlay}
      placement="bottomLeft"
      trigger={["click"]}
      visible={visible}
      onVisibleChange={() => {
        setFormErrors({});
        setVisible(!visible);
      }}
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
    >
      <Button className={styles.dropdownInput} danger={errorDisplay}>
        <span
          className={
            scheduleExport.cronExpression.length > 0
              ? undefined
              : styles.placeholder
          }
        >
          {formatScheduleExport(scheduleExport.cronExpression)}
        </span>
        <Icon component={DropDown} className={styles.icon} />
      </Button>
    </Popover>
  );
}

export default Recurrence;

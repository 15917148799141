import React, { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { StringParam, useQueryParam } from "use-query-params";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { Table, Tabs } from "antd";
import { message } from "components";
import { RootState, useAppDispatch } from "app/store";
import {
  AssignmentListParams,
  RedemptionListParams,
} from "types/dto/request/assignment";
import { DEFAULT_PAGINATION } from "constants/common";
import {
  fetchAssignmentList,
  fetchRedemptionList,
} from "../../voucherGroupsSlice";
import Toolbar from "./Toolbar";
import useColumns from "../useColumns";

import styles from "./MasterVoucherAssignment.module.scss";

function MasterVoucherAssignment() {
  const dispatch = useAppDispatch();
  const { customerGroupId, voucherGroupId } = useParams<{
    customerGroupId: string;
    voucherGroupId: string;
  }>();
  const [redeemerEmail] = useQueryParam("redeemerEmail", StringParam);
  const adminGroupId = parseInt(customerGroupId);
  const [email, setEmail] = useState(redeemerEmail || "");

  const initialFilterState = {
    ...DEFAULT_PAGINATION,
    voucherGroupId: voucherGroupId,
    adminGroupId: adminGroupId,
  };

  const resetFilter = () => {
    setEmail("");
    setAssignmentFilter(initialFilterState);
    setRedemptionFilter(initialFilterState);
  };

  const {
    masterRedemptionColumnConfig,
    masterAssignmentColumnConfig,
  } = useColumns("MASTER", voucherGroupId, adminGroupId, resetFilter);

  const [assignmentFilter, setAssignmentFilter] = useState<
    AssignmentListParams
  >(initialFilterState);

  const [redemptionFilter, setRedemptionFilter] = useState<
    RedemptionListParams
  >({ ...initialFilterState, redeemerEmail: email.trim() });

  const {
    currentPage,
    currentSize,
    totalAssignElements,
    totalRedeemElements,
    redemptionList,
    assignmentList,
  } = useSelector((state: RootState) => state.voucherGroups);

  useEffect(() => {
    dispatch(fetchRedemptionList(redemptionFilter))
      .then(unwrapResult)
      .catch(message.error);
  }, [dispatch, redemptionFilter]);

  useEffect(() => {
    dispatch(fetchAssignmentList(assignmentFilter))
      .then(unwrapResult)
      .catch(message.error);
  }, [dispatch, assignmentFilter]);

  const handleAssignPaginationChange = (page: number, pageSize?: number) => {
    setAssignmentFilter((prevState) => ({
      ...prevState,
      page: page - 1,
      size: pageSize,
    }));
  };

  const handleRedeemedPaginationChange = (page: number, pageSize?: number) => {
    setRedemptionFilter((prevState) => ({
      ...prevState,
      page: page - 1,
      size: pageSize,
    }));
  };

  return (
    <>
      <Tabs
        defaultActiveKey={redeemerEmail ? "2" : "1"}
        type="card"
        className={styles.tab}
        onChange={resetFilter}
      >
        <Tabs.TabPane tab="Assignment status" key="1">
          <Toolbar
            filter={assignmentFilter}
            setFilter={setAssignmentFilter}
            email={email}
            setEmail={setEmail}
            resetFilter={resetFilter}
          />
          <Table
            className={styles.assignTable}
            rowKey={(record) => record.assignmentId}
            dataSource={assignmentList}
            columns={masterAssignmentColumnConfig}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}~${range[1]} of ${total} items`,
              total: totalAssignElements,
              current: currentPage,
              pageSize: currentSize,
              showSizeChanger: true,
              onChange: handleAssignPaginationChange,
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Redemption status" key="2">
          <Toolbar
            isRedeem
            filter={redemptionFilter}
            setFilter={setRedemptionFilter}
            email={email}
            setEmail={setEmail}
            resetFilter={resetFilter}
          />
          <Table
            rowKey={(record) => record.redemptionId}
            dataSource={redemptionList}
            columns={masterRedemptionColumnConfig}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}~${range[1]} of ${total} items`,
              total: totalRedeemElements,
              current: currentPage,
              pageSize: currentSize,
              showSizeChanger: true,
              onChange: handleRedeemedPaginationChange,
            }}
          />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}

export default MasterVoucherAssignment;

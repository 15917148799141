import {
  combineReducers,
  createReducer,
  isFulfilled,
  isRejected,
} from "@reduxjs/toolkit";
import voucherGroupsReducer from "features/assignment/voucherGroupsSlice";
import customerGroupsReducer from "features/customerGroup/customerGroupsSlice";
import reportingReducer from "features/reporting/reportingSlice";
import userListReducer from "features/reporting/userListSlice";

const loadingReducer = createReducer(false, (builder) => {
  builder.addMatcher(
    (action) =>
      action.type.endsWith("pending") &&
      action.type !== "vouchers/getVoucherCodeInformation/pending",
    () => {
      return true;
    }
  );
  builder.addMatcher(isFulfilled, () => {
    return false;
  });
  builder.addMatcher(isRejected, () => {
    return false;
  });
});

const rootReducer = combineReducers({
  voucherGroups: voucherGroupsReducer,
  customerGroups: customerGroupsReducer,
  reporting: reportingReducer,
  userList: userListReducer,
  loading: loadingReducer,
});

export default rootReducer;

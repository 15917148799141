import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Row, Table, TableProps } from "antd";
import { RootState, useAppDispatch } from "app/store";
import { message, Modal } from "components";
import { SorterResult } from "antd/es/table/interface";
import { DEFAULT_PAGINATION } from "constants/common";
import { SubgroupListParams } from "types/dto/request/customerGroup";
import { SubgroupListResponse } from "types/dto/response/customerGroup";
import {
  fetchSubgroup,
  removeCustomerGroup,
  selectCurrentGroup,
} from "../customerGroupsSlice";
import useColumns from "./useColumns";
import { DELETION_CONFIRM_MSG } from "../configs";

type Record = SubgroupListResponse;

interface IState {
  dataSource: Record[];
  total: number;
  current: number;
  currentSize: number;
}

const initialState: IState = {
  dataSource: [],
  total: 0,
  current: 1,
  currentSize: 10,
};

function SubgroupList() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentGroupId: adminGroupId } = useSelector(
    (rootState: RootState) => rootState.customerGroups
  );
  const customerGroup = useSelector(selectCurrentGroup);
  const [state, setState] = useState<IState>(initialState);

  const [filter, setFilter] = useState<SubgroupListParams>({
    ...DEFAULT_PAGINATION,
    sort: "lastModifiedDate,DESC",
  });

  const fetchData = useCallback(
    (params: SubgroupListParams) => {
      return dispatch(fetchSubgroup(params))
        .then(unwrapResult)
        .then((response) =>
          setState({
            dataSource: response.content,
            current: response.page + 1,
            currentSize: response.size,
            total: response.totalElements,
          })
        )
        .catch(message.error);
    },
    [dispatch]
  );

  useEffect(() => {
    fetchData({ ...filter, customerGroupId: adminGroupId });
  }, [dispatch, fetchData, filter, adminGroupId]);

  const handlePaginationChange = (page: number, pageSize?: number) => {
    setFilter((prevState) => ({
      ...prevState,
      page: page - 1,
      size: pageSize,
    }));
  };

  const handleTableChange: TableProps<Record>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    const { order } = sorter as SorterResult<Record>;
    if (extra.action === "sort") {
      order &&
        setFilter((prevState) => ({
          ...prevState,
          page: undefined,
          sort: `lastModifiedDate,${order === "ascend" ? "ASC" : "DESC"}`,
        }));
    }
  };

  const onDelete = (record: Record) => {
    return Modal.confirm({
      title: "Confirm",
      content: DELETION_CONFIRM_MSG(record.name),
      onOk: () =>
        dispatch(removeCustomerGroup(record.id))
          .then(unwrapResult)
          .then(() => {
            if (state.dataSource.length === 1) {
              fetchData({
                ...filter,
                customerGroupId: adminGroupId,
                page: state.current - 1,
              });
            } else {
              fetchData({ ...filter, customerGroupId: adminGroupId });
            }
          })
          .catch(message.error),
    });
  };

  const columnConfig = useColumns(onDelete);

  return (
    <>
      <Row justify={"space-between"} style={{ marginBottom: "24px" }}>
        <h3>Subgroups</h3>
        <Button
          onClick={() => history.push(history.location.pathname + "/add")}
          type="primary"
          shape="round"
          hidden={!customerGroup?.isCreatingSubgroupEnabled ?? true}
        >
          + Add subgroup
        </Button>
      </Row>
      <Table<Record>
        rowKey={(record) => record.id}
        dataSource={state.dataSource}
        columns={columnConfig}
        onChange={handleTableChange}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}~${range[1]} of ${total} items`,
          total: state.total,
          current: state.current,
          showSizeChanger: true,
          pageSize: state.currentSize,
          onChange: handlePaginationChange,
        }}
      />
    </>
  );
}

export default SubgroupList;

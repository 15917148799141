import React, { ChangeEvent, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Form, Input, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { DisplayInput, message } from "components";
import { unwrapResult } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { RootState, useAppDispatch } from "../../../app/store";
import { getVoucherCodeInformation } from "../voucherGroupsSlice";

import styles from "./SearchVoucherInformation.module.scss";

function SearchOrganizationByVoucher() {
  const dispatch = useAppDispatch();
  const { voucherCodeInformation } = useSelector(
    (state: RootState) => state.voucherGroups
  );
  const [voucherCode, setVoucherCode] = useState("");

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVoucherCode(e.target.value);
  };

  const handleSearchByVoucher = () => {
    const newVoucherCode = voucherCode.trim();
    setVoucherCode(newVoucherCode);
    return (
      newVoucherCode &&
      dispatch(getVoucherCodeInformation(newVoucherCode))
        .then(unwrapResult)
        .then((data) => {
          if (isEmpty(data)) {
            return message.error("Invalid voucher code!");
          }
        })
        .catch(message.error)
    );
  };

  const renderSearchResult = () => {
    return (
      <Form layout="vertical">
        <Form.Item label="Admin group:">
          <DisplayInput value={voucherCodeInformation?.adminGroupName} />
        </Form.Item>
        <Form.Item label="Invoice number:">
          <DisplayInput value={voucherCodeInformation?.invoice} />
        </Form.Item>
      </Form>
    );
  };

  return (
    <Tooltip
      placement="bottom"
      className={styles.tooltip}
      title={renderSearchResult}
      getPopupContainer={(triggerNode) => triggerNode}
    >
      <Input
        allowClear
        value={voucherCode}
        prefix={<SearchOutlined />}
        onChange={handleInputChange}
        onBlur={() => setVoucherCode((prevState) => prevState.trim())}
        onPressEnter={handleSearchByVoucher}
        placeholder="Search voucher code"
      />
    </Tooltip>
  );
}

export default SearchOrganizationByVoucher;

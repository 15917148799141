import { useAuth0 } from "@auth0/auth0-react";

const useAuth0User = () => {
  const { REACT_APP_AUTH0_AUDIENCE } = process.env;
  const { user } = useAuth0();

  user.isB2bAdmin = user[REACT_APP_AUTH0_AUDIENCE]?.roles.includes(
    "PIMSLEUR_B2B_ADMIN"
  );

  return user;
};

export default useAuth0User;

import React, { useEffect, useState } from "react";
import { Progress, Table } from "antd";
import moment from "moment";
import { fetchLessonProgressSummary } from "../../../../services/reporting";
import { LessonProgressSummaryModel } from "../../../../types/model/reporting";
import { DATE_FORMAT, EMPTY } from "../../../../constants/common";

interface Props {
  customerGroupId: number;
  customerId: number;
  courseName: string;
  subUserNames: string[];
  rootUserName: string;
}

export default function ({
  customerGroupId,
  customerId,
  courseName,
  subUserNames,
  rootUserName,
}: Props) {
  const [dataSource, setDataSource] = useState<LessonProgressSummaryModel[]>(
    []
  );

  useEffect(() => {
    fetchLessonProgressSummary(customerGroupId, customerId, courseName).then(
      (data) => {
        setDataSource(
          subUserNames.map((subUserName) => {
            let record = data.find((item) => item.subUserName === subUserName);
            if (!record) {
              record = {
                courseCompletedPercent: 0,
                firstActivityDate: null,
                lastActivityDate: null,
                listenTimeMinutes: null,
                subUserName,
              };
            }
            return record;
          })
        );
      }
    );
  }, [customerGroupId, customerId, courseName]);

  return (
    <Table
      rowKey="subUserName"
      pagination={false}
      dataSource={dataSource}
      columns={[
        {
          title: "Subuser",
          dataIndex: "subUserName",
          render: (value) => {
            if (value === rootUserName) {
              return (
                <>
                  {value}
                  <div style={{ color: "#858585", fontSize: "12px" }}>
                    Account holder
                  </div>
                </>
              );
            }
            return value;
          },
        },
        {
          title: "First activity date",
          dataIndex: "firstActivityDate",
          render: (value) =>
            value ? moment(value).format(DATE_FORMAT) : EMPTY,
        },
        {
          title: "Last activity date",
          dataIndex: "lastActivityDate",
          render: (value) =>
            value ? moment(value).format(DATE_FORMAT) : EMPTY,
        },
        {
          title: "Completion",
          dataIndex: "courseCompletedPercent",
          render: (value) => <Progress percent={value} status="active" />,
        },
        {
          title: "Listen time(Mins)",
          dataIndex: "listenTimeMinutes",
          render: (value) => value ?? EMPTY,
        },
      ]}
    />
  );
}

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import * as reportingAPI from "../../services/reporting";
import {
  ActiveUsersStatisticsResponse,
  CategorizedLearningTimeStatisticsResponse,
  DailyLearningTimeStatisticsResponse,
  ReportingStatisticsResponse,
  ReportingSummaryResponse,
} from "../../types/dto/response/reporting";
import { LessonProgressStatisticsModel } from "../../types/model/reporting";
import { RootState } from "../../app/store";

export const fetchLessonProgressStatistics = createAsyncThunk(
  "reporting/fetchLessonProgressStatistics",
  reportingAPI.fetchLessonProgressStatistics
);

export const fetchReportingSummary = createAsyncThunk(
  "reporting/fetchReportingSummary",
  reportingAPI.fetchReportingSummary
);

export const fetchCourseRedemptionStatistics = createAsyncThunk(
  "reporting/fetchCourseRedemptionStatistics",
  reportingAPI.fetchCourseRedemptionStatistics
);

export const fetchCourseCompletionStatistics = createAsyncThunk(
  "reporting/fetchCourseCompletionStatistics",
  reportingAPI.fetchCourseCompletionStatistics
);

export const fetchActiveUsersStatistics = createAsyncThunk(
  "reporting/fetchActiveUsersStatistics",
  reportingAPI.fetchActiveUsersStatistics
);

export const fetchTotalActiveUsers = createAsyncThunk(
  "reporting/fetchTotalActiveUsers",
  reportingAPI.fetchTotalActiveUsers
);

export const fetchTotalLearningTime = createAsyncThunk(
  "reporting/fetchTotalLearningTime",
  reportingAPI.fetchTotalLearningTime
);

export const fetchDailyLearningTime = createAsyncThunk(
  "reporting/fetchDailyLearningTime",
  reportingAPI.fetchDailyLearningTime
);

export const fetchCategorizedLearningTime = createAsyncThunk(
  "reporting/fetchCategorizedLearningTime",
  reportingAPI.fetchCategorizedLearningTime
);

export const exportLessonProgressStatistics = createAsyncThunk(
  "reporting/exportLessonProgressStatistics",
  reportingAPI.exportLessonProgressStatistics
);

export const scheduleExportLessonProgress = createAsyncThunk(
  "reporting/scheduleExportLessonProgress",
  reportingAPI.scheduleExportLessonProgress
);

export const fetchScheduleExportLessonProgress = createAsyncThunk(
  "reporting/fetchScheduleExportLessonProgress",
  reportingAPI.fetchScheduleExportLessonProgress
);

export const fetchHistoricalReportList = createAsyncThunk(
  "reporting/fetchHistoricalReportList",
  reportingAPI.fetchHistoricalReportList
);

export const removeScheduleExportLessonProgress = createAsyncThunk(
  "reporting/removeScheduleExportLessonProgress",
  reportingAPI.removeScheduleExportLessonProgress
);

const reportingAdapter = createEntityAdapter<LessonProgressStatisticsModel>({
  selectId: (record) => record.customerId + record.courseName,
});

const ReportingSlice = createSlice({
  name: "Reporting",
  initialState: reportingAdapter.getInitialState({
    currentPage: 1,
    currentSize: 10,
    totalElements: 0,
    reportingSummary: {} as ReportingSummaryResponse,
    courseRedemptionStatistics: [] as ReportingStatisticsResponse[],
    courseCompletionStatistics: [] as ReportingStatisticsResponse[],
    activeUsersStatistics: [] as ActiveUsersStatisticsResponse[],
    totalLearningTime: 0,
    dailyTotalLearningTime: [] as DailyLearningTimeStatisticsResponse[],
    categorizedLearningTime: [] as CategorizedLearningTimeStatisticsResponse[],
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchLessonProgressStatistics.fulfilled,
      (state, { payload }) => {
        const { content, totalElements, page, size } = payload;
        state.currentPage = page + 1;
        state.currentSize = size;
        state.totalElements = totalElements;
        reportingAdapter.setAll(state, content);
      }
    );
    builder.addCase(fetchReportingSummary.fulfilled, (state, { payload }) => {
      state.reportingSummary = payload;
    });
    builder.addCase(
      fetchCourseRedemptionStatistics.fulfilled,
      (state, { payload }) => {
        state.courseRedemptionStatistics = payload;
      }
    );
    builder.addCase(
      fetchCourseCompletionStatistics.fulfilled,
      (state, { payload }) => {
        state.courseCompletionStatistics = payload;
      }
    );
    builder.addCase(
      fetchActiveUsersStatistics.fulfilled,
      (state, { payload }) => {
        state.activeUsersStatistics = payload;
      }
    );
    builder.addCase(fetchTotalLearningTime.fulfilled, (state, { payload }) => {
      state.totalLearningTime = payload;
    });
    builder.addCase(fetchDailyLearningTime.fulfilled, (state, { payload }) => {
      state.dailyTotalLearningTime = payload;
    });
    builder.addCase(
      fetchCategorizedLearningTime.fulfilled,
      (state, { payload }) => {
        state.categorizedLearningTime = payload;
      }
    );
  },
});

export default ReportingSlice.reducer;

export const reportingSelectors = reportingAdapter.getSelectors<RootState>(
  (state) => state.reporting
);

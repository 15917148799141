import { Input, InputProps } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React from "react";

export default function InputForSeat(props: InputProps) {
  return (
    <Input
      {...props}
      allowClear
      style={{ width: "233px" }}
      prefix={<SearchOutlined />}
      placeholder="Search assigned email"
    />
  );
}

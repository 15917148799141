import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Card, Spin } from "antd";
import { RootState } from "app/store";
import MyGroupDetailPage from "../MyGroupDetailPage";
import { EMPTY } from "../../../constants/common";
import SubgroupList from "../SubgroupList";
import { selectCurrentGroup } from "../customerGroupsSlice";

const tabList = [
  {
    key: "basicInfo",
    tab: "Basic info",
  },
  {
    key: "subgroup",
    tab: "Subgroup",
  },
];

function MyGroup() {
  const history = useHistory<string>();
  const customerGroup = useSelector(selectCurrentGroup);

  const [activeTab, setActiveTab] = useState<string>("basicInfo");
  const isLoading = useSelector((state: RootState) => state.loading);

  useEffect(() => {
    if (history.location.state != null) {
      setActiveTab(history.location.state);
    }
  }, [history.location.state]);

  return (
    <Card
      title={customerGroup?.name || EMPTY}
      tabList={tabList}
      activeTabKey={activeTab}
      onTabChange={(key) => setActiveTab(key)}
      className="page-container"
    >
      <Spin spinning={isLoading} size={"large"}>
        {activeTab === "basicInfo" ? <MyGroupDetailPage /> : <SubgroupList />}
      </Spin>
    </Card>
  );
}

export default MyGroup;

import React, { ComponentProps, useState } from "react";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Prompt, Redirect, useHistory } from "react-router";
import { message } from "components";
import { Button, Card, Form, Space } from "antd";
import { RootState, store, useAppDispatch } from "app/store";
import { LEAVE_CONFIRMATION } from "constants/common";
import { useParams } from "react-router-dom";
import BasicInfoForm from "../BasicInfoForm";
import {
  createCustomerGroup,
  customerGroupsSelectors,
} from "../customerGroupsSlice";

function SubgroupCreationPage() {
  const history = useHistory();
  const { customerGroupId } = useParams<{ customerGroupId: string }>();
  const customerGroup = customerGroupsSelectors.selectById(
    store.getState(),
    customerGroupId
  );

  const loading = useSelector((state: RootState) => state.loading);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [submit, setSubmit] = useState(false);

  const buttonGroup = (
    <Space size={12}>
      <Button
        type="default"
        shape="round"
        onClick={() =>
          history.push({
            pathname: `/${customerGroupId}/my-group`,
            state: "subgroup",
          })
        }
      >
        Cancel
      </Button>
      <Button
        type="primary"
        shape="round"
        onClick={form.submit}
        loading={loading}
      >
        Save
      </Button>
    </Space>
  );

  const handleSubmit: ComponentProps<typeof BasicInfoForm>["onFinish"] = (
    values
  ) => {
    if (customerGroupId != null) {
      const data = {
        ...values,
        groupAdmins: values?.groupAdmins ?? [],
        parentGroupId: parseInt(customerGroupId),
      };
      dispatch(createCustomerGroup(data))
        .then(unwrapResult)
        .then(() => {
          setSubmit(true);
          history.push({
            pathname: `/${customerGroupId}/my-group`,
            state: "subgroup",
          });
        })
        .catch(message.error);
    }
  };

  return !customerGroup?.isCreatingSubgroupEnabled ? (
    <Redirect to={"/my-group"} />
  ) : (
    <Card title={"Add subgroup"} extra={buttonGroup} className="page-container">
      <Prompt when={!submit} message={LEAVE_CONFIRMATION} />
      <BasicInfoForm form={form} onFinish={handleSubmit} />
    </Card>
  );
}

export default SubgroupCreationPage;

import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import * as queryString from "query-string";
import { unwrapResult } from "@reduxjs/toolkit";
import { Button, Card, Checkbox, Input, Row, Space, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Refresh } from "assets/icons";
import {
  customerGroupsSelectors,
  selectCurrentGroup,
} from "features/customerGroup/customerGroupsSlice";
import { RootState, useAppDispatch } from "app/store";
import { message } from "components";
import { VoucherDistributionListParams } from "types/dto/request/assignment";
import useColumns from "./useColumns";

import {
  fetchVoucherDistributions,
  voucherGroupsSelectors,
} from "../voucherGroupsSlice";
import styles from "./VoucherGroupList.module.scss";

function VoucherDistributionList() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const query: VoucherDistributionListParams = queryString.parse(search, {
    arrayFormat: "comma",
    parseBooleans: true,
  });
  const { customerGroupId } = useParams<{ customerGroupId: string }>();
  const datasource = useSelector(voucherGroupsSelectors.selectAll);
  const { currentPage, currentSize, totalElements, actionToggle } = useSelector(
    (state: RootState) => state.voucherGroups
  );
  const isLoading = useSelector((state: RootState) => state.loading);
  const customerGroup = useSelector(selectCurrentGroup);
  const canDistribute =
    useSelector(customerGroupsSelectors.selectAll).filter(
      (entity) => entity.organizationId === customerGroup.organizationId
    ).length > 1;
  const [invoice, setInvoice] = useState(query.invoice);
  const [refreshTimestamp, setRefreshTimestamp] = useState(Date.now());

  function fetchData() {
    dispatch(
      fetchVoucherDistributions({
        customerGroupId,
        ...query,
      })
    )
      .then(unwrapResult)
      .catch(message.error);
  }

  function updateQuery(queryParams: VoucherDistributionListParams) {
    history.push({
      pathname,
      search: queryString.stringify(queryParams, {
        arrayFormat: "comma",
      }),
    });
  }

  useEffect(fetchData, [
    search,
    customerGroupId,
    actionToggle,
    refreshTimestamp,
  ]);

  return (
    <Card title="Assignment" className="page-container">
      <Row justify={"space-between"} style={{ marginBottom: "24px" }}>
        <Space>
          <Input
            allowClear
            value={invoice}
            prefix={<SearchOutlined />}
            className={styles.invoiceInput}
            onChange={(e) => setInvoice(e.target.value)}
            onPressEnter={() => {
              const newInvoice = invoice?.trim();
              setInvoice(newInvoice);
              updateQuery({ ...query, invoice: newInvoice });
            }}
            onBlur={() => setInvoice((prevState) => prevState?.trim())}
            placeholder="Search invoice number"
          />
          <Checkbox
            checked={query.isActive}
            onChange={(e) =>
              updateQuery({ ...query, isActive: e.target.checked })
            }
          >
            Active Invoice
          </Checkbox>
        </Space>
        <Space>
          <Button
            type="text"
            shape="circle"
            icon={<Refresh />}
            onClick={() => {
              updateQuery({ ...query, invoice, page: 1 });
              setRefreshTimestamp(Date.now());
            }}
          />
        </Space>
      </Row>

      <Table
        loading={{ spinning: isLoading }}
        columns={useColumns(canDistribute)}
        dataSource={datasource}
        className={styles.table}
        rowClassName={styles.tableRow}
        rowKey={(record) => record.voucherGroupId}
        onRow={(record) => ({
          onClick: () => {
            history.push(`${pathname}/${record.voucherGroupId}`);
          },
        })}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}~${range[1]} of ${total} items`,
          total: totalElements,
          showSizeChanger: true,
          current: currentPage,
          pageSize: currentSize,
          onChange: (page, size) => updateQuery({ ...query, page, size }),
        }}
      />
    </Card>
  );
}

export default VoucherDistributionList;

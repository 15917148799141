import Icon from "@ant-design/icons/lib/components/Icon";
import { Button, Divider, Form, FormProps, Input, Popover, Space } from "antd";
import React, { useState } from "react";
import { DropDown } from "assets/icons";
import moment from "moment/moment";
import styles from "./LessonProgressFilter.module.scss";
import DateInput from "../DateInput";
import { LessonProgressStatisticListParams } from "../../../../../types/dto/request/reporting";

export interface PopOverFilterProps {
  initialValues: LessonProgressStatisticListParams;
  onSubmit: (val: LessonProgressStatisticListParams) => void;
}

function LessonProgressFilter({ initialValues, onSubmit }: PopOverFilterProps) {
  const [form] = Form.useForm<LessonProgressStatisticListParams>();
  const [open, setOpen] = useState(false);

  function toggleFilterButton() {
    setOpen((prevState) => {
      form.resetFields();
      return !prevState;
    });
  }

  const handleSearch: FormProps<
    LessonProgressStatisticListParams
  >["onFinish"] = (values) => {
    onSubmit(values);
    toggleFilterButton();
  };

  const handleBlur = () => {
    const email = form.getFieldValue("email");
    const courseName = form.getFieldValue("courseName");
    const customerName = form.getFieldValue("customerName");
    form.setFieldsValue({
      ...form.getFieldsValue(),
      email: email && email.trim(),
      courseName: courseName && courseName.trim(),
      customerName: customerName && customerName.trim(),
    });
  };

  const handleReset = () => {
    form.setFieldsValue({
      email: "",
      lastActivityDateFrom: undefined,
      lastActivityDateTo: undefined,
      courseName: undefined,
      customerName: undefined,
    });
  };

  const content = (
    <Form
      form={form}
      layout="inline"
      className={styles.searchForm}
      onFinish={handleSearch}
      initialValues={initialValues}
    >
      <Space direction="vertical" align="end" size={24}>
        <Form.Item label="Last activity date">
          <Form.Item noStyle name="lastActivityDateFrom">
            <DateInput
              placeholder="Start date"
              disabledDate={(date: moment.Moment) =>
                date.startOf("day") > moment().startOf("day") ||
                (form.getFieldValue("lastActivityDateTo") &&
                  date > form.getFieldValue("lastActivityDateTo"))
              }
            />
          </Form.Item>
          <span style={{ margin: "0 20px" }}>to</span>
          <Form.Item noStyle name="lastActivityDateTo">
            <DateInput
              placeholder="End date"
              disabledDate={(date: moment.Moment) =>
                date.valueOf() > moment().valueOf() ||
                (form.getFieldValue("lastActivityDateFrom") &&
                  date < form.getFieldValue("lastActivityDateFrom"))
              }
            />
          </Form.Item>
        </Form.Item>
        <Form.Item label="Customer email" name="email">
          <Input
            onBlur={handleBlur}
            placeholder="Please enter customer email"
            allowClear
            style={{ width: "314px" }}
          />
        </Form.Item>
      </Space>

      <Space direction="vertical" align="end" size={24}>
        <Form.Item label="Course" name="courseName">
          <Input
            onBlur={handleBlur}
            placeholder="Please enter course name"
            allowClear
            style={{ width: "314px" }}
          />
        </Form.Item>

        <Form.Item label="Customer name" name="customerName">
          <Input
            onBlur={handleBlur}
            placeholder="Please enter customer name"
            allowClear
            style={{ width: "314px" }}
          />
        </Form.Item>
      </Space>

      <Divider />

      <Space className={styles.buttonGroup}>
        <Button type="default" shape="round" onClick={toggleFilterButton}>
          Cancel
        </Button>
        <Button type="default" shape="round" onClick={handleReset}>
          Reset
        </Button>
        <Button type="primary" shape="round" htmlType="submit">
          OK
        </Button>
      </Space>
    </Form>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      placement="bottom"
      visible={open}
      overlayClassName={styles.popover}
      onVisibleChange={toggleFilterButton}
    >
      <Button type="default" shape="round">
        Filter
        <Icon component={DropDown} />
      </Button>
    </Popover>
  );
}

export default LessonProgressFilter;

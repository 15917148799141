import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import ReactECharts from "echarts-for-react";
import { Tooltip_MAP } from "constants/common";
import { RootState, useAppDispatch } from "app/store";
import { message } from "components";
import PMCard from "components/PMCard";
import NoData from "components/NoData";
import { fetchCourseCompletionStatistics } from "../reportingSlice";

import styles from "./DoughnutChart.module.scss";

function DoughnutChart() {
  const dispatch = useAppDispatch();
  const { currentGroupId: adminGroupId } = useSelector(
    (state: RootState) => state.customerGroups
  );
  const { courseCompletionStatistics } = useSelector(
    (state: RootState) => state.reporting
  );

  let initialCompletions = [
    {
      name: "Not started",
      value: 0,
    },
    {
      name: "Beginning",
      value: 0,
    },
    {
      name: "In progress",
      value: 0,
    },
    {
      name: "Almost completed",
      value: 0,
    },
    {
      name: "Completed",
      value: 0,
    },
  ];

  initialCompletions.forEach((initialCompletion) => {
    const matchedCompletion = courseCompletionStatistics.find(
      (completion) => completion.name === initialCompletion.name
    );
    if (matchedCompletion) {
      initialCompletion.value = matchedCompletion.value;
    }
  });

  useEffect(() => {
    if (adminGroupId != null) {
      dispatch(fetchCourseCompletionStatistics(adminGroupId))
        .then(unwrapResult)
        .catch(message.error);
    }
  }, [dispatch, adminGroupId]);

  const option = {
    title: {
      text: "Course statistics",
      left: 23,
      top: 20,
      textStyle: {
        color: "#474545",
        fontSize: 16,
      },
    },
    tooltip: {
      trigger: "item",
      formatter: (params: any) => {
        return `${params.marker}${params.name} <br> ${params.value} course(s)`;
      },
    },
    legend: {
      orient: "vertical",
      right: 0,
      top: "center",
      textStyle: {
        color: "#8c8c8c",
      },
      tooltip: {
        show: true,
        backgroundColor: "#3a3a3a",
        textStyle: {
          color: "#ffffff",
        },
        formatter: (params: any) => {
          return `Completion ${Tooltip_MAP.get(params.name)}`;
        },
      },
    },
    color: ["#858585", "#5c7cfa", "#00a854", "#8bd4f1", "#f8ca15"],
    series: [
      {
        name: "Completion",
        type: "pie",
        radius: ["35%", "70%"],
        center: ["30%", "54%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 2,
          borderColor: "#fff",
          borderWidth: 1,
        },
        label: {
          show: false,
          position: "center",
        },
        labelLine: {
          show: false,
        },
        data: initialCompletions,
      },
    ],
  };
  return (
    <PMCard className={styles.chartContainer}>
      {courseCompletionStatistics.length > 0 ? (
        <ReactECharts
          style={{ height: "100%" }}
          option={option}
          notMerge={true}
          lazyUpdate={true}
        />
      ) : (
        <>
          <h4>Course statistics</h4>
          <NoData />
        </>
      )}
    </PMCard>
  );
}

export default DoughnutChart;

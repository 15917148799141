import React from "react";

export const DELETION_CONFIRM_MSG = (subgroupName?: string) => (
  <>
    Are you sure you want to delete group{" "}
    <strong>
      <i>{subgroupName}</i>
    </strong>
    ?<br />
    This can not be undone.
  </>
);

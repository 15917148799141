import React from "react";
import Icon from "@ant-design/icons";
import { MenuConfig } from "types/menu";
import { Assignment, Reporting, Group } from "../assets/icons";

const menu: MenuConfig[] = [
  {
    title: "Assignment",
    path: "/assignment",
    icon: Assignment,
  },
  {
    title: "Reporting",
    path: "/reporting",
    icon: Reporting,
  },
  {
    title: "My group",
    path: "/my-group",
    icon: Group,
  },
].map((menuItem) => ({
  ...menuItem,
  icon: <Icon component={menuItem.icon} style={{ fontSize: "20px" }} />,
}));

export default menu;

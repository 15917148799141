import React from "react";
import { Checkbox, Form, FormInstance, Input } from "antd";
import { FormProps } from "antd/es/form";
import { RedeemableProductModel } from "types/model/assignment";
import { VoucherReassignmentRequest } from "types/dto/request/assignment";
import { validateMessages } from "../../../constants/common";
import styles from "./AssignmentForm.module.scss";

export type ReassignValues = Pick<
  VoucherReassignmentRequest,
  "assignee" | "autoSendEmail"
>;

interface Props extends FormProps<ReassignValues> {
  assigneeEmail?: string;
  voucherType?: string;
  redeemableProducts?: RedeemableProductModel[];
  isCreatingAuth0AccountForSeatUserEnabled: boolean;
  form: FormInstance;
}

/* Voucher Assignment Form for single user  */
function AssignmentForm({
  assigneeEmail,
  voucherType,
  redeemableProducts,
  isCreatingAuth0AccountForSeatUserEnabled,
  form,
  ...props
}: Props) {
  return (
    <Form
      {...props}
      form={form}
      layout="horizontal"
      labelAlign="left"
      validateMessages={validateMessages}
      className={styles.form}
    >
      <Form.Item label="Original email">
        <Input disabled value={assigneeEmail} />
      </Form.Item>

      <Form.Item
        label="Reassigned email"
        name={["assignee", "email"]}
        rules={[
          { type: "email", required: true },
          { type: "string", max: 100 },
          {
            validator: (_, value) =>
              value === assigneeEmail
                ? Promise.reject("Please enter a different email address")
                : Promise.resolve(),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Reassigned name:" name={["assignee", "name"]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="autoSendEmail"
        initialValue={true}
        valuePropName="checked"
        style={{ marginBottom: "0px" }}
      >
        <Checkbox>
          Automatically send on-boarding email(s) after assigning user(s)
        </Checkbox>
      </Form.Item>
      {voucherType === "SEAT" && isCreatingAuth0AccountForSeatUserEnabled && (
        <Form.Item
          name="applyEasyPassword"
          initialValue={false}
          valuePropName="checked"
        >
          <Checkbox>Apply easy password</Checkbox>
        </Form.Item>
      )}
    </Form>
  );
}

export default AssignmentForm;

import Schema from "async-validator";
import { Assignee } from "../../../types/dto/request/assignment";

export function validateCsv(data: any[]) {
  const validator = new Schema({
    assigneeList: {
      type: "array",
      required: true,
      defaultField: {
        required: true,
        type: "object",
        fields: {
          email: [{ type: "email", required: true }],
          name: [{ type: "string" }],
        },
      },
    },
  });

  function isAssignee(arg: any): arg is Assignee {
    return Reflect.has(arg, "email") && Reflect.has(arg, "name");
  }

  if (data.length === 0) {
    const msg = "The file is empty.";
    throw new Error(msg);
  }

  if (data.length > 500) {
    const msg = "The number of records exceeds the max value 500.";
    throw new Error(msg);
  }

  data.forEach((record) => {
    if (!isAssignee(record)) {
      let msg;
      if (Object.keys(record).length === 2) {
        msg = "The file contains invalid column header.";
      } else {
        msg = "The file may be damaged and cannot be opened.";
      }
      throw new Error(msg);
    }
  });

  return validator.validate({ assigneeList: data }).catch(({ errors }) => {
    const idx = parseInt(errors[0]?.field?.split(".")?.[1]);
    const msg = `The file contains invalid email ${data?.[idx]?.email} in row ${
      idx + 2
    }.`;
    throw new Error(msg);
  });
}

import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Menu } from "antd";
import { MenuItemProps } from "rc-menu/lib/MenuItem";
import { store, useAppDispatch } from "app/store";
import { switchGroup } from "features/assignment/voucherGroupsSlice";
import {
  customerGroupsSelectors,
  selectCurrentGroup,
} from "features/customerGroup/customerGroupsSlice";
import { message, Modal, SwitchGroupForm } from "../index";

interface Props extends MenuItemProps {
  voucherGroupId: string;
  assignmentId?: number;
  redemptionId?: number;
}

function SwitchGroup({
  assignmentId,
  redemptionId,
  voucherGroupId,
  ...props
}: Props) {
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const currentGroup = useSelector(selectCurrentGroup);
  const customerGroups = customerGroupsSelectors.selectAll(store.getState());
  const treeData = customerGroups
    .filter(
      (customerGroup) =>
        customerGroup.organizationId === currentGroup?.organizationId
    )
    .map((customerGroup) => {
      return customerGroup.id === currentGroup.id
        ? { ...customerGroup, disabled: true }
        : customerGroup;
    });

  const handleSwitchGroup = () => {
    Modal.confirm({
      title: "Switch group",
      content: <SwitchGroupForm form={form} treeData={treeData} />,
      onOk: () =>
        form.validateFields().then(() => {
          const { targetGroupId } = form.getFieldsValue();
          dispatch(
            switchGroup({
              targetGroupId,
              assignmentId,
              redemptionId,
            })
          )
            .then(unwrapResult)
            .then(() => {
              history.push(`/${targetGroupId}/assignment/${voucherGroupId}`);
            })
            .catch(message.error);
        }),
      afterClose: form.resetFields,
    });
  };

  return (
    <Menu.Item {...props} onClick={handleSwitchGroup}>
      Switch group
    </Menu.Item>
  );
}

export default SwitchGroup;

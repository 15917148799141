import { Input, InputProps, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React from "react";

const { Option } = Select;

export type OptionValue = "voucherCode" | "assignedEmail" | "redeemedEmail";

const OPTIONS: { label: string; value: OptionValue }[] = [
  { label: "Voucher code", value: "voucherCode" },
  { label: "Assigned email", value: "assignedEmail" },
  { label: "Redeemed email", value: "redeemedEmail" },
];

interface Props extends InputProps {
  selectedValue: OptionValue;
  onSelectedValueChange: (selectedValue: OptionValue) => void;
}

export default function InputForSingle({
  selectedValue,
  onSelectedValueChange,
  ...restProps
}: Props) {
  return (
    <Input
      {...restProps}
      style={{ minWidth: "380px" }}
      allowClear
      prefix={<SearchOutlined />}
      placeholder={`Search ${OPTIONS.find(
        (it) => it.value === selectedValue
      )?.label.toLowerCase()}`}
      addonBefore={
        <Select
          style={{ minWidth: "160px" }}
          value={selectedValue}
          onChange={onSelectedValueChange}
        >
          {OPTIONS.map((type) => (
            <Option key={type.value} value={type.value}>
              {type.label}
            </Option>
          ))}
        </Select>
      }
    />
  );
}

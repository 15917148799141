import React from "react";
import { DatePicker } from "antd";
import moment from "moment";

function DateInput({ value, placeholder, disabledDate, ...props }: any) {
  return (
    <DatePicker
      {...props}
      placeholder={placeholder}
      value={value ? moment(value) : undefined}
      disabledDate={disabledDate}
    />
  );
}

export default DateInput;

import React from "react";
import { Col, Row } from "antd";
import { EMPTY } from "constants/common";
import { VoucherType } from "types/model/assignment";
import { Indicator } from "components";
import {
  AssignedUser,
  Redeem,
  Seat,
  VoucherCode,
  VoucherNumber,
} from "assets/icons";

interface Props {
  voucherType?: VoucherType;
  voucherCode?: string;
  totalAmount?: number;
  assignedAmount?: number;
  redeemedAmount?: number;
}

function VoucherSummary({
  voucherType = "SINGLE",
  voucherCode,
  totalAmount,
  assignedAmount,
  redeemedAmount,
}: Props) {
  return (
    <Row gutter={8} style={{ padding: "8px", backgroundColor: "#f2f3fa" }}>
      {voucherType === "MASTER" && (
        <Col span={8}>
          <Indicator
            icon={<VoucherCode />}
            label="Voucher code"
            value={voucherCode || "--"}
          />
        </Col>
      )}
      {voucherType === "SINGLE" && (
        <Col span={8}>
          <Indicator
            icon={<VoucherNumber />}
            label="Total licenses"
            value={totalAmount ?? EMPTY}
          />
        </Col>
      )}
      {voucherType === "SEAT" && (
        <Col span={12}>
          <Indicator
            icon={<Seat />}
            label="Total licenses"
            value={totalAmount ?? EMPTY}
          />
        </Col>
      )}
      <Col span={voucherType !== "SEAT" ? 8 : 12}>
        <Indicator
          icon={<AssignedUser />}
          label="Assigned"
          value={`${assignedAmount ?? EMPTY}/${totalAmount ?? EMPTY}`}
        />
      </Col>
      {voucherType !== "SEAT" && (
        <Col span={8}>
          <Indicator
            icon={<Redeem />}
            label="Redeemed"
            value={`${redeemedAmount ?? EMPTY}/${totalAmount ?? EMPTY}`}
          />
        </Col>
      )}
    </Row>
  );
}

export default VoucherSummary;

import { useEffect } from "react";

function useRouteGuardian(active?: boolean) {
  useEffect(() => {
    if (active ?? true) {
      window.onbeforeunload = () => true;
      return () => {
        window.onbeforeunload = null;
      };
    }
  });
}

export default useRouteGuardian;

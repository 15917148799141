import React from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Menu } from "antd";
import { MenuItemProps } from "rc-menu/lib/MenuItem";
import { useAppDispatch } from "app/store";
import {
  AssignmentAndRedemptionModel,
  RedemptionModel,
} from "types/model/assignment";
import {
  fetchRedeemableProducts,
  switchCourse,
} from "features/assignment/voucherGroupsSlice";
import { message, Modal, CourseSelectorForm } from "../index";

interface Props extends MenuItemProps {
  record: AssignmentAndRedemptionModel | RedemptionModel;
  resetFilter: () => void;
}

function SwitchCourse({ record, resetFilter, ...props }: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const handleSwitchCourse = () => {
    const { voucherCode, redemptionId, redeemedCourse } = record;
    voucherCode &&
      dispatch(fetchRedeemableProducts(voucherCode))
        .then(unwrapResult)
        .then((data) => {
          Modal.confirm({
            title: "Switch course",
            content: (
              <CourseSelectorForm
                form={form}
                redeemedCourse={redeemedCourse}
                redeemableProducts={data}
              />
            ),
            okText: "Confirm",
            onOk: () =>
              form.validateFields().then(() => {
                const { productId } = form.getFieldsValue();
                dispatch(
                  switchCourse({
                    voucherCode: voucherCode,
                    redemptionId: redemptionId,
                    productId,
                  })
                )
                  .then(unwrapResult)
                  .then(() => resetFilter())
                  .catch(message.error);
              }),
          });
        })
        .catch(message.error);
  };

  return (
    <Menu.Item {...props} onClick={handleSwitchCourse}>
      Switch course
    </Menu.Item>
  );
}

export default SwitchCourse;

import React from "react";
import { Switch, Route } from "react-router-dom";
import VoucherDistributionList from "./VoucherDistributionList";
import VoucherDistributionDetail from "./VoucherDistributionDetail";

function Assignment() {
  return (
    <Switch>
      <Route exact path="/:customerGroupId/assignment">
        <VoucherDistributionList />
      </Route>
      <Route path="/:customerGroupId/assignment/:voucherGroupId">
        <VoucherDistributionDetail />
      </Route>
    </Switch>
  );
}

export default Assignment;

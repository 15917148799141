import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import * as reportingAPI from "../../services/reporting";
import { UserListFormModel } from "../../types/model/reporting";
import { RootState } from "../../app/store";

export const fetchCustomerLearningTimeStatistics = createAsyncThunk(
  "reporting/fetchCustomerLearningTimeStatistics",
  reportingAPI.fetchCustomerLearningTimeStatistics
);

export const exportUserListStatistics = createAsyncThunk(
  "reporting/exportCustomerLearningTimeStatistics",
  reportingAPI.exportUserListStatistics
);

export const getVoucherAssignmentsByCustomerId = createAsyncThunk(
  "reporting/getVoucherAssignmentsByCustomerId",
  reportingAPI.getVoucherAssignmentsByCustomerId
);

const userListAdapter = createEntityAdapter<UserListFormModel>({
  selectId: (record) => record.customerId + record.courseName,
});

const UserListSlice = createSlice({
  name: "User List",
  initialState: userListAdapter.getInitialState({
    currentPage: 1,
    currentSize: 10,
    totalElements: 0,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchCustomerLearningTimeStatistics.fulfilled,
      (state, { payload }) => {
        const { content, totalElements, page, size } = payload;
        state.currentPage = page + 1;
        state.currentSize = size;
        state.totalElements = totalElements;
        userListAdapter.setAll(state, content);
      }
    );
  },
});

export default UserListSlice.reducer;

export const userListSelectors = userListAdapter.getSelectors<RootState>(
  (state) => state.userList
);

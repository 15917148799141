import { Input, Modal, Row, Space } from "antd";
import React, { ChangeEvent, useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { message, Modal as ModalComponents } from "components";
import moment from "moment";
import { RootState, useAppDispatch } from "app/store";
import LessonProgressStatisticList from "features/reporting/LessonProgressStatisticList";
import styles from "./TabLearningProgress.module.scss";
import {
  fetchLessonProgressStatistics,
  fetchScheduleExportLessonProgress,
  removeScheduleExportLessonProgress,
} from "../../../reportingSlice";
import LessonProgressFilter, {
  PopOverFilterProps,
} from "./LessonProgressFilter";
import {
  LessonProgressStatisticListParams,
  ScheduleExportInfo,
} from "../../../../../types/dto/request/reporting";
import ScheduledReports from "../ScheduledReports";
import ScheduledExportButton from "../ScheduledExportButton";
import ExportNow from "../ExportNow";
import DownloadReports from "../DownloadReports";

export default function TabLearningProgress() {
  const dispatch = useAppDispatch();
  const { currentGroupId: adminGroupId } = useSelector(
    (state: RootState) => state.customerGroups
  );
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 0),
    size: withDefault(NumberParam, 10),
    customerGroupId: withDefault(NumberParam, adminGroupId),
    email: withDefault(StringParam, ""),
    sort: withDefault(StringParam, "lastActivityDate,DESC"),
    lastActivityDateFrom: withDefault(NumberParam, undefined),
    lastActivityDateTo: withDefault(NumberParam, undefined),
    courseName: withDefault(StringParam, undefined),
    customerName: withDefault(StringParam, undefined),
    openDownloadReports: withDefault(BooleanParam, false),
  });
  const [scheduleAReportVisible, setScheduleAReportVisible] = useState(false);
  const [email, setEmail] = useState(query.email);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const initScheduleExportInfo: ScheduleExportInfo = {
    expired: false,
    excelTypeEnum: "CSV",
    cronExpression: "",
    beginDate: null,
    endDate: null,
    scheduleExportType: "LEARNING_PROGRESS",
    createdDate: 0,
    includeFields: [],
    reminderEmails: [],
    fileNamePrefix: "pimsleur",
  };
  const [scheduleExportInfo, setScheduleExportInfo] = useState<
    ScheduleExportInfo
  >(initScheduleExportInfo);
  const [exportNowVisible, setExportNowVisible] = useState(false);
  const fieldOptions = [
    "Group",
    "User email",
    "Sub user",
    "Account holder",
    "Course",
    "First activity date",
    "Last activity date",
    "Total lessons",
    "Completed lessons",
    "Completion(%)",
    "Listen time(Mins)",
  ];
  const [isShake, setIsShake] = useState(false);
  useEffect(() => {
    setIsShake(true);
    const timer = setTimeout(() => {
      setIsShake(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setExpandedKeys([]);
    setEmail(query.email);
    dispatch(
      fetchLessonProgressStatistics({
        ...query,
        customerGroupId: adminGroupId,
      })
    )
      .then(unwrapResult)
      .catch(message.error);
  }, [dispatch, query, adminGroupId]);

  const updateScheduleExportInfo = (isDisplayToast: boolean) => {
    dispatch(
      fetchScheduleExportLessonProgress({
        customerGroupId: adminGroupId,
        scheduleExportType: "LEARNING_PROGRESS",
      })
    )
      .then(unwrapResult)
      .then((data) => {
        setScheduleExportInfo(data);
        setIsShake(true);
        setTimeout(() => {
          setIsShake(false);
        }, 5000);
      })
      .then(
        () =>
          isDisplayToast &&
          message.success(
            "Success! Your report is now scheduled and will be promptly delivered to your email at the specified time."
          )
      )
      .catch(message.error);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => updateScheduleExportInfo(false), [adminGroupId, dispatch]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePressEnter = () => {
    setQuery((prevState) => ({
      ...prevState,
      page: undefined,
      email: email.trim(),
    }));
  };

  const handleOnSubmit: PopOverFilterProps["onSubmit"] = (values) => {
    setQuery({
      ...query,
      ...values,
      email: values.email?.trim(),
      courseName: values.courseName?.trim(),
      customerName: values.customerName?.trim(),
      lastActivityDateFrom: values.lastActivityDateFrom
        ? moment(values.lastActivityDateFrom).startOf("D").valueOf()
        : undefined,
      lastActivityDateTo: values.lastActivityDateTo
        ? moment(values.lastActivityDateTo).endOf("D").valueOf()
        : undefined,
      page: 0,
    });
  };

  const handleDeleteScheduledExport = () => {
    ModalComponents.confirm({
      title: "Confirm",
      content: "Are you sure you want to stop scheduling a report?",
      onOk: () => {
        dispatch(
          removeScheduleExportLessonProgress({
            customerGroupId: adminGroupId,
            scheduleExportType: "LEARNING_PROGRESS",
          })
        )
          .then(unwrapResult)
          .catch(message.error)
          .then(() => {
            setScheduleExportInfo(initScheduleExportInfo);
            setScheduleAReportVisible(false);
          });
      },
    });
  };

  return (
    <>
      <Row justify={"space-between"} className={styles.content}>
        <Space size={21}>
          <Input
            allowClear
            value={email}
            placeholder="Search email"
            className={styles.email}
            onChange={handleInputChange}
            onBlur={() => setEmail((prevState) => prevState.trim())}
            onPressEnter={handlePressEnter}
          />
          <LessonProgressFilter
            initialValues={
              { ...query, email: email } as LessonProgressStatisticListParams
            }
            onSubmit={handleOnSubmit}
          />
        </Space>
        <ScheduledExportButton
          scheduleExportInfo={scheduleExportInfo}
          setScheduleAReportVisible={setScheduleAReportVisible}
          handleDeleteScheduledExport={handleDeleteScheduledExport}
          setExportNowVisible={setExportNowVisible}
          setDownloadReportsVisible={(v) => {
            setQuery((prevState) => ({
              ...prevState,
              openDownloadReports: v,
            }));
          }}
          isShake={isShake}
        />
      </Row>

      <LessonProgressStatisticList
        customerGroupId={adminGroupId}
        expandedKeys={expandedKeys}
        setExpandedKeys={setExpandedKeys}
        setQuery={setQuery}
      />
      <Modal
        title="Schedule a report"
        visible={scheduleAReportVisible}
        footer={null}
        onCancel={() => setScheduleAReportVisible(false)}
        destroyOnClose={true}
        maskClosable={false}
        width={600}
      >
        <ScheduledReports
          customerGroupId={adminGroupId}
          dispatch={dispatch}
          handleCloseModal={() => setScheduleAReportVisible(false)}
          scheduleExportInfo={scheduleExportInfo}
          updateScheduleExportInfo={updateScheduleExportInfo}
          handleDeleteScheduledExport={handleDeleteScheduledExport}
          isDisplayDelete={
            !scheduleExportInfo.expired &&
            scheduleExportInfo.cronExpression?.length > 0
          }
          scheduleExportType="LEARNING_PROGRESS"
          options={fieldOptions}
        />
      </Modal>
      <Modal
        title="Export a report now"
        visible={exportNowVisible}
        footer={null}
        onCancel={() => setExportNowVisible(false)}
        destroyOnClose={true}
        maskClosable={false}
        width={600}
      >
        <ExportNow
          dispatch={dispatch}
          customerGroupId={adminGroupId}
          handleCloseModal={() => setExportNowVisible(false)}
          scheduleExportType="LEARNING_PROGRESS"
          options={fieldOptions}
        />
      </Modal>
      <Modal
        title="Download your scheduled reports"
        visible={query.openDownloadReports}
        footer={null}
        onCancel={() =>
          setQuery((prevState) => ({
            ...prevState,
            openDownloadReports: false,
          }))
        }
        destroyOnClose={true}
        maskClosable={false}
        width={800}
      >
        <DownloadReports
          adminGroupId={adminGroupId}
          dispatch={dispatch}
          scheduleExportType="LEARNING_PROGRESS"
        />
      </Modal>
    </>
  );
}

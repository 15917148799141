import { CSSProperties, ReactNode } from "react";
import classNames from "classnames";

import styles from "./PMCard.module.scss";

interface Props {
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
}

export default function PMCard({ style, className, children }: Props) {
  return (
    <div className={classNames(styles.PMCard, className)} style={style}>
      {children}
    </div>
  );
}

import * as Bowser from "bowser";

const browser = Bowser.getParser(window.navigator.userAgent);

export const checkIsSafari = () => browser.isBrowser("safari");

const { userAgent } = navigator;

export const checkIsIPad = () =>
  /iPad/i.test(userAgent) ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

export const checkIsIphone = () =>
  !checkIsIPad() && (/iPhone/i.test(userAgent) || /iPod/i.test(userAgent));

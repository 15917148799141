import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
  unwrapResult,
} from "@reduxjs/toolkit";
import * as CustomerGroupAPI from "services/customerGroup";
import { AppDispatch, RootState } from "app/store";
import {
  CustomerGroupCreateAndUpdateRequest,
  SubgroupListParams,
} from "../../types/dto/request/customerGroup";
import {
  CustomerGroupDetailResponse,
  CustomerGroupDto,
  SubgroupListResponse,
} from "../../types/dto/response/customerGroup";

/* Thunk action creators */
export const fetchAll = createAsyncThunk(
  "customer-groups/fetchAll",
  CustomerGroupAPI.fetchAll
);

export const fetchCustomerGroup = createAsyncThunk(
  "customer-groups/fetchCustomerGroup",
  async (id: number) => {
    return await CustomerGroupAPI.fetchCustomerGroup(id);
  }
);

export const createCustomerGroup = createAsyncThunk<
  void,
  CustomerGroupCreateAndUpdateRequest,
  { dispatch: AppDispatch; state: RootState }
>("customer-groups/add", async (data, { dispatch }) => {
  await CustomerGroupAPI.createCustomerGroup(data);
  unwrapResult(await dispatch(fetchAll()));
});

export const updateCustomerGroup = createAsyncThunk<
  void,
  CustomerGroupCreateAndUpdateRequest,
  { dispatch: AppDispatch; state: RootState }
>("customer-groups/update", async (data, { dispatch, getState }) => {
  const id = getState().customerGroups.currentGroupId;
  await CustomerGroupAPI.updateCustomerGroup(id, data);
  const actions = await Promise.all([
    dispatch(fetchAll()),
    dispatch(fetchCustomerGroup(id)),
  ]);
  actions.map(unwrapResult);
});

export const sendEmail = createAsyncThunk(
  "customer-groups/sendEmail",
  async (params: any) => {
    return await CustomerGroupAPI.sendEmail(params.id, params.email);
  }
);

export const fetchSubgroup = createAsyncThunk(
  "customer-groups/fetchSubgroup",
  async (params: SubgroupListParams) => {
    return await CustomerGroupAPI.fetchSubgroup(params);
  }
);

export const removeCustomerGroup = createAsyncThunk(
  "customer-groups/delete",
  CustomerGroupAPI.removeCustomerGroup
);

export type CustomerGroup = CustomerGroupDto & {
  name: string;
} & CustomerGroupDetailResponse &
  SubgroupListResponse;

function toEntity(response: CustomerGroupDto): CustomerGroup {
  return { ...response, name: response.title };
}

const customerGroupsAdapter = createEntityAdapter<CustomerGroup>({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

interface IState {
  currentGroupId: number;
  currentPage: number;
  totalElements: number;
}

const initialState: IState = {
  currentGroupId:
    parseInt(sessionStorage.getItem("lastSelectedGroupId")!) || NaN,
  currentPage: 1,
  totalElements: 0,
};

const customerGroupsSlice = createSlice({
  name: "customer-groups",
  initialState: customerGroupsAdapter.getInitialState(initialState),
  reducers: {
    setCurrentGroupId: (state, { payload }: PayloadAction<number>) => {
      sessionStorage.setItem("lastSelectedGroupId", payload.toString());
      state.currentGroupId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAll.fulfilled, (state, { payload }) => {
      const { adminGroups, lastSelectedGroupId } = payload;
      const entities = adminGroups?.map(toEntity) ?? [];
      customerGroupsAdapter.upsertMany(state, entities);
      if (
        isNaN(state.currentGroupId) ||
        !entities.find((entity) => entity.id === state.currentGroupId)
      ) {
        state.currentGroupId = lastSelectedGroupId;
        sessionStorage.setItem(
          "lastSelectedGroupId",
          lastSelectedGroupId.toString()
        );
      }
    });
    builder.addCase(fetchCustomerGroup.fulfilled, (state, { payload }) => {
      customerGroupsAdapter.updateOne(state, {
        id: payload.id,
        changes: payload,
      });
    });
    builder.addCase(fetchSubgroup.fulfilled, (state, { payload }) => {
      const updates = payload.content.map((record) => ({
        id: record.id,
        changes: record,
      }));
      customerGroupsAdapter.updateMany(state, updates);
    });
    builder.addCase(removeCustomerGroup.fulfilled, (state, { meta }) => {
      const { arg: id } = meta;
      customerGroupsAdapter.removeOne(state, id);
    });
  },
});

export const { setCurrentGroupId } = customerGroupsSlice.actions;

export const selectCurrentGroup = (state: RootState) =>
  state.customerGroups.entities[
    state.customerGroups.currentGroupId
  ] as CustomerGroup;

export const customerGroupsSelectors = customerGroupsAdapter.getSelectors<
  RootState
>((state) => state.customerGroups);

export default customerGroupsSlice.reducer;

import React, { useEffect } from "react";
import { Button, Divider, Form, FormProps, Input, Radio, Tooltip } from "antd";
import Icon, { InfoCircleOutlined } from "@ant-design/icons";
import { span } from "utils/style";
import { Minus, Plus } from "assets/icons";
import { CustomerGroupCreateAndUpdateRequest } from "types/dto/request/customerGroup";
import { DisplayInput, EditableTagGroup } from "components";
import { validateMessages } from "constants/common";
import { useRouteGuardian } from "hooks";
import { options, rules } from "./formConfig";
import GroupAdminEmailView from "./GroupAdminEmailView";
import styles from "./BasicInfoForm.module.scss";

type Request = CustomerGroupCreateAndUpdateRequest;

interface Values extends Omit<Request, "groupAdmins" | "parentGroupId"> {
  id: number;
  groupAdmins?: Request["groupAdmins"];
}

interface Props extends FormProps<Values> {
  editing?: boolean;
}

function BasicInfoForm({ editing = true, ...otherProps }: Props) {
  useRouteGuardian(editing);

  useEffect(() => {
    if (otherProps.initialValues != null && otherProps.form != null) {
      otherProps.form.resetFields();
    }
  }, [otherProps.initialValues, otherProps?.form, editing]);

  const handleBlur = () => {
    const name = otherProps.form?.getFieldValue("name");
    const description = otherProps.form?.getFieldValue("description");
    otherProps.form?.setFieldsValue({
      ...otherProps.form?.getFieldsValue(),
      name: name && name.trim(),
      description: description && description.trim(),
      groupAdmins:
        otherProps.form &&
        otherProps.form?.getFieldsValue().groupAdmins?.map((item) => ({
          ...item,
          email: item.email && item.email.trim(),
          name: item.name && item.name.trim(),
          telephone: item.telephone && item.telephone.trim(),
        })),
    });
  };

  return (
    <>
      <Form<Values>
        {...otherProps}
        layout="inline"
        labelAlign={"left"}
        validateMessages={validateMessages}
        validateTrigger={["onBlur", "onSubmit"]}
        className={styles.form}
      >
        <Form.Item
          label="Group name"
          name="name"
          rules={editing ? rules.name : undefined}
          style={span(12)}
        >
          {editing ? <Input onBlur={handleBlur} /> : <DisplayInput />}
        </Form.Item>
        <Form.Item
          label="Allowed to create subgroup"
          name="isCreatingSubgroupEnabled"
          initialValue={editing ? true : undefined}
          rules={editing ? rules.isCreatingSubgroupEnabled : undefined}
          style={span(6)}
        >
          {editing ? <Radio.Group options={options} /> : <DisplayInput />}
        </Form.Item>
        <Form.Item
          label="Progress Reporting"
          name="isAnalyticsReportingEnabled"
          initialValue={editing ? true : undefined}
          rules={editing ? rules.isAnalyticsReportingEnabled : undefined}
          style={span(6)}
        >
          {editing ? <Radio.Group options={options} /> : <DisplayInput />}
        </Form.Item>
        {editing ? (
          <Form.Item
            label="Description"
            name="description"
            rules={[{ max: 2000 }]}
            className={styles.descriptionTextArea}
            style={span(24)}
          >
            <Input.TextArea onBlur={handleBlur} />
          </Form.Item>
        ) : (
          <Form.Item label="Description" name="description" style={span(24)}>
            <DisplayInput />
          </Form.Item>
        )}

        <Divider style={{ margin: "8px 0 21px 0" }} />

        <Form.List name="groupAdmins">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <React.Fragment key={index}>
                  <Form.Item
                    label={index > 0 ? "" : "Admin email"}
                    name={editing ? [field.name, "email"] : field.name}
                    rules={editing ? rules.adminEmail : undefined}
                    style={span(12)}
                  >
                    {editing ? (
                      <Input
                        placeholder="Please input email"
                        onBlur={handleBlur}
                      />
                    ) : (
                      <GroupAdminEmailView />
                    )}
                  </Form.Item>
                  <Form.Item
                    label={index > 0 ? "" : "Admin name"}
                    name={[field.name, "name"]}
                    fieldKey={[field.fieldKey!, "name"]}
                    style={span(6)}
                    rules={editing ? rules.adminName : undefined}
                  >
                    {editing ? (
                      <Input
                        placeholder="Please input admin name"
                        onBlur={handleBlur}
                      />
                    ) : (
                      <DisplayInput />
                    )}
                  </Form.Item>
                  <Form.Item
                    label={index > 0 ? "" : "Admin phone number"}
                    name={[field.name, "telephone"]}
                    fieldKey={[field.fieldKey!, "telephone"]}
                    style={span(5)}
                    rules={editing ? rules.adminTelephone : undefined}
                  >
                    {editing ? (
                      <Input
                        placeholder="Please input phone number"
                        onBlur={handleBlur}
                      />
                    ) : (
                      <DisplayInput />
                    )}
                  </Form.Item>
                  {editing && (
                    <Form.Item
                      label={index === 0 && " "}
                      colon={false}
                      style={span(1)}
                      className={styles.minusIcon}
                    >
                      <Icon
                        component={Minus}
                        onClick={() => remove(field.name)}
                      />
                    </Form.Item>
                  )}
                </React.Fragment>
              ))}
              {editing && (
                <Button
                  onClick={() => add()}
                  icon={<Icon component={Plus} />}
                  className={styles.button}
                  type="link"
                >
                  Add Admin
                </Button>
              )}
            </>
          )}
        </Form.List>

        <Form.Item
          label={
            <>
              Allowed domains:
              {editing && allowedDomainTip}
            </>
          }
          colon={false}
          name="allowedLoginDomains"
          style={span(24)}
        >
          {editing ? <EditableTagGroup /> : <DisplayInput />}
        </Form.Item>

        <Form.Item name="id" noStyle hidden />
      </Form>
    </>
  );
}

const allowedDomainTip = (
  <Tooltip
    title={
      <>
        Example value: <br />
        testdomain.com
      </>
    }
  >
    <InfoCircleOutlined style={{ paddingLeft: "4px" }} />
  </Tooltip>
);

export default BasicInfoForm;

import React from "react";
import { Form, FormInstance, TreeSelect } from "antd";
import { FormProps } from "antd/es/form";
import { validateMessages } from "constants/common";
import { ReassignValues } from "features/assignment/AssignmentForm";
import { CustomerGroup } from "features/customerGroup/customerGroupsSlice";

import styles from "./SwitchGroupForm.module.scss";

interface Props extends FormProps<ReassignValues> {
  form: FormInstance;
  treeData: Array<CustomerGroup>;
}

function SwitchGroupForm({ form, treeData }: Props) {
  return (
    <Form
      layout="horizontal"
      labelAlign="left"
      validateTrigger={["onSubmit"]}
      validateMessages={validateMessages}
      className={styles.form}
      form={form}
    >
      <Form.Item
        label={"Switch to"}
        name={"targetGroupId"}
        rules={[{ required: true }]}
      >
        <TreeSelect
          treeDataSimpleMode
          treeData={treeData}
          allowClear
          treeDefaultExpandAll
        />
      </Form.Item>
    </Form>
  );
}

export default SwitchGroupForm;

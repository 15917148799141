import React, { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Col, Divider, Radio, Row } from "antd";
import { Redeem, Remaining, TotalRedemptions } from "assets/icons";
import { Indicator, message } from "components";
import { EMPTY } from "constants/common";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "app/store";
import { fetchReportingSummary } from "../reportingSlice";
import styles from "./GroupSummary.module.scss";

function GroupSummary() {
  const dispatch = useAppDispatch();
  const { currentGroupId: adminGroupId } = useSelector(
    (state: RootState) => state.customerGroups
  );
  const { reportingSummary } = useSelector(
    (state: RootState) => state.reporting
  );
  const {
    totalVoucherAmount,
    redeemedVoucherAmount,
    remainingVoucherAmount,
  } = reportingSummary;
  const [voucherType, setVoucherType] = useState("");

  useEffect(() => {
    if (adminGroupId != null) {
      dispatch(
        fetchReportingSummary({ customerGroupId: adminGroupId, voucherType })
      )
        .then(unwrapResult)
        .catch(message.error);
    }
  }, [dispatch, adminGroupId, voucherType]);

  return (
    <>
      Voucher Type:
      <Radio.Group
        options={[
          { label: "All", value: "" },
          { label: "Seat", value: "SEAT" },
          { label: "Single", value: "SINGLE" },
          { label: "Master", value: "MASTER" },
        ]}
        onChange={(evt) => setVoucherType(evt.target.value)}
        value={voucherType}
        optionType="button"
        buttonStyle="solid"
        className={styles.radioGroup}
      />
      <Row gutter={24} style={{ marginBottom: "24px" }}>
        <Col span={8}>
          <Indicator
            icon={<TotalRedemptions />}
            label="Total license(s)"
            value={totalVoucherAmount ?? EMPTY}
          />
        </Col>
        <Col span={8}>
          <Indicator
            icon={<Redeem />}
            label="Redeemed"
            value={redeemedVoucherAmount ?? EMPTY}
          />
        </Col>
        <Col span={8}>
          <Indicator
            icon={<Remaining />}
            label="Remaining"
            value={remainingVoucherAmount ?? EMPTY}
          />
        </Col>
      </Row>
      <Divider />
    </>
  );
}

export default GroupSummary;
